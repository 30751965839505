'use client';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import {
 Accordion,
 AccordionContent,
 AccordionItem,
 AccordionTrigger,
} from '../components/ui/accordion';
import posthog from 'posthog-js';
import { useToast } from '../components/ui/use-toast';
import { PremiumsOnUsFAQ } from './lp-get-proposals';
import { reportError } from '../components/reportError';
import {
 Dialog,
 DialogContent,
 DialogDescription,
 DialogFooter,
 DialogHeader,
 DialogTitle,
 DialogTrigger,
} from '../components/ui/dialog';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import { BlockNumberFAQ } from '../components/advisor-spam';
import { PremiumsOnUs } from '../components/pou';

type DynamicFields = {
 [key: string]: string | number | boolean | null; // Assuming simple scalar values, adjust as necessary
};
interface Prospect {
 fields?: DynamicFields;
}

interface ProspectFields {
 [key: string]: any; // Adjust based on your actual prospect fields
}

const prepareFormData = (
 prospectFields: ProspectFields
): { [key: string]: string | boolean } => {
 const formData: { [key: string]: string | boolean } = {};
 Object.keys(prospectFields).forEach(key => {
  const value = prospectFields[key];
  if (typeof value === 'boolean') {
   // For boolean values, keep them as is (useful for checkboxes)
   formData[key] = value;
  } else {
   // Convert everything else to string
   formData[key] = String(value || '');
  }
 });
 return formData;
};

const ProspectCentre = () => {
 const [advisor, setAdvisors] = useState([]);
 const [prospect, setProspect] = useState<Prospect | null>(null);
 const location = useLocation();
 const [selectedAdvisorIndex, setSelectedAdvisorIndex] = useState(null);
 const { toast } = useToast();
 const [formData, setFormData] = useState({});
 const [comments, setComments] = useState('');
 const [countdown, setCountdown] = useState(0);
 const [searchParams] = useSearchParams();
 const [showVerificationPopup, setShowVerificationPopup] = useState(false);
 const [openDialog, setOpenDialog] = useState(false);
 const [feedback, setFeedback] = useState('');
 const [isSpamDialogOpen, setIsSpamDialogOpen] = useState(false);
 const [showEmailResponseDialog, setShowEmailResponseDialog] = useState(false);
 const [additionalInfo, setAdditionalInfo] = useState('');

 const handleSpamReport = async () => {
  try {
   const response = await fetch('/api/prospect-action', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     recordID: advisor[selectedAdvisorIndex].data.fields['recordID'],
     action: 'spam',
    }),
   });
   if (response.ok) {
    toast({
     title: 'Report Submitted',
     description:
      'We have received your report and will investigate. Truly sorry about this! 🙏',
    });
    setIsSpamDialogOpen(false);
   } else {
    console.error('Error sending spam report');
    toast({
     title: 'Error',
     description:
      'There was an error submitting your report. Please try again.',
     variant: 'destructive',
    });
   }
  } catch (error) {
   console.error('Error sending spam report', error);
   toast({
    title: 'Error',
    description: 'There was an error submitting your report. Please try again.',
    variant: 'destructive',
   });
  }
 };

 const handleMessageSubmit = async e => {
  e.preventDefault();

  // Start the countdown regardless of message content
  setCountdown(5);
  const timer = setInterval(() => {
   setCountdown(prevCount => {
    if (prevCount <= 1) {
     clearInterval(timer);
     return 0;
    }
    return prevCount - 1;
   });
  }, 1000);

  // Check if the message is empty or only contains whitespace
  if (!comments.trim()) {
   toast({
    title: 'Empty message',
    description: 'We assume that was a mistake. No message was sent.',
   });
   return;
  }

  if (countdown > 0) {
   toast({
    title: 'Please wait',
    description: `You can send another message in ${countdown} seconds.`,
   });
   return;
  }

  const formData = {
   recordID: advisor[selectedAdvisorIndex].data.fields['recordID'],
   action: 'send-message',
   message: comments,
  };

  try {
   const response = await fetch('/api/prospect-action', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
   });

   if (response.ok) {
    setComments('');
    toast({
     title: 'Message sent',
     description: 'Your message was just sent!',
    });
    posthog.capture('prospect_send_message');
   } else {
    toast({
     title: 'Something went wrong',
     description: 'Please email us about it. Sorry about that 🙏',
     variant: 'destructive',
    });
   }
  } catch (error) {
   reportError({
    message: 'Error sending message',
    url: window.location.href,
    function: 'handleMessageSubmit',
    componentName: 'ProspectCentre',
    additionalInfo: { advisorIndex: selectedAdvisorIndex },
    stackTrace: error.stack,
   });
   toast({
    title: 'Network error',
    description: 'Please check your internet connection and try again.',
    variant: 'destructive',
   });
  }
 };

 const extractPath = () => {
  const basePath = '/prospect/';
  const fullPath = location.pathname;
  return fullPath.includes(basePath) ? fullPath.split(basePath)[1] : '';
 };

 const id = extractPath();

 useEffect(() => {
  const fetchData = async (isReduced = true) => {
   try {
    const params = new URLSearchParams();
    const productParam = id || '';
    if (productParam) params.append('id', productParam);
    params.append('reduced', isReduced.toString());

    // Check if 'start' parameter is true
    const start = searchParams.get('start');
    if (start === 'true') {
     params.append('start', 'true');
    }

    const response = await fetch(`/api/prospect?${params}`);
    if (!response.ok) {
     throw new Error('Network response was not ok');
    }
    const data = await response.json();
    posthog.capture('prospect_on_dashboard');
    setAdvisors(data.advisors || []);
    setProspect(data.user || {});
    setFormData(prepareFormData(data.user?.fields || {}));
    setSelectedAdvisorIndex(0);
    console.log(data);

    // Show popup if 'start' was true
    if (start === 'true') {
     setShowVerificationPopup(true);
    }
   } catch (error) {
    reportError({
     message: 'Error fetching types data',
     url: window.location.href,
     function: 'fetchData',
     componentName: 'ProspectCentre',
     additionalInfo: { isReduced },
     stackTrace: error.stack,
    });
   }
  };

  fetchData();
 }, [searchParams]);

 useEffect(() => {
  if (prospect?.fields) {
   setFormData(prepareFormData(prospect.fields));
  }
 }, [prospect]);

 useEffect(() => {
  const emailResponse = 'email-' + searchParams.get('email_response');
  const answer = searchParams.get('answer');

  if (emailResponse && answer) {
   fetch('/api/document-email-response', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, email: emailResponse, answer }),
   });
   setShowEmailResponseDialog(true);
  }
 }, [searchParams, id]);

 const handleAdditionalInfoSubmit = () => {
  fetch('/api/document-email-response', {
   method: 'POST',
   headers: {
    'Content-Type': 'application/json',
   },
   body: JSON.stringify({
    id,
    email: 'email-' + searchParams.get('email_response'),
    answer: searchParams.get('answer'),
    additional_info: additionalInfo,
   }),
  });
  setShowEmailResponseDialog(false);
 };

 const handleOnboardingSubmit = async e => {
  e.preventDefault(); // Prevent default form submission

  const formData = {
   recordID: prospect?.['id'],
   email: prospect?.['fields']?.['Email'],
  };

  try {
   const response = await fetch('/code/send-prospect-onboarding', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json', // Set the content type to application/json
    },
    body: JSON.stringify(formData), // Convert the formData object to a JSON string
   });

   if (!response.ok) {
    throw new Error(`Error: ${response.status}`); // Handle server errors
   }

   // Handle success
   toast({
    description: 'Verification email sent successfully',
   });
  } catch (error) {
   reportError({
    message: 'Failed to send verification email',
    url: window.location.href,
    function: 'handleOnboardingSubmit',
    componentName: 'ProspectCentre',
    additionalInfo: { prospectId: prospect?.['id'] },
    stackTrace: error.stack,
   });
  }
 };

 const handleInputChange = e => {
  const { name, value } = e.target;

  setFormData(prevFormData => {
   // Directly use the name attribute value, including spaces and casing
   const updatedFormData = { ...prevFormData, [name]: value };
   return updatedFormData;
  });
 };

 const handleProfileSubmit = async () => {
  try {
   const response = await fetch('/api/prospect-update-details', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     recordID: prospect?.['fields']['recordID'],
     fields: formData,
    }),
   });

   if (!response.ok) {
    throw new Error(`Error: ${response.status}`); // Handle server errors
   }

   // Handle success
   toast({
    description: 'Information updated successfully',
   });
  } catch (error) {
   reportError({
    message: 'Failed to update profile',
    url: window.location.href,
    function: 'handleProfileSubmit',
    componentName: 'ProspectCentre',
    additionalInfo: { prospectId: prospect?.['fields']['recordID'] },
    stackTrace: error.stack,
   });
  }
 };

 return (
  <div className="bg-white-1">
   <Dialog open={showVerificationPopup} onOpenChange={setShowVerificationPopup}>
    <DialogContent>
     <DialogHeader>
      <DialogTitle>
       <span className="text-green-1">Thanks for verifying!</span>
      </DialogTitle>
     </DialogHeader>
     <p>
      We aim to have your advisors selected in the next 24hrs. We'll send you an
      email once we have them!
     </p>
    </DialogContent>
   </Dialog>
   <Dialog
    open={showEmailResponseDialog}
    onOpenChange={setShowEmailResponseDialog}
   >
    <DialogContent>
     <DialogHeader>
      <DialogTitle>Thank you for your response!</DialogTitle>
      <DialogDescription>
       Is there anything else you'd like to add? (Optional)
      </DialogDescription>
     </DialogHeader>
     <Textarea
      value={additionalInfo}
      onChange={e => setAdditionalInfo(e.target.value)}
      placeholder="Add any additional information here..."
     />
     <DialogFooter>
      <Button
       variant="outline"
       onClick={() => setShowEmailResponseDialog(false)}
      >
       Close
      </Button>
      <Button variant="primary" onClick={handleAdditionalInfoSubmit}>
       Submit
      </Button>
     </DialogFooter>
    </DialogContent>
   </Dialog>
   <div>
    <section className="grid max-w-screen-xl mx-auto py-8 dark:text-white">
     <div className="text-center">
      <h1 className="primary-h3 text-green-1">
       {prospect?.['fields']?.['Name']}'s Dashboard
      </h1>
      <p className="">
       You can update your information here and see your customized advice from
       the different advisors.
      </p>
     </div>
    </section>
    <section className="flex flex-col justify-items-center my-4 dark:text-white">
     <div className="grid grid-cols-4 gap-4 max-w-screen-2xl">
      <div
       id="col1"
       className="px-2 md:px-8 col-span-4 md:col-span-1 order-last md:order-first"
      >
       <div className="flex flex-col py-2 md:basis-1/4 flex-0">
        {!prospect?.['fields']?.['Telegram ID'] && (
         <div className="flex flex-col bg-blue-1 p-4 rounded-2xl gap-4">
          <h2 className="text-h5 text-green-1 font-bold text-pretty">
           Connect to our Telegram Bot 🤖
          </h2>
          <p>
           Consider connecting with our Telegram Bot! It's the best way for
           advisors to{' '}
           <span className="font-semibold">ask clarification questions</span>{' '}
           while keeping your contact information private. 😊
          </p>
          <a
           className="px-4 py-2 border border-gray-2 rounded-xl shadow-lg hover:bg-green-2 bg-green-1 text-white-1 mx-auto"
           href={`https://t.me/whatins_bot?start=${prospect?.['id']}`}
          >
           Connect Now
          </a>
         </div>
        )}

        <form
         id="updateProfile"
         className="p-4"
         onSubmit={e => {
          e.preventDefault();
          handleProfileSubmit();
         }}
        >
         {formData && (
          <Accordion type="single" collapsible>
           <ul className="space-y-4">
            <li>
             <h2 className="font-bold text-xl capitalize primary-h3 text-green-1">
              Your Profile
             </h2>
            </li>
            <li>
             <p className="">Missed something? Update it below.</p>
            </li>

            <AccordionItem value="item-1">
             <AccordionTrigger>Personal Details</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between mb-4 text-xl font-bold dark:text-white">
                Name/Alias:{' '}
                <span className="text-right mb-2 text-lg font-light dark:text-white">
                 {prospect?.['fields']?.['Name']}
                </span>
               </div>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Gender:{' '}
                <span className="text-right mb-2 text-sm font-light dark:text-white">
                 {prospect?.['fields']?.['Gender']}
                </span>
               </div>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Birthday:
                <input
                 type="date"
                 id="birthday"
                 name="Birthday"
                 className="border px-2 rounded-2xl"
                 onChange={handleInputChange}
                 value={formData['Birthday'] || ''}
                />
               </div>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Singaporean?:{' '}
                <span className="text-right mb-2 text-sm font-light dark:text-white">
                 {prospect?.['fields']?.['Singaporean?'] ? (
                  <p>Yes</p>
                 ) : (
                  <p>No</p>
                 )}
                </span>
               </div>
              </li>
              {!prospect?.['fields']?.['Singaporean?'] && (
               <div>
                <li>
                 <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                  What Pass:{' '}
                  <input
                   type="text"
                   name="What pass?"
                   className="border px-2 rounded-2xl text-xs"
                   onChange={handleInputChange}
                   value={formData['What pass?'] || ''}
                  />
                 </div>
                </li>
                <li>
                 <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                  Live in 🇸��?:{' '}
                  <input
                   type="text"
                   name="Living in SG?"
                   className="border px-2 rounded-2xl text-xs"
                   onChange={handleInputChange}
                   value={formData['Living in SG?'] || ''}
                  />
                 </div>
                </li>
               </div>
              )}
             </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-2">
             <AccordionTrigger>Job</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Job/Profession:
               </div>
               <input
                type="text"
                name="Occupation"
                className="w-full rounded-lg border-gray-200 p-3 text-sm mb-2 font-light dark:text-white border text-xs"
                onChange={handleInputChange}
                value={formData['Occupation'] || ''}
                required
               />
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Monthly Income:
               </div>
               <input
                name="Monthly Income"
                type="number"
                className="w-full rounded-lg border-gray-200 p-3 text-sm text-right mb-2 font-light dark:text-white border"
                onChange={handleInputChange}
                value={formData['Monthly Income'] || ''}
                required
               />
              </li>
             </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-3">
             <AccordionTrigger>Life Stage</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Relationship Status:
               </div>{' '}
               <span className="font-light text-sm">
                {formData['Relationship Status']}
               </span>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Current Kids:
               </div>{' '}
               <span className="font-light text-sm">
                {formData['Current Kids']}
               </span>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Future Kids?:{' '}
               </div>{' '}
               <input
                type="text"
                name="More Kids In Future"
                className="w-full rounded-lg border-gray-200 p-3 text-sm mb-2 font-light dark:text-white border text-xs"
                onChange={handleInputChange}
                value={formData['More Kids In Future'] || ''}
                required
               />
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Current / Future Dependants:
               </div>
               <textarea
                rows={3}
                className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 text-xs"
                required
                name="Other Dependants"
                onChange={handleInputChange}
                value={formData['Other Dependants'] || ''}
               ></textarea>
              </li>
             </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-4">
             <AccordionTrigger>Health</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Smoker?:{' '}
                <input
                 type="checkbox"
                 name="Smoker?"
                 onChange={handleInputChange}
                 checked={formData['Smoker?'] || false}
                />
               </div>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Medical History?:
               </div>
               <textarea
                rows={3}
                className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 text-xs"
                required
                name="Medical Condition"
                onChange={handleInputChange}
                value={formData['Medical Condition'] || ''}
               ></textarea>
              </li>
             </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-5">
             <AccordionTrigger>Insurance Needs</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                What Insurance Do You Have?
               </div>
               <textarea
                rows={3}
                className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                required
                name="Existing Policies"
                onChange={handleInputChange}
                value={
                 formData['Existing Policies'] ||
                 prospect?.['fields']?.['Existing Policies'] ||
                 ''
                }
                placeholder="Please add whatever insurance you have here. If none, say 'no insurance'"
               ></textarea>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">
                What Insurance Are You Looking For?
               </div>
               <ul className="block mb-2 text-sm font-light  dark:text-white">
                {Array.isArray(prospect?.['fields']?.['Types Name']) &&
                 prospect['fields']['Types Name'].map((plan, index) => (
                  <li key={index}>{plan}</li>
                 ))}
               </ul>
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Ideal Insurance Budget (Monthly):
               </div>
               <input
                name="Budget"
                type="number"
                className="w-full rounded-lg border-gray-200 p-3 text-sm text-right mb-2 font-light dark:text-white border"
                onChange={handleInputChange}
                value={formData['Budget'] || ''}
                required
               />
              </li>
             </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-6">
             <AccordionTrigger>Financial Goals</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Do you plan on buying property? If so, what's the budget range?:
               </div>
               <input
                type="text"
                name="Ideal Property Budget"
                className="w-full border px-2 rounded-2xl text-xs"
                onChange={handleInputChange}
                value={formData['Ideal Property Budget'] || ''}
               />
              </li>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Do you have any future financial goals and aspirations that the
                advisor should be aware of to suggest the perfect insurance fit?
               </div>
               <textarea
                rows={2}
                className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 text-xs"
                required
                name="Other Aspirations"
                value={formData['Other Aspirations'] || ''}
                onChange={handleInputChange}
               ></textarea>
              </li>
             </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-7">
             <AccordionTrigger>Additional Information</AccordionTrigger>
             <AccordionContent>
              <li>
               <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
                Phone Number:{' '}
                <input
                 type="text"
                 name="Phone Number"
                 className="border px-2 rounded-2xl text-xs"
                 onChange={handleInputChange}
                 value={formData['Phone Number'] || ''}
                />
               </div>
              </li>
             </AccordionContent>
            </AccordionItem>
           </ul>
          </Accordion>
         )}
         <button
          type="submit"
          className="primary-button1 hover:primary-button2"
         >
          Update Profile
         </button>
        </form>
       </div>
      </div>
      <div
       id="col2"
       className="px-2 md:px-8 col-span-4 md:col-span-3 space-y-2"
      >
       {!prospect?.['fields']?.['Answered Intro Email?'] &&
       typeof prospect?.['fields']?.['Status'] === 'string' &&
       !prospect?.['fields']?.['Status'].includes('Advisors Selected') ? (
        <div className="flex flex-col border-2 border-gray-2 p-4 rounded-2xl gap-4 bg-white-2">
         <h2 className="text-h5 text-green-1 font-semibold">
          👉 Verify your email to get started
         </h2>
         <p>
          Before getting advisors' advice, please verify your email. You should
          have received an email from us titled:{' '}
          <span className="font-italic">
           [Quick Reply Needed] Can we find your advisors now{' '}
          </span>
          <b>If you haven't received an email from us</b>, please request it
          again below.
         </p>
         <form
          action="/code/send-prospect-onboarding"
          method="post"
          className="p-2 flex flex-col gap-4 items-center"
         >
          <input type="hidden" name="recordID" value={prospect?.['id']} />
          <input
           type="text"
           name="email"
           className="bg-white-1 w-full rounded-xl"
           value={
            prospect?.['fields']?.['Email']
             ? String(prospect['fields']['Email'])
             : 'No email on file'
           }
           disabled
          />
          <button
           type="button"
           onClick={handleOnboardingSubmit}
           className="primary-button2 text-white "
          >
           Send Verification Email
          </button>
         </form>
        </div>
       ) : (
        <div className="flex flex-col border-2 border-gray-2 p-4 rounded-2xl gap-4 bg-white-2">
         <h2 className="text-h5 text-green-1 font-semibold">
          {prospect?.['fields']?.['Total POU'] ?? 0} Chances to have us pay for
          your premiums
         </h2>
         <p>
          The Premiums On Us giveaway is our way of giving back. Each quarter we
          randomly draw a winner and pay for their premiums. The entries you
          have do not expire even if you win!{' '}
          <b>The next draw is 2 Sep 2024. </b>
          <PremiumsOnUsFAQ
           className="underline text-green-1 text-start"
           triggerText="Click here for more information"
          />
         </p>
        </div>
       )}
       <div
        id="tab"
        className="flex flex-col md:flex-row gap-4 md:gap-6 justify-start"
       >
        {advisor.map((advisor, index) => (
         <div key={index}>
          <button
           className={`w-full md:w-auto px-4 py-2 border border-gray-2 rounded-xl shadow-lg hover:bg-green-2 hover:text-green-1 ${
            selectedAdvisorIndex === index
             ? 'bg-green-1 text-white-2'
             : 'bg-white-2'
           }`}
           onClick={() => setSelectedAdvisorIndex(index)}
          >
           <div className="capitalize">
            {advisor.data.fields['send?'] && '✅ '}
            {advisor.data.fields['Advisor Name'][0]}
           </div>
          </button>
         </div>
        ))}
       </div>

       <div className="border md:p-8 rounded-xl shadow-xl bg-white-2 border-gray-2">
        {selectedAdvisorIndex !== null && advisor[selectedAdvisorIndex] && (
         <div className="flex flex-col md:flex-row gap-8">
          <div className="gap-y-8 divide-y-2 md:basis-1/2 order-last md:order-first">
           <div className="p-4 hidden md:block">
            <h2 className="font-bold text-xl capitalize">
             {advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}
            </h2>
            <p>
             Company:{' '}
             {advisor[selectedAdvisorIndex].data.fields['Advisor Company']}
            </p>
           </div>
           {selectedAdvisorIndex !== null && advisor[selectedAdvisorIndex] && (
            <div className="p-4">
             {advisor[selectedAdvisorIndex].data.fields['send?'] ? (
              <>
               <h2 className="font-abold text-lg capitalize">Their Message</h2>
               <textarea
                rows={8}
                disabled
                className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={
                 advisor[selectedAdvisorIndex].data.fields[
                  'Additional comments'
                 ] || ''
                }
               />
               <h2 className="font-abold text-lg capitalize pt-6">
                Suggestions from Advisor (Click to Open)
               </h2>
               <ul>
                {advisor[selectedAdvisorIndex].attachments?.map(
                 (attachment, attIndex) => (
                  <li key={`${selectedAdvisorIndex}-${attIndex}`}>
                   <button
                    className="mb-2 text-sm font-medium text-blue-500 dark:text-white"
                    onClick={async () => {
                     try {
                      window.open(
                       attachment.url,
                       '_blank',
                       'noopener,noreferrer'
                      );
                      const response = await fetch('/api/prospect-action', {
                       method: 'POST',
                       headers: {
                        'Content-Type': 'application/json',
                       },
                       body: JSON.stringify({
                        recordID:
                         advisor[selectedAdvisorIndex].data.fields['recordID'],
                        action: 'open-proposal',
                       }),
                      });
                      posthog.capture('prospect_open_proposal');
                      if (!response.ok) {
                       console.error('Error sending prospect open request');
                      }
                     } catch (error) {
                      console.error(
                       'Error sending prospect open request',
                       error
                      );
                     }
                    }}
                   >
                    {attachment.filename}
                   </button>
                  </li>
                 )
                )}
               </ul>
               {advisor[selectedAdvisorIndex].data.fields['Presentation'] && (
                <div className="mt-4">
                 <h3 className="font-bold text-md capitalize">
                  Presentation Link
                 </h3>
                 <a
                  href={
                   advisor[selectedAdvisorIndex].data.fields['Presentation']
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                  onClick={async () => {
                   try {
                    const response = await fetch('/api/prospect-action', {
                     method: 'POST',
                     headers: {
                      'Content-Type': 'application/json',
                     },
                     body: JSON.stringify({
                      recordID:
                       advisor[selectedAdvisorIndex].data.fields['recordID'],
                      action: 'open-proposal',
                     }),
                    });
                    posthog.capture('prospect_open_proposal');
                    if (!response.ok) {
                     console.error('Error sending prospect open request');
                    }
                   } catch (error) {
                    console.error('Error sending prospect open request', error);
                   }
                  }}
                 >
                  View Presentation
                 </a>
                </div>
               )}
               <h2 className="font-abold text-lg capitalize pt-6">
                What actions do you plan on taking?
               </h2>
               <div className="mt-4 flex flex-col">
                {['yes_meet', 'no_meet', 'yes_met', 'yes_buy', 'spam'].map(
                 option => (
                  <div key={`${selectedAdvisorIndex}-${option}`}>
                   {option === 'no_meet' ? (
                    <>
                     <Button
                      className="primary-button2 my-1 hover:text-green-2 w-full"
                      onClick={() => setOpenDialog(true)}
                     >
                      I don't plan on meeting{' '}
                      {
                       advisor[selectedAdvisorIndex].data.fields[
                        'Advisor Name'
                       ][0]
                      }
                     </Button>
                     <Dialog open={openDialog} onOpenChange={setOpenDialog}>
                      <DialogContent>
                       <DialogHeader>
                        <DialogTitle>Would you mind elaborating?</DialogTitle>
                        <DialogDescription>
                         Your feedback helps us improve our service. Why don't
                         you plan on meeting the advisor? This will not be
                         shared with the advisor. Instead, we'll work harder to
                         ensure the advisors are able to provide more value.
                        </DialogDescription>
                       </DialogHeader>
                       <Textarea
                        value={feedback}
                        onChange={e => setFeedback(e.target.value)}
                        placeholder="[Optional] Please share your thoughts..."
                       />
                       <DialogFooter>
                        <Button
                         variant="outline"
                         onClick={() => setOpenDialog(false)}
                        >
                         Cancel
                        </Button>
                        <Button
                         variant="primary"
                         onClick={async () => {
                          try {
                           const response = await fetch(
                            '/api/prospect-action',
                            {
                             method: 'POST',
                             headers: {
                              'Content-Type': 'application/json',
                             },
                             body: JSON.stringify({
                              recordID:
                               advisor[selectedAdvisorIndex].data.fields[
                                'recordID'
                               ],
                              action: 'no_meet',
                              feedback: feedback,
                             }),
                            }
                           );
                           if (response.ok) {
                            toast({
                             title: 'Thank you',
                             description: 'We have noted it down.',
                            });
                            setOpenDialog(false);
                            setFeedback('');
                           } else {
                            console.error('Error sending prospect action');
                           }
                          } catch (error) {
                           console.error(
                            'Error sending prospect action',
                            error
                           );
                          }
                         }}
                        >
                         Submit
                        </Button>
                       </DialogFooter>
                      </DialogContent>
                     </Dialog>
                    </>
                   ) : option === 'spam' ? (
                    <Dialog
                     open={isSpamDialogOpen}
                     onOpenChange={setIsSpamDialogOpen}
                    >
                     <DialogTrigger asChild>
                      <Button className="my-1 w-full bg-red-500 hover:bg-red-600 text-white">
                       Report{' '}
                       {
                        advisor[selectedAdvisorIndex].data.fields[
                         'Advisor Name'
                        ][0]
                       }{' '}
                       as spammer
                      </Button>
                     </DialogTrigger>
                     <DialogContent>
                      <DialogHeader>
                       <DialogTitle>Report Spam</DialogTitle>
                       <DialogDescription>
                        We're sorry to hear that you've experienced spam.
                        Reporting this advisor will:
                        <ul className="list-disc pl-5 mt-2">
                         <li>Trigger an immediate investigation</li>
                         <li>
                          Potentially disable the advisor's ability to receive
                          new prospects
                         </li>
                         <li>Help us improve our anti-spam measures</li>
                        </ul>
                        Are you sure you want to report this advisor for spam?
                       </DialogDescription>
                      </DialogHeader>
                      <DialogFooter>
                       <Button
                        variant="outline"
                        onClick={() => setIsSpamDialogOpen(false)}
                       >
                        Cancel
                       </Button>
                       <Button variant="destructive" onClick={handleSpamReport}>
                        Confirm Report
                       </Button>
                      </DialogFooter>
                     </DialogContent>
                    </Dialog>
                   ) : (
                    <Button
                     className="primary-button2 my-1 hover:text-green-2 w-full"
                     onClick={async () => {
                      try {
                       const response = await fetch('/api/prospect-action', {
                        method: 'POST',
                        headers: {
                         'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                         recordID:
                          advisor[selectedAdvisorIndex].data.fields['recordID'],
                         action: option,
                        }),
                       });
                       if (response.ok) {
                        toast({
                         title: 'Thank you',
                         description: 'We have noted it down.',
                        });
                       } else {
                        console.error('Error sending prospect action');
                       }
                      } catch (error) {
                       console.error('Error sending prospect action', error);
                      }
                     }}
                    >
                     {option === 'yes_meet' &&
                      `I plan on meeting ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
                     {option === 'yes_met' &&
                      `I have met ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
                     {option === 'yes_buy' &&
                      `I have purchased from ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
                    </Button>
                   )}
                  </div>
                 )
                )}
               </div>
               <p className="pt-4 text-gray-500">
                A quick reminder, any meetings you have with advisors will give
                you 1 additional entry to Premiums on Us where we may end up
                paying for 3 months of your premiums.{' '}
                <PremiumsOnUsFAQ
                 className="underline text-green-1 text-start"
                 triggerText="Click here for more information"
                />
               </p>
              </>
             ) : (
              <div>It seems like they haven't sent anything yet</div>
             )}
            </div>
           )}
          </div>
          <div className="space-y-8 divide-y-2 md:basis-1/2">
           <div className="p-4 space-y-2">
            <div className="p-4 block md:hidden">
             <h2 className="font-bold text-xl capitalize">
              {advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}
             </h2>
             <p>
              Company:{' '}
              {advisor[selectedAdvisorIndex].data.fields['Advisor Company']}
             </p>
            </div>
            <h2 className="font-bold text-xl capitalize">Chat history</h2>
            {Array.isArray(
             advisor[selectedAdvisorIndex].data.fields['Message History']
            ) ? (
             advisor[selectedAdvisorIndex].data.fields['Message History'].map(
              (message, index) => (
               <div key={index} className="w-full gap-2">
                <p
                 className={`${
                  message.sender ===
                  advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]
                   ? 'text-start bg-blue-1 px-2 py-1 rounded-xl text-sm md:text-base'
                   : 'text-end bg-white-2 px-2 py-1 rounded-xl text-sm md:text-base border-gray-2 border-2'
                 }`}
                >
                 {message.message}
                </p>
               </div>
              )
             )
            ) : (
             <p className="text-sm md:text-base">
              You two haven't messaged each other.
             </p>
            )}
           </div>
           <div className="p-4 space-y-2">
            <h2 className="font-bold text-xl capitalize">Message Centre</h2>
            <p className="text-sm md:text-base">
             We suggest you talk to the advisors via telegram as there are fewer
             bugs. But if you do not use telegram, you can use this message area
             to message them. It will be sent as an email.
            </p>
            <form className="space-y-2" onSubmit={handleMessageSubmit}>
             <textarea
              id="message"
              name="message"
              rows={8}
              value={comments}
              onChange={e => setComments(e.target.value)}
              className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
             ></textarea>
             <button
              type="submit"
              className="primary-button1 hover:primary-button2"
              disabled={countdown > 0}
             >
              {countdown > 0 ? `Send Message (${countdown}s)` : 'Send Message'}
             </button>
            </form>
           </div>
          </div>
         </div>
        )}
       </div>
      </div>
     </div>
    </section>
   </div>
  </div>
 );
};

export default ProspectCentre;
