import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Image from "./image"
import TypeTag, { getMatchedType } from '../components/TypeTag';
import { Button } from "./ui/button"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "./ui/drawer"

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table"

interface StoryField {
  'TypeIDs': string[];
  'BrandIDs': string[];
  "searchQuery": string;
  'Insurance Types': string[];
  'Insurance Brand': string[];
  'Monthly Policy Premium': number;
  'Total Policy Size': number;
  'Age': number;
  'Preexisting Conditions'?: string[];
  'Image Number': string;
  'Name': string;
  'Gender': string;
  'Monthly Income': number;
  'What year did you buy the policies?': number;
  'List of Policies': string[];
  'Policy Slug': string[];
  'Policy Name': string[];
  'slug': string;
  'Promo'?: boolean;
}

interface Submission {
  products: Policy[];
  total_premium: string;
  advisor_name: string
}

interface Policy {
  company: string | null;
  coverage: string | Record<string, number>;
  duration: string | null;
  name: string | null;
  payment_term: string | null;
  policy_id: string | null;
  premium: number | null;
  rationale: string | null;
  slug: string | null;
  type: string | null;
  type_id: string | null;
}

interface FilteredProduct {
  coverage: {
    [key: string]: number;
  };
  name: string;
  payment_term: string;
  policy_id: string;
  premium: number;
  rationale: string;
  slug: string;
  type: string;
  type_id: string;
}


interface StoriesPageProps {
  stories: Story[];
  selectedTypes: Set<string>;
  selectedBrands: Set<string>;
  searchQuery: string;
  ageFrom: string;
  ageTo: string;
  budgetFrom: string;
  budgetTo: string;
  incomeFrom: string;
  incomeTo: string;
  selectedGender: Set<string>;
  selectedInsurance: Set<string>;
}

interface FilterSectionProps {
  types: Type[];
  brands: Brand[];
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedTypes: Set<string>;
  setSelectedTypes: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedBrands: Set<string>;
  setSelectedBrands: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedGender: Set<string>;
  setSelectedGender: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedInsurance: Set<string>;
  setSelectedInsurance: React.Dispatch<React.SetStateAction<Set<string>>>;
  ageFrom: string;
  ageTo: string;
  budgetFrom: string;
  budgetTo: string;
  incomeFrom: string;
  incomeTo: string;
  setAgeFrom: React.Dispatch<React.SetStateAction<string>>;
  setAgeTo: React.Dispatch<React.SetStateAction<string>>;
  setBudgetFrom: React.Dispatch<React.SetStateAction<string>>;
  setBudgetTo: React.Dispatch<React.SetStateAction<string>>;
  setIncomeFrom: React.Dispatch<React.SetStateAction<string>>;
  setIncomeTo: React.Dispatch<React.SetStateAction<string>>;
}


interface Type {
  id: string;
  fields: {
    Name: string;
    // ... any other fields for Type
  };
}

interface Brand {
  id: string;
  fields: {
    Name: string;
    // ... any other fields for Brand
  };
}

interface Story {
  fields: StoryField;
  submissions: (Submission | null)[];
  filtered_products: FilteredProduct[];
}

type ExploreProps = {
  path: string;
  product?: string;
  tag?: string;
  instype?: string;
  insbrand?: string;
  initialAgeFrom?: string;
  initialAgeTo?: string;
  initialBudgetFrom?: string;
  initialBudgetTo?: string;
  initialIncomeFrom?: string;
  initialIncomeTo?: string;
  initialCoverageFrom?: string;
  initialCoverageTo?: string;
  initialSelectedTypes?: Set<string>;
  initialSelectedBrands?: Set<string>;
  initialSearchQuery?: string;
};


const FilterSection: React.FC<FilterSectionProps> = ({
  types,
  brands,
  searchQuery,
  setSearchQuery,
  selectedBrands,
  setSelectedBrands,
  selectedTypes,
  setSelectedTypes,
  ageFrom,
  ageTo,
  budgetFrom,
  budgetTo,
  incomeFrom,
  incomeTo,
  setAgeFrom,
  setAgeTo,
  setBudgetFrom,
  setBudgetTo,
  setIncomeFrom,
  setIncomeTo,
  selectedGender,
  setSelectedGender,
  selectedInsurance,
  setSelectedInsurance,
}) => {


  const handleBrandChange = (brandId: string) => {
    setSelectedBrands((prevSelectedBrands) => {
      const newSelectedBrands = new Set(prevSelectedBrands);
      if (newSelectedBrands.has(brandId)) {
        newSelectedBrands.delete(brandId);
      } else {
        newSelectedBrands.add(brandId);
      }
      return newSelectedBrands;
    });
  };

  const handleTypeChange = (typeId: string) => {
    setSelectedTypes((prevSelectedTypes) => {
      const newSelectedTypes = new Set(prevSelectedTypes);
      if (newSelectedTypes.has(typeId)) {
        newSelectedTypes.delete(typeId);
      } else {
        newSelectedTypes.add(typeId);
      }
      return newSelectedTypes;
    });
  };

  const handleGenderChange = (gender: string) => {
    setSelectedGender((prevSelectedGender) => {
      const newSelectedGender = new Set(prevSelectedGender);
      if (newSelectedGender.has(gender)) {
        newSelectedGender.delete(gender);
      } else {
        newSelectedGender.add(gender);
      }
      return newSelectedGender;
    });
  };

  const handleInsuranceChange = (insurance: string) => {
    setSelectedInsurance((prevSelectedInsurance) => {
      const newSelectedInsurance = new Set(prevSelectedInsurance);
      if (newSelectedInsurance.has(insurance)) {
        newSelectedInsurance.delete(insurance);
      } else {
        newSelectedInsurance.add(insurance);
      }
      return newSelectedInsurance;
    });
  };

  return (
    <div>
      <div className="hidden md:block col-span-4 md:col-span-1 px-4 space-y-4 sticky top-0">

        <div className="flex justify-between my-6">
          <h3 className="text-h5 text-green-1 font-bold">Age</h3>
          <a href="#" className="filter_reset text-green-1" onClick={(e) => { e.preventDefault(); setAgeFrom(''); setAgeTo(''); }}>Clear</a>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <input
            className="text-sm border rounded-lg px-4 py-2 bg-white-1"
            type="number"
            maxLength={256}
            name="ageFrom"
            placeholder="From"
            value={ageFrom}
            onChange={(e) => setAgeFrom(e.target.value)}
          />
          <input
            className="text-sm border rounded-lg px-4 py-2 bg-white-1"
            type="number"
            maxLength={256}
            name="ageTo"
            placeholder="To"
            value={ageTo}
            onChange={(e) => setAgeTo(e.target.value)}
          />
        </div>

        {/* income Filter */}
        <div className="flex justify-between my-6">
          <h3 className="text-h5 text-green-1 font-bold">Income</h3>
          <a
            href="#"
            className="filter_reset text-green-1"
            onClick={(e) => {
              e.preventDefault();
              setIncomeFrom('');
              setIncomeTo('');
            }}
          >
            Clear
          </a>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <input
            className="text-sm border rounded-lg px-4 py-2 bg-white-1"
            type="number"
            maxLength={256}
            name="incomeFrom"
            placeholder="From"
            value={incomeFrom}
            onChange={(e) => setIncomeFrom(e.target.value)}
          />
          <input
            className="text-sm border rounded-lg px-4 py-2 bg-white-1"
            type="number"
            maxLength={256}
            name="incomeTo"
            placeholder="To"
            value={incomeTo}
            onChange={(e) => setIncomeTo(e.target.value)}
          />
        </div>
        {/* Monthly Premiums Filter */}
        <div className="flex justify-between my-6">
          <h3 className="text-h5 text-green-1 font-bold">Monthly Premiums</h3>
          <a
            href="#"
            className="filter_reset text-green-1"
            onClick={(e) => {
              e.preventDefault();
              setBudgetFrom('');
              setBudgetTo('');
            }}
          >
            Clear
          </a>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <input
            className="text-sm border rounded-lg px-4 py-2 bg-white-1"
            type="number"
            maxLength={256}
            name="budgetFrom"
            placeholder="From"
            value={budgetFrom}
            onChange={(e) => setBudgetFrom(e.target.value)}
          />
          <input
            className="text-sm border rounded-lg px-4 py-2 bg-white-1"
            type="number"
            maxLength={256}
            name="budgetTo"
            placeholder="To"
            value={budgetTo}
            onChange={(e) => setBudgetTo(e.target.value)}
          />
        </div>
        <div className="filter_block">
          <h3 className="text-h5 text-green-1 font-bold">Gender</h3>
          <div className="space-y-1 grid grid-cols-2">
            <div className="space-x-2">
              <input
                type="checkbox"
                id="gender-female"
                name="gender"
                value="Female"
                checked={selectedGender.has('Female')}
                onChange={() => handleGenderChange('Female')}
              />


              <label htmlFor="gender-female" className="text-sm text-gray-500">Female</label>
            </div>
            <div className="space-x-2">
              <input
                type="checkbox"
                id="gender-male"
                name="gender"
                value="Male"
                checked={selectedGender.has('Male')}
                onChange={() => handleGenderChange('Male')}
              />
              <label htmlFor="gender-male" className="text-sm text-gray-500">Male</label>
            </div>
          </div>
        </div>
        {/* need to fix */}
        {/* <div className="filter_block">
        <h3 className="text-h5 text-green-1 font-bold">Current Insurance</h3>
        <div className="space-y-1 grid grid-cols-2">
          <div className="space-x-2">
          <input
            type="checkbox"
            id="insurance-yes"
            name="insurance"
            value="Yes"
            checked={selectedInsurance.has('Yes')}
            onChange={() => handleInsuranceChange('Yes')}
          />
            <label htmlFor="insurance-yes" className="text-sm text-gray-500">Yes</label>
          </div>
          <div className="space-x-2">
          <input
            type="checkbox"
            id="insurance-no"
            name="insurance"
            value="No"
            checked={selectedInsurance.has('No')}
            onChange={() => handleInsuranceChange('No')}
          />

            <label htmlFor="insurance-no" className="text-sm text-gray-500">No</label>
          </div>
        </div>
      </div> */}

        {/* need to fix */}
        {/* Insurance Brand Checkboxes */}
        {/* <div className="filter_block">
        <h3 className="text-h5 text-green-1 font-bold">Insurance Provider</h3>
        <div className="space-y-1 grid grid-cols-2">
          {brands.map((i, index) => (
            <div key={index} className="space-x-2">
              <input type="checkbox" value={i.id} id={`checkbox-brand-${index}`} className="text-sm text-gray-500" onChange={() => handleBrandChange(i.id)}/>
              <label htmlFor={`checkbox-brand-${index}`} className="text-sm text-gray-500">{i.fields.Name}</label>
            </div>
          ))}
        </div>
      </div> */}

        {/* ... other filter blocks */}
      </div>
      <div id="mobile" className="md:hidden *:flex m*:x-auto">
        <div className='flex justify-start sticky top-0 w-full mx-auto z-50'>
          <Drawer>
            <DrawerTrigger asChild className=''>
              <Button variant="primary_2" className='py-2 px-4 text-white-1'>Search (Products & Conditions)</Button>
            </DrawerTrigger>
            <DrawerContent>
              <div className="md:block col-span-4 md:col-span-1 px-8 space-y-4 py-8 bg-white-1">

                <div className="relative">
                  <Image src="/clipboard.svg" alt="search icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
                  <input
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by products or health concerns"
                    className="pl-10 block w-full p-2 border rounded-lg text-xs bg-white-1"
                  />
                </div>

                <div className="flex justify-between my-6">
                  <h3 className="text-h5 text-green-1 font-bold">Age</h3>
                  <a href="#" className="filter_reset text-green-1" onClick={(e) => { e.preventDefault(); setAgeFrom(''); setAgeTo(''); }}>Clear</a>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    className="text-sm border rounded-lg px-4 py-2 bg-white-1"
                    type="number"
                    maxLength={256}
                    name="ageFrom"
                    placeholder="From"
                    value={ageFrom}
                    onChange={(e) => setAgeFrom(e.target.value)}
                  />
                  <input
                    className="text-sm border rounded-lg px-4 py-2 bg-white-1"
                    type="number"
                    maxLength={256}
                    name="ageTo"
                    placeholder="To"
                    value={ageTo}
                    onChange={(e) => setAgeTo(e.target.value)}
                  />
                </div>
                {/* Monthly Premiums Filter */}
                <div className="flex justify-between my-6">
                  <h3 className="text-h5 text-green-1 font-bold">Monthly Premiums</h3>
                  <a
                    href="#"
                    className="filter_reset text-green-1"
                    onClick={(e) => {
                      e.preventDefault();
                      setBudgetFrom('');
                      setBudgetTo('');
                    }}
                  >
                    Clear
                  </a>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    className="text-sm border rounded-lg px-4 py-2 bg-white-1"
                    type="number"
                    maxLength={256}
                    name="budgetFrom"
                    placeholder="From"
                    value={budgetFrom}
                    onChange={(e) => setBudgetFrom(e.target.value)}
                  />
                  <input
                    className="text-sm border rounded-lg px-4 py-2 bg-white-1"
                    type="number"
                    maxLength={256}
                    name="budgetTo"
                    placeholder="To"
                    value={budgetTo}
                    onChange={(e) => setBudgetTo(e.target.value)}
                  />
                </div>

                {/* income Filter */}
                <div className="flex justify-between my-6">
                  <h3 className="text-h5 text-green-1 font-bold">Income</h3>
                  <a
                    href="#"
                    className="filter_reset text-green-1"
                    onClick={(e) => {
                      e.preventDefault();
                      setIncomeFrom('');
                      setIncomeTo('');
                    }}
                  >
                    Clear
                  </a>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    className="text-sm border rounded-lg px-4 py-2 bg-white-1"
                    type="number"
                    maxLength={256}
                    name="incomeFrom"
                    placeholder="From"
                    value={incomeFrom}
                    onChange={(e) => setIncomeFrom(e.target.value)}
                  />
                  <input
                    className="text-sm border rounded-lg px-4 py-2 bg-white-1"
                    type="number"
                    maxLength={256}
                    name="incomeTo"
                    placeholder="To"
                    value={incomeTo}
                    onChange={(e) => setIncomeTo(e.target.value)}
                  />
                </div>
                <div className="filter_block">
                  <h3 className="text-h5 text-green-1 font-bold">Gender</h3>
                  <div className="space-y-1 grid grid-cols-2">
                    <div className="space-x-2">
                      <input
                        type="radio"
                        id="gender-female"
                        name="gender"
                        value="Female"
                        checked={selectedGender.has('Female')}
                        onChange={() => handleGenderChange('Female')}
                      />
                      <label htmlFor="gender-female" className="text-sm text-gray-500">Female</label>
                    </div>
                    <div className="space-x-2">
                      <input
                        type="radio"
                        id="gender-male"
                        name="gender"
                        value="Male"
                        checked={selectedGender.has('Male')}
                        onChange={() => handleGenderChange('Male')}
                      />
                      <label htmlFor="gender-male" className="text-sm text-gray-500">Male</label>
                    </div>
                  </div>
                </div>
                {/* <div className="filter_block">
          <h3 className="text-h5 text-green-1 font-bold">Current Insurance</h3>
          <div className="space-y-1 grid grid-cols-2">
            <div className="space-x-2">
              <input
                type="radio"
                id="insurance-yes"
                name="insurance"
                value="Yes"
                checked={selectedInsurance.has('Yes')}
                onChange={() => handleInsuranceChange('Yes')}
              />
              <label htmlFor="insurance-yes" className="text-sm text-gray-500">Yes</label>
            </div>
            <div className="space-x-2">
              <input
                type="radio"
                id="insurance-no"
                name="insurance"
                value="No"
                checked={selectedInsurance.has('No')}
                onChange={() => handleInsuranceChange('No')}
              />
              <label htmlFor="insurance-no" className="text-sm text-gray-500">No</label>
            </div>
          </div>
        </div> */}

              </div>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

const renderCoverage = (coverage: string | number | Record<string, any>) => {
  if (!coverage) {
    return null;
  }

  if (typeof coverage === 'string') {
    return <div className="text-gray-500 text-base">{coverage}</div>;
  } else if (typeof coverage === 'number') {
    return <div className="flex text-gray-500 text-base capitalize justify-end">{formatCurrency(coverage)}</div>;
  } else {
    return (
      <div >
        {Object.entries(coverage).map(([key, value]) => {
          if (Array.isArray(value)) {
            return (
              <div key={key} className="w-full flex flex-col gap-2">
                <div className="text-gray-500 text-base capitalize font-semibold">{key.replace(/_/g, ' ')}:</div>
                <div className="pl-4">
                  {value.map((item, index) => (
                    <div key={index} className="mb-2">
                      {renderCoverage(item)}
                    </div>
                  ))}
                </div>
              </div>
            );
          } else if (typeof value === 'object' && value !== null) {
            return (
              <div key={key} className="w-full flex flex-col gap-2">
                <div className="text-gray-500 text-base capitalize font-semibold">{key.replace(/_/g, ' ')}:</div>
                <div className="pl-4">{renderCoverage(value)}</div>
              </div>
            );
          } else {
            return (
              <div key={key} className="w-full flex justify-between gap-8">
                <div className="text-gray-800 text-base capitalize">{key.replace(/_/g, ' ')}</div>
                <div className="text-gray-500 text-base">
                  {typeof value === 'number' ? formatCurrency(value) : value as string}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
};

export function formatCurrency(value) {
  // Check if the value is null or undefined; return a default string in such cases
  if (value === null || value === undefined) {
    return "$0";
  }

  // Convert the value to a number
  const number = Number(value);

  // Check if the number has decimal places
  if (Number.isInteger(number)) {
    // If the number is an integer, format it without decimal places
    return `$${number.toLocaleString()}`;
  } else {
    // If the number has decimal places, format it with 2 decimal places
    return `$${number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
}

interface Ad {
  image: string;
  header: string;
  text: string;
  ctaText: string;
  ctaLink: string;
}

const StoriesPage: React.FC<StoriesPageProps> = ({
  stories,
  selectedTypes,
  selectedBrands,
  ageFrom,
  ageTo,
  budgetFrom,
  budgetTo,
  incomeFrom,
  incomeTo,
  searchQuery,
  selectedGender,
  selectedInsurance,
}) => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [displayedStoriesCount, setDisplayedStoriesCount] = useState(15);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch('/api/explore-ads');
        if (!response.ok) {
          throw new Error('Failed to fetch ads');
        }
        const data = await response.json();
        setAds(data);
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, []);

  const filteredStories = useMemo(() => {
    return stories.filter(story => {
      const typeMatch = selectedTypes.size === 0 || (
        story.submissions &&
        story.submissions.some(submission =>
          submission?.products.some(plan => {
            const matchedType = getMatchedType(plan.type);
            return matchedType && selectedTypes.has(matchedType.typeId);
          })
        )
      );

      const brandMatch = selectedBrands.size === 0 ||
        (story.fields['Insurance Brand'] &&
          story.fields['Insurance Brand'].some((brandId: string) => selectedBrands.has(brandId)));

      const genderMatch = selectedGender.size === 0 ||
        (story.fields['Gender'] && selectedGender.has(story.fields['Gender']));

      const insuranceMatch = selectedInsurance.size === 0 ||
        (selectedInsurance.has('Yes') && story.fields['Medical?']?.length > 0) ||
        (selectedInsurance.has('No') && (!story.fields['Medical?'] || story.fields['Medical?'].length === 0));

      const lowerCaseQuery = searchQuery.toLowerCase();

      const searchMatch = lowerCaseQuery === '' ||
        (Array.isArray(story.fields['Policy Name']) && story.fields['Policy Name'].some(name => name.toLowerCase().includes(lowerCaseQuery))) ||
        (Array.isArray(story.fields['Insurance Types']) && story.fields['Insurance Types'].some(type => type.toLowerCase().includes(lowerCaseQuery))) ||
        (Array.isArray(story.fields['Insurance Brand']) && story.fields['Insurance Brand'].some(brand => brand.toLowerCase().includes(lowerCaseQuery))) ||
        story.fields['Name']?.toLowerCase().includes(lowerCaseQuery) ||
        story.fields['Gender']?.toLowerCase().includes(lowerCaseQuery) ||
        story.fields['Monthly Income']?.toString().includes(lowerCaseQuery) ||
        story.fields['Age']?.toString().includes(lowerCaseQuery) ||
        story.fields['Medical Condition']?.toString().includes(lowerCaseQuery);

      return (
        (!ageFrom || story.fields.Age >= parseInt(ageFrom)) &&
        (!ageTo || story.fields.Age <= parseInt(ageTo)) &&
        (!incomeFrom || story.fields['Monthly Income'] >= parseFloat(incomeFrom)) &&
        (!incomeTo || story.fields['Monthly Income'] <= parseFloat(incomeTo)) &&
        typeMatch &&
        brandMatch &&
        searchMatch &&
        genderMatch &&
        insuranceMatch
      );
    });
  }, [stories, searchQuery, selectedTypes, selectedBrands, ageFrom, ageTo, incomeFrom, incomeTo, selectedGender, selectedInsurance]);

  const currentStories = filteredStories.slice(0, displayedStoriesCount);

  const handleLoadMore = () => {
    setDisplayedStoriesCount(prevCount => Math.min(prevCount + 15, filteredStories.length));
  };

  const isFiltered = () => {
    return selectedTypes.size > 0 ||
      selectedBrands.size > 0 ||
      ageFrom !== '' ||
      ageTo !== '' ||
      budgetFrom !== '' ||
      budgetTo !== '' ||
      incomeFrom !== '' ||
      incomeTo !== '' ||
      searchQuery !== '' ||
      selectedGender.size > 0 ||
      selectedInsurance.size > 0
  };

  const renderStoryOrAd = (item: Story, index: number) => {
    const adPositions = [1, 5, 9];
    if (adPositions.includes(index) && ads.length > 0 && !isFiltered()) {
      const adIndex = adPositions.indexOf(index);
      if (adIndex < ads.length) {
        const ad = ads[adIndex];
        return (
          <a href={ad.ctaLink} key={`ad-${index}`} className="flex flex-col h-full bg-white-2 shadow rounded p-4 relative hover:cursor-pointer hover:shadow-lg transition-shadow duration-300">
            <div className="absolute top-2 right-2 bg-white-1 text-gray-500 px-2 py-1 text-xs font-semibold rounded z-10">
              Ad
            </div>
            <Image src={ad.image} alt={ad.header} className="w-full mb-2" dimension='square'/>
            <h3 className="font-serif font-bold text-2xl mb-2">{ad.header}</h3>
            <p className="flex-grow mb-4">{ad.text}</p>
            <div className="primary-button2 text-center w-full">
              {ad.ctaText}
            </div>
          </a>
        );
      }
    }
  
    // Render normal story content
    const validSubmissions = item.submissions?.filter(submission => submission !== null);
    const uniqueTypes = validSubmissions?.reduce((types, submission) => {
      if (submission && submission.products) {
        submission.products.forEach(plan => {
          const matchedType = getMatchedType(plan.type);
          if (matchedType && !types.some(t => t.typeId === matchedType.typeId)) {
            types.push(matchedType);
          }
        });
      }
      return types;
    }, []);
  
    return (
      <a href={`/insurance-story/${item.fields['slug']}`} key={index} role="listitem" className="flex flex-col h-full bg-white-2 shadow rounded p-4 hover:cursor-pointer hover:shadow-lg transition-shadow duration-300">
        <div className="flex-shrink-0 w-full p-4">
          <img loading="lazy"
            src={`/images/generic/${item.fields['Image Number'] ?? 'default'}.svg`}
            alt={`Whatins ${item.fields['Image Number'] ?? 'default'}`}
            className='w-full' />
        </div>
        <div className="flex flex-col flex-grow p-1.5 gap-1.5">
          <h3 className="font-serif font-bold text-2xl">{item.fields['Name']}, {item.fields['Age']}</h3>
          <div className="flex justify-between">
            <p className="font-semibold">Gender</p>
            <p>{`${item.fields['Gender'] ?? 'Unknown'}`}</p>
          </div>
          <div className="flex justify-between">
            <p className="font-semibold">Monthly Income</p>
            <p>{formatCurrency(item.fields['Monthly Income'])}</p>
          </div>
          {validSubmissions && validSubmissions.length > 0 && (
            <>
              <div>
                <p className="font-semibold">Insurance types suggested</p>
                {uniqueTypes?.map((matchedType, typeIndex) => (
                  <TypeTag key={typeIndex} type={matchedType.output} />
                ))}
              </div>
            </>
          )}
          {item.fields['Medical Condition'] && (
            <div className="flex flex-col justify-between">
              <div>
                <div className="flex-shrink-0">
                  <p className="font-semibold pt-4">Preexisting Condition:</p>
                  <p>{item.fields['Medical Condition']}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="grid w-full my-4">
          <a href={`/insurance-story/${item.fields['slug']}`} className="primary-button2 text-center w-full">
            Read More
          </a>
        </div>
      </a>
    );
  };

  return (
    <div className="col-span-4 md:col-span-3 mx-4">
      {filteredStories && filteredStories.length > 0 ? (
        <div className="col-span-3 md:col-span-2">
          <div className="collection-list-wrapper w-dyn-list flex flex-col">
            <div role="list" className="collection-list-2 w-dyn-items grid grid-cols-1 md:grid-cols-3 gap-4">
              {currentStories.map((item, index) => renderStoryOrAd(item, index))}
            </div>
            {displayedStoriesCount < filteredStories.length && (
              <div className='flex flex-row w-full justify-center'>
                <button onClick={handleLoadMore} className="primary-button2 my-4">
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div>Looks like we didn't find any matches.</div>
          <div className="filter_empty">
            {/* Empty State Content */}
          </div>
        </div>
      )}
    </div>
  );
};

const Explore: React.FC<ExploreProps> = ({
  path,
  product,
  tag,
  instype,
  insbrand,
  initialAgeFrom = '',
  initialAgeTo = '',
  initialBudgetFrom = '',
  initialBudgetTo = '',
  initialIncomeFrom = '',
  initialIncomeTo = '',
  initialCoverageFrom = '',
  initialCoverageTo = '',
  initialSelectedTypes = new Set(),
  initialSelectedBrands = new Set(),
  initialSearchQuery = '',
}) => {
  const [stories, setStories] = useState<Story[]>([]);
  const [ageFrom, setAgeFrom] = useState(initialAgeFrom);
  const [ageTo, setAgeTo] = useState(initialAgeTo);
  const [budgetFrom, setBudgetFrom] = useState(initialBudgetFrom);
  const [budgetTo, setBudgetTo] = useState(initialBudgetTo);
  const [incomeFrom, setIncomeFrom] = useState(initialIncomeFrom);
  const [incomeTo, setIncomeTo] = useState(initialIncomeTo);
  const [coverageFrom, setCoverageFrom] = useState(initialCoverageFrom);
  const [coverageTo, setCoverageTo] = useState(initialCoverageTo);
  const [types, setTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState<Set<string>>(initialSelectedTypes);
  const [selectedBrands, setSelectedBrands] = useState<Set<string>>(initialSelectedBrands);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [selectedGender, setSelectedGender] = useState<Set<string>>(new Set());
  const [selectedInsurance, setSelectedInsurance] = useState<Set<string>>(new Set());

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const utmAge = searchParams.get('utm_age');
  const utmGender = searchParams.get('utm_gender');
  const utmBudget = searchParams.get('utm_budget');
  const utmInsurance = searchParams.get('utm_insurance');
  const utmIncome = searchParams.get('utm_income');
  const utmCoverage = searchParams.get('utm_coverage');

  useEffect(() => {
    const fetchStories = (isReduced = false) => {
      const params = new URLSearchParams();
      if (product) params.append('product', product);
      if (tag) params.append('tag', tag);
      if (instype) params.append('type', instype);
      if (insbrand) params.append('brand', insbrand);
      if (isReduced) params.append('reduced', 'true');

      const baseUrl = window.location.origin;
      const apiUrl = `/api/new-start?${params}`;

      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data && data.stories) {
            setStories(data.stories);
            setTypes(data.types || []);
            setBrands(data.brands || []);
          } else {
            console.error('Response JSON does not contain the expected data');
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    };

    fetchStories(true);
    fetchStories();

    if (utmAge) {
      const [fromAge, toAge] = utmAge.split('-');
      setAgeFrom(fromAge);
      setAgeTo(toAge);
      updateURL('utm_age', utmAge);
    }

    if (utmGender) {
      const genders = utmGender.split(',');
      setSelectedGender(new Set(genders));
      updateURL('utm_gender', utmGender);
    }

    if (utmBudget) {
      const [fromBudget, toBudget] = utmBudget.split('-');
      setBudgetFrom(fromBudget);
      setBudgetTo(toBudget);
      updateURL('utm_budget', utmBudget);
    }

    if (utmInsurance) {
      setSelectedInsurance(new Set([utmInsurance]));
      updateURL('utm_insurance', utmInsurance);
    }

    if (utmIncome) {
      const [fromIncome, toIncome] = utmIncome.split('-');
      setIncomeFrom(fromIncome);
      setIncomeTo(toIncome);
      updateURL('utm_income', utmIncome);
    }

    if (utmCoverage) {
      const [fromCoverage, toCoverage] = utmCoverage.split('-');
      setCoverageFrom(fromCoverage);
      setCoverageTo(toCoverage);
      updateURL('utm_coverage', utmCoverage);
    }
  }, []);

  const handleTypeChange = (typeId: string) => {
    setSelectedTypes((prevSelectedTypes) => {
      const newSelectedTypes = new Set(prevSelectedTypes);
      if (newSelectedTypes.has(typeId)) {
        newSelectedTypes.delete(typeId);
      } else {
        newSelectedTypes.add(typeId);
      }
      updateURL('utm_type', Array.from(newSelectedTypes).join(','));
      return newSelectedTypes;
    });
  };

  const handleBrandChange = (brandId: string) => {
    setSelectedBrands((prevSelectedBrands) => {
      const newSelectedBrands = new Set(prevSelectedBrands);
      if (newSelectedBrands.has(brandId)) {
        newSelectedBrands.delete(brandId);
      } else {
        newSelectedBrands.add(brandId);
      }
      updateURL('utm_brand', Array.from(newSelectedBrands).join(','));
      return newSelectedBrands;
    });
  };

  const handleGenderChange = (gender: string) => {
    setSelectedGender((prevSelectedGender) => {
      const newSelectedGender = new Set(prevSelectedGender);
      if (newSelectedGender.has(gender)) {
        newSelectedGender.delete(gender);
      } else {
        newSelectedGender.add(gender);
      }
      updateURL('utm_gender', Array.from(newSelectedGender).join(','));
      return newSelectedGender;
    });
  };

  const handleInsuranceChange = (insurance: string) => {
    setSelectedInsurance((prevSelectedInsurance) => {
      const newSelectedInsurance = new Set(prevSelectedInsurance);
      if (newSelectedInsurance.has(insurance)) {
        newSelectedInsurance.delete(insurance);
      } else {
        newSelectedInsurance.add(insurance);
      }
      return newSelectedInsurance;
    });
  };

  const handleAgeChange = () => {
    updateURL('utm_age', `${ageFrom}-${ageTo}`);
  };

  const handleBudgetChange = () => {
    updateURL('utm_budget', `${budgetFrom}-${budgetTo}`);
  };

  const handleIncomeChange = () => {
    updateURL('utm_income', `${incomeFrom}-${incomeTo}`);
  };

  const handleCoverageChange = () => {
    updateURL('utm_coverage', `${coverageFrom}-${coverageTo}`);
  };

  const updateURL = (param: string, value: string) => {
    const newUrl = new URL(window.location.href);
    if (value) {
      newUrl.searchParams.set(param, value);
    } else {
      newUrl.searchParams.delete(param);
    }
    window.history.pushState(null, '', newUrl.toString());
  };

  return (
    <section className="grid justify-items-center py-4 text-black bg-white-1">
      <div className="w-full max-w-screen-2xl px-2 md:px-4 pb-8">
        <div className="w-full gap-4">
          <div className="pt-8">
            <div className="relative">
              <Image src="/clipboard.svg" alt="search icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
              <input
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by products or health concerns"
                className="pl-10 block w-full p-2 text-xs md:text-sm border rounded-lg bg-white-1 mb-4"
              />
            </div>

            <div className="mb-4">
              <div>
                <ul className="grid grid-cols-3 md:grid-cols-8 gap-4 pt-4 h-full">
                  {types.map((type, index) => (
                    <li key={index} className={`flex flex-col rounded-lg ${selectedTypes.has(type.id) ? 'bg-white-2' : 'bg-white-1'} has-[:checked]:border-b-2 has-[:checked]:border-sav-green-1 has-[:hover]:sav-green-2 h-full`}>
                      <input
                        type="checkbox"
                        name={type.id}
                        id={type.id}
                        value="checked"
                        className="hidden peer"
                        checked={selectedTypes.has(type.id)}
                        onChange={() => handleTypeChange(type.id)}
                      />
                      <label htmlFor={type.id} className="flex flex-col items-center justify-between text-gray-500 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 h-full">
                        <div className="flex flex-col justify-between h-full p-4">
                          <Image src={`${type.fields['Image Source']}.svg`} alt={type.fields['Questionnaire Name']} className="flex-1" />
                          <div className="text-gray-500 text-center mt-auto">{type.fields['Questionnaire Name']}</div>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-4 px-2 md:px-4 gap-6 max-w-screen-2xl">
        <FilterSection
          types={types}
          brands={brands}
          searchQuery={searchQuery}
          selectedTypes={selectedTypes}
          setSearchQuery={setSearchQuery}
          setSelectedTypes={setSelectedTypes}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          ageFrom={ageFrom}
          ageTo={ageTo}
          budgetFrom={budgetFrom}
          budgetTo={budgetTo}
          incomeFrom={incomeFrom}
          incomeTo={incomeTo}
          setAgeFrom={setAgeFrom}
          setAgeTo={setAgeTo}
          setBudgetFrom={setBudgetFrom}
          setBudgetTo={setBudgetTo}
          setIncomeFrom={setIncomeFrom}
          setIncomeTo={setIncomeTo}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
          selectedInsurance={selectedInsurance}
          setSelectedInsurance={setSelectedInsurance}
        />
        <StoriesPage
          stories={stories}
          selectedTypes={selectedTypes}
          selectedBrands={selectedBrands}
          searchQuery={searchQuery}
          ageFrom={ageFrom}
          ageTo={ageTo}
          budgetFrom={budgetFrom}
          budgetTo={budgetTo}
          incomeFrom={incomeFrom}
          incomeTo={incomeTo}
          selectedGender={selectedGender}
          selectedInsurance={selectedInsurance}
        />
      </div>
    </section>
  );
};

interface MiniExploreProps {
  criteria?: string;
  age?: number;
  income?: number;
  policy_id?: string;
  policy_slug?: string;
  type_id?: string;
  exclude?: string;
  header?: string;
  header_class?: string;
  paragraph?: string;
  paragraph_class?: string;
  cta_text?: string;
  cta_link?: string;
  fallback?: React.ReactElement;
  readMore?: boolean;
  header_className?: string;
  rationale?: boolean;
}

const MiniExplore: React.FC<MiniExploreProps> = ({ criteria, age, income, policy_id, type_id, exclude, header, header_class = 'primary-h3 text-green-1 text-center', paragraph_class = 'text-center text-gray-500', paragraph, cta_text = "See all insurance suggestions →", cta_link = "/explore", fallback, rationale = false }) => {
  const [stories, setStories] = useState<Story[]>([]);

  useEffect(() => {
    const fetchStories = async () => {
      const baseUrl = window.location.origin;
      let apiUrl = `${baseUrl}/api/stories?${criteria}`;

      // Add filter parameters to the API URL based on the provided arguments
      if (age !== undefined) {
        apiUrl += `&age=${age}`;
      }
      if (income !== undefined) {
        apiUrl += `&income=${income}`;
      }
      if (policy_id !== undefined) {
        apiUrl += `&policy_id=${policy_id}`;
      }
      if (type_id !== undefined) {
        apiUrl += `&type_id=${type_id}`;
      }
      if (exclude !== undefined) {
        apiUrl += `&exclude=${exclude}`;
      }

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data) {
          setStories(data.stories.slice(0, 3));
        } else {
          console.error('Response JSON does not contain the expected data');
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchStories();
  }, [criteria, age, income, policy_id, type_id, exclude]);

  if (stories.length === 0) {
    return fallback;
  }

  return (
    <div className='flex flex-col p-8 max-w-screen-2xl mx-auto'>
      <div className='flex flex-col max-w-screen-2xl mx-auto'>
        <h2 className={header_class}>{header}</h2>
        <p className={paragraph_class}>{paragraph}</p>
      </div>
      <div className='py-8 max-w-screen-xl mx-auto'>
        <div className="mini-explore grid grid-cols-1 md:grid-cols-3 gap-4 justify-center content-center mx-auto">
          {stories.length === 0 ? (
            <div className="text-center bg-white-2">
              <Image src='insurance-not-found.png' className='p-8' imgClass='max-h-40' />
              <p>Sorry, we have no stories that match your criteria</p>
            </div>
          ) : (
            stories.map((item, index) => {
              const validSubmissions = item.submissions?.filter(submission => submission !== null);
              const uniqueTypes = item.filtered_products?.reduce((types, product) => {
                const matchedType = getMatchedType(product.type);
                if (matchedType && !types.some(t => t.typeId === matchedType.typeId)) {
                  types.push(matchedType);
                }
                return types;
              }, []);

              return (
                <a href={`/insurance-story/${item.fields['slug']}`} key={index} role="listitem" className="flex flex-col h-full bg-white shadow rounded p-4 hover:cursor-pointer justify-self-center">
                  <div className="flex-shrink-0 w-full p-4">
                    <img loading="lazy"
                      src={`/images/generic/${item.fields['Image Number'] ?? 'default'}.svg`}
                      alt={`Whatins ${item.fields['Image Number'] ?? 'default'}`}
                      className='w-full' />
                  </div>
                  <div className="flex flex-col flex-grow p-1.5 gap-1.5">
                    <h3 className="text-h5 text-green-1">{item.fields['Name']}, {item.fields['Age']}</h3>
                    <div className="flex justify-between">
                      <p className="font-semibold">Gender</p>
                      <p>{`${item.fields['Gender'] ?? 'Unknown'}`}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="font-semibold">Monthly Income</p>
                      <p>{formatCurrency(item.fields['Monthly Income'])}</p>
                    </div>
                    {item.fields['Medical Condition'] && (
                      <div className="flex flex-col justify-between">
                        <div>
                          <div className="flex-shrink-0">
                            <p className="font-semibold pt-4">Preexisting Condition:</p>
                            <p>{item.fields['Medical Condition']}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {uniqueTypes && uniqueTypes.length > 0 && (
                      <div>
                        <p className="font-semibold">Insurance types suggested</p>
                        {uniqueTypes.map((matchedType, typeIndex) => (
                          <TypeTag key={typeIndex} type={matchedType.output} />
                        ))}
                      </div>
                    )}
                    {rationale && item.filtered_products && item.filtered_products.length > 0 && (
                      <div>
                        {item.filtered_products.length > 0 && (
                          <div className='gap-4'>
                            <div className='font-semibold'>Why {item.filtered_products[0].name} was suggested:</div>
                            <div className='text-gray-500'>{item.filtered_products[0].rationale}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="grid w-full my-4">
                    <a href={`/insurance-story/${item.fields['slug']}`} className="primary-button2 w-full text-center">
                      Read More
                    </a>
                  </div>
                </a>
              );
            })
          )}
        </div>
      </div>
      <a href={cta_link} className="primary-button2 w-full text-center max-w-screen-xl mx-auto">
        {cta_text}
      </a>
    </div>
  );
};

const ExplorePolicies: React.FC = () => {
  const [policies, setPolicies] = useState([]);
  const [types, setTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState('');

  useEffect(() => {
    // Fetch the JSON data from the backend API
    fetch('/api/policy-filter')
      .then(response => response.json())
      .then(data => {
        setPolicies(data.policies);
        setTypes(data.type);
        setBrands(data.brand);
      })
      .catch(error => {
        console.error('Error fetching policies:', error);
      });
  }, []);

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);
    setSelectedBrand('');
    setSelectedPolicy('');
  };

  const handleBrandChange = (event) => {
    const selectedBrand = event.target.value;
    setSelectedBrand(selectedBrand);
    setSelectedPolicy('');
  };

  const handlePolicyChange = (event) => {
    const selectedPolicy = event.target.value;
    setSelectedPolicy(selectedPolicy);
  };

  const filteredPolicies = policies.filter(policy =>
    (selectedType === '' || policy.type === selectedType) &&
    (selectedBrand === '' || policy.brand === selectedBrand)
  );

  const filteredBrands = [...new Set(filteredPolicies.map(policy => policy.brand))];

  const handleButtonClick = () => {
    const selectedPolicyObj = policies.find(policy => policy.name === selectedPolicy);
    if (selectedPolicyObj) {
      window.location.href = "/insurance-products/" + selectedPolicyObj.slug;
    }
  };

  return (
    <div className='py-24 bg-no-repeat bg-right gap-6' style={{ backgroundImage: "url(/images/explore-policies.svg)" }}>
      <div className='flex flex-col md:flex-row gap-4 mx-auto max-w-screen-xl px-4'>
        <div className='flex flex-col basis-1/2 grow-0 gap-2'>
          <h2 className='primary-h2 text-start'>Policy Breakdown</h2>
          <p className='text-green-1'>Understand what any policy has to offer. Read terms and conditions in plan English and discover the good, the bad and the lacking features of the policy.</p>
        </div>
        <div className='basis-1/2 shrink-0'></div>
      </div>
      <div className='flex flex-col md:flex-row gap-4 mx-auto max-w-screen-xl w-full mt-8 px-4'>
        <select value={selectedType} onChange={handleTypeChange} className='basis-1/4 rounded-lg'>
          <option value="">Select Insurance Type</option>
          {types.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>

        <select value={selectedBrand} onChange={handleBrandChange} className='basis-1/4 rounded-lg'>
          <option value="">Select Insurer</option>
          {filteredBrands.map(brand => (
            <option key={brand} value={brand}>{brand}</option>
          ))}
        </select>

        <select value={selectedPolicy} onChange={handlePolicyChange} className='basis-1/4 rounded-lg'>
          <option value="">Select Policy</option>
          {filteredPolicies.map(policy => (
            <option key={policy.name} value={policy.name}>{policy.name}</option>
          ))}
        </select>

        <button className='basis-1/4 primary-button2 relative' onClick={handleButtonClick}>
          <Image src="/search.svg" alt="search icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
          <p className="text-white pl-4 block">Search Policy</p></button>
      </div>
    </div>
  );
};


const PremiumSample: React.FC<MiniExploreProps> = ({ criteria, age, income, policy_id, policy_slug, type_id, exclude, header, paragraph, cta_text = "Get your own quotes →", cta_link = "/lp/get-proposals?source=premium-sample", fallback, readMore = true, header_className }) => {
  const [stories, setStories] = useState<Story[]>([]);

  useEffect(() => {
    const fetchStories = async () => {
      const baseUrl = window.location.origin;
      let apiUrl = `${baseUrl}/api/stories?${criteria}`;

      // Add filter parameters to the API URL based on the provided arguments
      if (age !== undefined) {
        apiUrl += `&age=${age}`;
      }
      if (income !== undefined) {
        apiUrl += `&income=${income}`;
      }
      if (policy_id !== undefined) {
        apiUrl += `&policy_id=${policy_id}`;
      }
      if (policy_slug !== undefined) {
        apiUrl += `&policy_slug=${policy_slug}`;
      }
      if (type_id !== undefined) {
        apiUrl += `&type_id=${type_id}`;
      }
      if (exclude !== undefined) {
        apiUrl += `&exclude=${exclude}`;
      }

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data) {
          setStories(data.stories.slice(0, 6));
        } else {
          console.error('Response JSON does not contain the expected data');
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchStories();
  }, [criteria, age, income, policy_id, type_id, exclude]);

  if (stories.length === 0) {
    return fallback;
  }

  return (
    <div id='Explore' className='w-full'>
      <div className='flex flex-col p-8 max-w-screen-2xl mx-auto'>
        <div className='flex flex-col max-w-screen-2xl'>
          <h2 className={`${header_className ? header_className : 'primary-h3 text-green-1 text-start capitalize'}`}>
            {header}</h2>
          <p className='text-start'>{paragraph}</p>
        </div>
        <div className='py-8'>
          <div className="mini-explore grid grid-cols-1 md:grid-cols-1 gap-4 justify-center content-center mx-auto">
            {stories.length > 0 && (
              <div className="bg-white-1 rounded">
                <div className="relative overflow-x-auto mt-4">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="*:primary-h5 *:text-gray-500 uppercase bg-green-2">
                      <tr className='flex flex-row'>
                        <th scope="col" className="px-6 py-3 basis-1/5">Age</th>
                        <th scope="col" className="px-6 py-3 basis-3/5">What suggestion covers</th>
                        <th scope="col" className="px-6 py-3 basis-1/5">Premium</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stories.map((item, index) => (
                        item.filtered_products && item.filtered_products.map((product, productIndex) => (
                          <tr key={`${index}-${productIndex}`} className="bg-white-2 border-b dark:bg-gray-800 dark:border-gray-700 flex flew-row">
                            <th scope="row" className="px-6 py-4 basis-1/5 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {item.fields['Age']}, {item.fields['Gender']} making<br />{formatCurrency(item.fields['Monthly Income'])} a month
                            </th>
                            <td className="px-6 py-4 basis-3/5">{renderCoverage(product.coverage)}</td>
                            <td className="px-6 py-4 basis-1/5 flex flex-col gap-4">
                              <div>
                                {product.premium !== null && product.premium !== 0 ?
                                  `${formatCurrency(product.premium)} ${product.payment_term}` :
                                  'Not provided'}
                              </div>
                              {readMore && (
                                <div>
                                  <a
                                    href={`/insurance-story/${item.fields['slug']}`}
                                    className="mt-auto text-gray-900 underline font-semibold flex text-center"
                                  >
                                    Read More
                                  </a>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <a href={cta_link} className="primary-button2 w-full text-center mx-auto">
          {cta_text}
        </a>
      </div>
    </div>
  );
};

export default Explore;
export { MiniExplore };
export { ExplorePolicies };
export { PremiumSample };