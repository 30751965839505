import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet'
import { useNavigate } from 'react-router-dom';
import { GetStartedSidebar, useAdVariation, GetStartedSample, GetStartedTestimonial } from '../components/CTA'
import Image from '../components/image'
import { Button } from "../components/ui/button"
import TypeTag from '../components/TypeTag';
import { MiniExplore, PremiumSample } from '../components/explore';
import posthog from 'posthog-js'
import { useToast } from "../components/ui/use-toast"
import { ScrollArea, ScrollBar } from '../components/ui/scroll-area'


import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet"

interface SimilarPolicy {
  name: string;
  internalDescription: string;
  slug: string;
}

interface SimilarPoliciesProps {
  recordID: string;
}

export const SimilarPolicies: React.FC<SimilarPoliciesProps> = ({ recordID }) => {
  const [similarPolicies, setSimilarPolicies] = useState<SimilarPolicy[]>([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchSimilarPolicies = async () => {
      try {
        const response = await fetch('/api/similar-policies', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ recordID }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch similar policies');
        }

        const data = await response.json();
        setSimilarPolicies(data);
      } catch (error) {
        console.error('Error fetching similar policies:', error);
      }
    };

    fetchSimilarPolicies();
  }, [recordID]);
  const visiblePolicies = showAll ? similarPolicies : similarPolicies.slice(0, 3);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-8">
        {similarPolicies.map((policy, index) => (
          <div 
            key={index} 
            className={`bg-white rounded-lg shadow-md p-6 ${index >= 3 && !showAll ? 'hidden' : ''}`}
          >
            <h3 className="text-xl font-semibold mb-2">{policy.name}</h3>
            <p className="text-gray-600 mb-4 flex-grow">{policy.internalDescription}</p>
            <a href={`/insurance-products/${policy.slug}`}>
              <Button className="primary-button1 w-full">
                Learn More
              </Button>
            </a>
          </div>
        ))}
      </div>
      {similarPolicies.length > 3 && (
        <div className="text-center mt-4">
          <Button 
            onClick={() => setShowAll(!showAll)} 
            className="primary-button2"
          >
            {showAll ? 'Show Less' : `View all ${similarPolicies.length} policies`}
          </Button>
        </div>
      )}
    </div>
  );
};

interface MobileDropdownProps {
  productData: ProductDetailsData;
  unique: ProductDetailsData;
}

interface Product {
  // Define the types for your product properties
  Name: string;
  summary: string;
  [key: string]: any; // Add other relevant fields
}

interface SimilarItem {
  fields: {
    slug: string;
    Name: string;
  };
}

interface SimilarProductsProps {
  productData: {
    similar?: SimilarItem[];
  };
}

interface InsuranceProductResponse {
  airtable: AirtableRecord;
  blog: Record<string, any>;
  similar: any[];
}

interface AirtableFields {
  [key: string]: any;
}

interface AirtableRecord {
  id: string;
  fields: AirtableFields;
}

interface InsuranceProductResponse {
  airtable: AirtableRecord;
  blog: Record<string, any>;
  similar: any[];
  unique: Record<string, any>;
}

interface ProductDetailsData {
  [key: string]: {
    header: string;
    description: string;
    content?: Array<{ header: string; description: string }>;
  };
}

interface ProductData {
  airtable: AirtableRecord;
  blog: ProductDetailsData;
  similar: any[];
}


const parseDescription = (description: string) => {
  const addClassToLinks = (html: string) => {
    return html.replace(/<a\s/g, '<a class="primary-link" ');
  };

  return description.split('<br/>').map((paragraph, index) => (
    <p 
      key={index} 
      className='px-4 pb-6' 
      dangerouslySetInnerHTML={{ __html: addClassToLinks(paragraph) }} 
    />
  ));
};

const Section = ({ value, index, isUnique = false }) => (
  <div key={index} id={`section${index + 1}`} className='md:pt-8 md:pb-6'>
    <h2 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">{value.header}</h2>
    {parseDescription(value.description)}
    {(isUnique ? value['perks'] : value.content) && (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
        {(isUnique ? value['perks'] : value.content).map((item, itemIndex) => (
          <div key={itemIndex} className="rounded-2xl md:border-2 border-gray-200 p-6">
            <h3 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">{item.header}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    )}
  </div>
);

const ProductDetails = ({ productData, policy_id, name, unique }: { productData: ProductDetailsData, policy_id: string, name: string, unique: ProductDetailsData }) => {
  return (
    <div className="flex flex-col pb-12">
      {Object.entries(productData).map(([key, value], index) => (
        <React.Fragment key={key}>
          <Section value={value} index={index} />
          {index === 1 && (
            <div className="bg-green-2">
              <GetStartedTestimonial testimonial={1} source="policy_page_testimonial_3" />
            </div>
          )}
          {index === 3 && (
            <div className='pb-4'>
              <PremiumSample
                policy_id={policy_id}
                header={`Real premiums suggested for ${name}`}
              />
            </div>
          )}
          {index === 5 && Object.keys(productData).length > 4 && (
            <div className="bg-green-2">
              {/* <GetStartedTestimonial testimonial={2} source="policy_page_testimonial_3" /> */}
            </div>
          )}
          {index === 7 && Object.keys(productData).length > 6 && (
            <div className="bg-green-2">
              {/* <GetStartedTestimonial testimonial={3} source="policy_page_testimonial_3" /> */}
            </div>
          )}
        </React.Fragment>
      ))}
      {unique && Object.entries(unique).map(([key, value], index) => (
        <Section key={key} value={value} index={index} isUnique={true} />
      ))}
    </div>
  );
};

const StickyRightBar=({ productData, policy_id, name, unique }: { productData: ProductDetailsData, policy_id: string, name: string, unique: ProductDetailsData }) => {
  return (
    <div className='hidden md:block md:top-8 md:sticky bg-white-1'>
    <ScrollArea className=" border border-gray-300 w-full rounded-2xl p-6">
      <ul className="*:py-1">
      {productData && Object.entries(productData).map(([key, value], index) => {
          return (
            <li key={index}>
              <a href={`#${key}`} className="primary-link">
              👉 {value.header}
              </a>
            </li>
          );
        })}
        {unique && Object.entries(unique).map(([key, value], index) => {
          return (
            <li key={index}>
              <a href={`#${key}`} className="primary-link">
              👉 {value.header}
              </a>
            </li>
          );
        })}
      </ul>
    </ScrollArea>
    <div className='flex flex-col md:max-w-screen-2xl'>
        <GetStartedSidebar />
      </div>
    
  </div>
  );
};

const MobileDropdown: React.FC<MobileDropdownProps> = ({
  productData,
  unique,
}) => {
  return (
    <div className="md:hidden sticky top-0 backdrop-blur-sm py-2 flex mx-auto w-full bg-white-1 justify-between z-30">
      <Sheet>
        <SheetTrigger> <Button className='px-4 py-2 mx-4 primary-button2'>
            Table of contents
          </Button></SheetTrigger>
        <SheetContent side='bottom' className='bg-white-1'>
          <SheetHeader>
            <SheetTitle>Table of contents</SheetTitle>
            <SheetDescription>
            <div className="w-full p-4 text-start">
              <ul className="space-y-1">
                {productData &&
                  Object.entries(productData).map(([key, value], index) => {
                    return (
                      <li key={index}>
                        <a href={`#${key}`} className="primary-link">
                          👉 {value.header}
                        </a>
                      </li>
                    );
                  })}
                
              </ul>
            </div>
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
      <a href="/lp/get-proposals?source=top-nav" className='primary-button1'>
        Get advice
      </a>
    </div>
  );
};

const Blog: React.FC = () => {
  const [productData, setProductData] = useState<InsuranceProductResponse | null>(null);
  const [similar, setSimilar] = useState<SimilarItem[]>([]);
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const productDetailsData: ProductDetailsData | null = productData ? productData.blog : null;
  const uniqueData: ProductDetailsData | null = productData ? productData.unique : null;
  const adVariation = useAdVariation();

  const navigate = useNavigate();
  const { toast } = useToast()

  useEffect(() => {
    const storedToastNotification = localStorage.getItem('toastNotification');
    console.log('Stored Toast Notification:', storedToastNotification);
  
  
    if (storedToastNotification) {
      console.log("Inside toast notification block");
      const { title, description } = JSON.parse(storedToastNotification);
      console.log('Title:', title);
      console.log('Description:', description);
  
      // Delay the second toast notification by 1000 milliseconds (1 second)
      setTimeout(() => {
        toast({
          title: title,
          description: description,
        });
      }, 1000);
  
      // Clear the stored data after displaying the toast
      localStorage.removeItem('toastNotification');
    }
  }, []);


  const extractPath = () => {
    const basePath = '/insurance-products/';
    const fullPath = location.pathname;
    let path = fullPath.includes(basePath) ? fullPath.split(basePath)[1] : '';
    // Remove '-review' from the path if present
    path = path.replace('-review', '');
    return path;
  };

  const pathAfterInsuranceProducts = extractPath();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams();
        const productParam = pathAfterInsuranceProducts || slug;
  
        if (productParam) {
          params.append('product', productParam);
        }
  
        const response = await fetch(`/api/policies-new?${params}`);
  
        if (response.status === 301) {
          const data = await response.json();
          if (data.redirect) {
            localStorage.setItem('toastNotification', JSON.stringify({ title: `${data.old} is no longer available`, description: `${data.new} is the new product. We've taken you to the new page.` }));
            window.location.replace(data.redirect);
            return;
          }
        }
  
        if (!response.ok) {
          navigate('/');
          return;
        }
  
        const data: InsuranceProductResponse = await response.json();
  
        setProductData({
          airtable: data.airtable,
          blog: data.blog,
          similar: data.similar,
          unique: data.unique
        });

        posthog.capture(
          'intiial',
          {
            $set_once: { 
              initial_url: `/insurance-products/${productParam}`,
              ad_variation: adVariation
            }
          }
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [pathAfterInsuranceProducts, slug]);

  // New effect for PostHog capture
  useEffect(() => {
    if (productData && adVariation) {
      const productParam = pathAfterInsuranceProducts || slug;
      posthog.capture('initial', {
        $set_once: { 
          initial_url: `/insurance-products/${productParam}`,
          ad_variation: adVariation
        }
      });
    }
  }, [productData, adVariation, pathAfterInsuranceProducts, slug]);

  return (
    <div>
    <div className='bg-white-1'>
      <Helmet>
      <title>{productData?.airtable?.fields?.[`Title Tag (Final)`]}</title>
      <meta name="description" content={productData?.airtable?.fields?.[`Meta Description (Final)`]}/>
      <meta property="og:title" content={productData?.airtable?.fields?.[`Title Tag (Final)`]}/>
      <meta property="og:description" content={productData?.airtable?.fields?.[`Meta Description (Final)`]}/>
      <meta property="og:image" content="/images/whatins.png"/>
      <meta property="og:url" content="https://www.whatins.sg"/>
      <meta name="twitter:title" content={productData?.airtable?.fields?.[`Title Tag (Final)`]}/>
      <meta name="twitter:description" content={productData?.airtable?.fields?.[`Meta Description (Final)`]}/>
      <meta name="twitter:image" content="/images/whatins.png"/>
      <link rel="canonical" href={`https://www.whatins.sg${location.pathname}`}/>
    </Helmet>
      <div className="flex flex-col md:max-w-screen-2xl md:mx-auto">
        <div className=' md:max-w-screen-xl mx-auto '>
        <div className='w-full flex flex-col md:flex-row md:mt-8 gap-8 bg-white-1 p-4 md:p-4'>
          <div className='flex flex-col md:w-3/4'>
          <TypeTag type={productData?.airtable?.fields?.[`Insurance Type`][0]} isLink={true}/>
            <h1 className='primary-h2 py-4'>{productData?.airtable?.fields?.[`Name`]}</h1>
            <p>{productData?.airtable?.fields?.[`summary`]}
            </p>
          </div>
        </div>
        <div className='md:hidden bg-green-2 px-4'>
          <GetStartedSidebar />
          </div>
        <MobileDropdown productData={productDetailsData} unique={uniqueData}/>
        <div className="flex flex-col md:flex-row mt-8 gap-12">
        <div className="md:w-3/4">
          {productDetailsData && 
          <ProductDetails productData={productDetailsData} policy_id={productData?.airtable?.fields?.recordID} name={productData?.airtable?.fields?.[`Name`]} unique={uniqueData} />}
            <h2 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4 capitalize">Other {productData?.airtable?.fields?.[`Insurance Type Name`][0]} policies you may be interested in</h2>
            { productData?.airtable?.fields?.recordID && (
            <div className=' p-4'>
            <SimilarPolicies recordID={productData?.airtable?.fields?.recordID} />
          </div>
          )}
        </div>
        <div className="md:w-1/4 pb-12">
        <StickyRightBar  productData={productDetailsData} policy_id={productData?.airtable?.fields?.recordID} name={productData?.airtable?.fields?.[`Name`]} unique={uniqueData} />
        </div>
        </div>
      </div>
        <div id='Explore' className='bg-sav-blue-1 w-full'>
          { productData && (
        <MiniExplore
          policy_id={productData?.airtable.fields?.recordID}
          header={`Why people were suggested ${productData?.airtable?.fields?.[`Name`]}`}
          rationale = {true}
        />
      )}
      </div>
      </div>
    </div>
    <div className='flex flex-col mx-auto bg-green-2 px-4 md:px-0'><GetStartedSample/></div>

    </div>
  );
};

export {Blog}