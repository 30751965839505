import React, { useState, useRef, useEffect } from 'react';
import Image from '../components/image';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { MiniExplore, ExplorePolicies } from '../components/explore';

interface Ad {
  image: string;
  header: string;
  text: string;
  ctaText: string;
  ctaLink: string;
}

const ExplorePoliciesPage: React.FC = () => {
  const [expertQuestion, setExpertQuestion] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ads, setAds] = useState<Ad[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [blogResponse, adResponse] = await Promise.all([
          fetch('/api/get-brand'),
          fetch('/api/explore-ads')
        ]);
        
        const blogData = await blogResponse.json();
        const adData = await adResponse.json();
        
        setBlogPosts(blogData);
        setAds(adData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (blogPosts.length + ads.length));
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleExpertQuestionChange = (event) => {
    setExpertQuestion(event.target.value);
  };

  const handleAskExpert = () => {
    // Implement the logic to submit the question to an expert
    console.log("Question submitted:", expertQuestion);
    // Reset the question field
    setExpertQuestion('');
  };

  const BlogCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);
    if (blogPosts.length === 0) {
      return null;
    }

    const renderItem = (index: number) => {
      const totalItems = blogPosts.length + ads.length;
      const actualIndex = (currentIndex + index) % totalItems;
      const isAdPosition = actualIndex % 4 === 3; // Every 4th item (3rd, 7th, 11th, etc.) is an ad
  
      if (isAdPosition && ads.length > 0) {
        const adIndex = Math.floor(actualIndex / 4) % ads.length;
        const ad = ads[adIndex];
        return (
          <a href={ad.ctaLink} key={`ad-${actualIndex}`} className="flex flex-col h-full bg-white-2 shadow rounded p-4 relative hover:cursor-pointer hover:shadow-lg transition-shadow duration-300">
            <div className="absolute top-2 right-2 bg-white-1 text-gray-500 px-2 py-1 text-xs font-semibold rounded z-10">
              Ad
            </div>
            <Image src={ad.image} alt={ad.header} className="mb-2 mx-auto" dimension='square'/>
            <h3 className="font-serif font-bold text-2xl mb-2">{ad.header}</h3>
            <p className="flex-grow mb-4">{ad.text}</p>
            <div className="primary-button2 text-center w-full">
              {ad.ctaText}
            </div>
          </a>
        );
      } else {
        const blogIndex = actualIndex - Math.floor(actualIndex / 4);
        const post = blogPosts[blogIndex];
        return (
          <a href={post.ctaLink} key={`blog-${actualIndex}`} className="flex flex-col h-full bg-white-2 shadow rounded p-4 relative hover:cursor-pointer hover:shadow-lg transition-shadow duration-300">
            <div className="absolute top-2 right-2 bg-white-1 text-gray-500 px-2 py-1 text-xs font-semibold rounded z-10">
              Blog
            </div>
            {post.image && <Image src={post.image} alt={post.header} className="w-full mb-2" />}
            <h3 className="font-serif font-bold text-2xl mb-2">{post.header}</h3>
            <div className='flex justify-between'>
              <p className="text-gray-500 font-bold mb-2">Number of policies: </p>
              <p className="text-gray-500 font-bold mb-2">{post.policy_num}</p>
            </div>
            <p className="flex-grow mb-4">{post.text}</p>
            <div className="primary-button2 text-center w-full">
              {post.ctaText}
            </div>
          </a>
        );
      }
    };

    const navigateCarousel = (direction: 'prev' | 'next') => {
      const totalItems = blogPosts.length + ads.length;
      setCurrentIndex((prevIndex) => {
        if (direction === 'prev') {
          return prevIndex === 0 ? totalItems - 1 : prevIndex - 1;
        } else {
          return (prevIndex + 1) % totalItems;
        }
      });
    };
  
    const handleTouchStart = (e: React.TouchEvent) => {
      setTouchStart(e.targetTouches[0].clientX);
    };
  
    const handleTouchMove = (e: React.TouchEvent) => {
      setTouchEnd(e.targetTouches[0].clientX);
    };
  
    const handleTouchEnd = () => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > 50;
      const isRightSwipe = distance < -50;
  
      if (isLeftSwipe) {
        navigateCarousel('next');
      } else if (isRightSwipe) {
        navigateCarousel('prev');
      }
  
      setTouchStart(null);
      setTouchEnd(null);
    };

    return (
      <div className="relative" ref={carouselRef}>
        <div 
          className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {[0, 1, 2].map((index) => renderItem(index))}
        </div>
        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full"
          onClick={() => navigateCarousel('prev')}
        >
          <Image src='left-arrow.svg' className='w-4 md:w-8'/>
        </button>
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full"
          onClick={() => navigateCarousel('next')}
        >
          <Image src='right-arrow.svg' className='w-4 md:w-8'/>
        </button>
      </div>
    );
  };

  return (
    <div className='bg-white-1'>
      <div className='max-w-screen-xl mx-auto px-4 py-12'>

        {/* Policy Search Section */}
        <section className='mb-16'>
          <Card className='bg-green-2 md:p-6'>
            <CardContent>
              <ExplorePolicies />
            </CardContent>
          </Card>
        </section>

        {/* Blog Carousel Section */}
        {blogPosts.length > 0 && (
          <section className='mb-16'>
            <h2 className='primary-h2 text-green-1 mb-6'>Latest Insurance Insights</h2>
            <BlogCarousel />
          </section>
        )}

      </div>
    </div>
  );
};

export default ExplorePoliciesPage;