"use client"
import React, { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet'
import { useNavigate, Link } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod"
import Image  from "../components/image"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useToast } from "../components/ui/use-toast"
import Loading from "../components/GIF"
import posthog from 'posthog-js'



import { Button } from "../components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form"
import { Input } from "../components/ui/input"

const formSchema = z.object({
  name: z.string().min(4, {
    message: "Please enter your full name.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  })
})

export function PlainForm() {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
    },
  })
 
  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    const lowercaseEmail = values.email.toLowerCase();
  
    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, email: lowercaseEmail }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        toast({
          title: 'Error',
          description: errorData.message || 'An error occurred during registration.',
        });
      } else if (response.status === 200) {
        const userData = await response.json();

        posthog.capture('advisor_signed_up', {
          name: values.name,
          form: 'advisor_lp',
        });
        
        // Push event to dataLayer
        if (typeof window !== 'undefined' && window.dataLayer) {
          try {
            window.dataLayer.push({
              event: 'sign_up',
              userName: values.name,
              userEmail: lowercaseEmail
            });
          } catch (error) {
            console.error('Error pushing to dataLayer:', error);
            // You can add more detailed error reporting here if needed
          }
        } else {
          console.log("dataLayer not available");
        }
  
        // Navigate to the next page or show success message
        navigate('/advisor/profile', { state: { data: userData } });
        toast({
          title: 'Success',
          description: 'Registration successful. Welcome!',
        });
      }
    } catch (error) {
      console.error('Registration error:', error);
      toast({
        title: 'Error',
        description: 'An unexpected error occurred. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      {isLoading && <Loading src = "loading.mp4" />}
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={`grid grid-cols-1 md:grid-cols-3 gap-4`}
>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                  </svg>
                  </div>
                  <Input placeholder="Enter your name" {...field} className="pl-10 bg-white-2"/>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                      <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                      <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                    </svg>
                  </div>
                  <Input placeholder="Enter your email" {...field} className="pl-10 bg-white-2"/>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="primary-button1 text-green-1 px-4 rounded w-full">
            Sign up now →
          </Button>
        </form>
      </Form>
    
    </div>
  )
}

const Register = () => {

  return (
    <div className='bg-white-1'>
      <Helmet>
        <title>Whatins</title>
        <meta name="description" content="Get personalised advice from 5 insurance experts hassle-free all without sharing your phone number. Start your 5-minute questionnaire now!"/>
        <meta property="og:title" content="Whatins"/>
        <meta property="og:description" content="Get personalised advice from 5 insurance experts hassle-free all without sharing your phone number. Start your 5-minute questionnaire now!"/>
        <meta property="og:image" content="/images/whatins.png"/>
        <meta property="og:url" content="https://www.whatins.sg"/>
      </Helmet>
      <div className='flex flex-col max-w-screen-2xl mx-auto px-4 md:px-0'>
        <div className='flex flex-col max-w-4xl mx-auto py-8 gap-8'>
        <h1 className='primary-h1 text-center'>Struggling with consistent prospect flow?</h1>
        <p className='text-center'>We'll handle getting you quality prospects so you can focus solely on closing deals. Save time, expand your reach, and boost your success rate with our services.</p>
        <PlainForm/>
        <div className='flex flex-col content-center basis-1/2'>
            <div className='flex flex-row gap-6 py-2'>
            <Image src="advisor-img-1.png" className='w-8 h-8 flex-none'/>
              <div className='flex flex-col'>
                <h3 className='text-lg font-semibold'>Get pre-qualified prospects</h3>
                <p className='text-gray-500'>Each prospect goes through 3 stages of qualification checks to ensure they are real interests </p>
              </div>
            </div>
            <div className='flex flex-row gap-6 py-2'>
            <Image src="advisor-img-2.png" className='w-8 h-8 flex-none'/>
              <div className='flex flex-col'>
                <h3 className='text-lg font-semibold'>Prospects are not incentivised</h3>
                <p className='text-gray-500'>Leads signs up voluntarily, making them more viable prospects.</p>
              </div>
            </div>
            <div className='flex flex-row gap-6 py-2'>
              <Image src="advisor-img-3.png" className='w-8 h-8 flex-none'/>
              <div className='flex flex-col'>
                <h3 className='text-lg font-semibold'>Your Own CRM</h3>
                <p className='text-gray-500'>Manage your prospects easily with a built in CRM so you have a high level overview  of each prospect.</p>
              </div>
            </div>
            <div className='flex flex-row gap-6 py-2'>
              <Image src="advisor-img-4.png" className='w-8 h-8 flex-none'/>
              <div className='flex flex-col'>
                <h3 className='text-lg font-semibold'>Get assigned prospects on a regular basis</h3>
                <p className='text-gray-500'>Consistency is our main goal, we strive to ensure that you are assigned at least 1 fresh prospect every 2 weeks.</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className='flex flex-col max-2-screen-2xl mx-auto px-4 py-4 md:px-0 bg-green-1'>
        <div id='forAdvisors' className=' hiddens flex flex-col max-w-screen-lg mx-auto py-3 gap-12'>
            <div className='flex flex-col md:flex-row gap-16'>
              <div className='flex flex-col md:basis-1/3 items-start'>
              <Image src="whatins-advisor-1.svg" alt="5" imgClass='aspect-square' className='aspect-square'/>
              <p className='primary-h3 text-h4'>Quality Prospects</p>
              <p className='text-white-1'>The prospects are triple vetted before they are accepted to ensure that they are serious about exploring their insurance options.</p>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
              <Image src="whatins-advisor-2.svg" alt="5" imgClass='items-center aspect-square' className='aspect-square'/>
              <p className='primary-h3 text-h4'>Easy communication channels</p>
              <p className='text-white-1'>Although you will not be given their contact until after submitting your suggestions, we have a telegram bot that will allow you to continue to engage with your prospect.</p>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
              <Image src="whatins-advisor-3.svg" alt="5" imgClass='items-center aspect-square' className='aspect-square'/>
              <p className='primary-h3 text-h4'>Easy to start</p>
              <p className='text-white-1'>Test out this platform at your own pace. Costs less than $50 to start.</p>
              </div>
            </div>
          <a href="/advisor-register" className="primary-button1 text-center mx-auto">Help members with their insurance needs →</a>

          </div>
        </div>
      <div className='bg-white-1'>
        <div className='flex flex-col max-w-screen-md mx-auto px-4 py-8 gap-4 text-center'>
        <h2 className='text-h4 font-semibold'>Join whatins today!</h2>
        <p className='text-lg text-gray-500'>We'll handle getting you quality prospects so you can focus solely on closing deals. Save time, expand your reach, and boost your success rate with our services.</p>
        <PlainForm/>
        </div>
      </div>
      
      {/* <div className='flex flex-col bg-gray-200'>
        <div className='flex flex-col max-w-screen-2xl mx-auto px-4 md:px-0 py-8 gap-4'>
          <div className='max-w-2xl mx-auto flex flex-col'>
          <h2 className='text-2xl text-center font-semibold'>Lead generation redefined</h2>
          <p className='text-gray-500 text-center'>We go beyond typical lead generation by asking meaningful questions like future aspirations to show prospects we care, helping you win their business.</p>
          </div>
        </div>
      </div> */}
      <div className='flex flex-col'>
        <div className='flex flex-col md:flex-row gap-8 max-w-screen-2xl mx-auto px-4 py-8'>
          <div className='object-scale-down h-1/2 flex-none basis-1/2'>
          <Image src="advisor_lp.png" alt="what insurance is for you?"/>
          </div>
          <div className='flex flex-col justify-center basis-1/2 gap-8'>
            <h2 className='primary-h2 mb-0'>How it works</h2>
            <div className='gap-2 py-2'>
              <div className='flex flex-row gap-6 py-2'>
            <Image src="check-circle.svg" className='w-8 h-8 flex-none pt-2'/>
              <div className='flex flex-col'>
                <h3 className='text-h5 text-green-1'>Step 1: Assignment of Prospects</h3>
                <p className='text-gray-500'>Upon sign-up, prospects undergo verification via Telegram/email to confirm their identity. Our team conducts a thorough background check to ensure authenticity and weed out any fake prospects or potential advisors posing as prospects. This rigorous pre-qualification process guarantees high-quality leads for you. Once this step is done, these prospects is then assigned to you. </p>

                <p className='text-gray-500'>Good to know: These individuals are genuine and not incentivised.</p>
              </div>
            </div>
            <div className='flex flex-row gap-6 py-2'>
            <Image src="check-circle.svg" className='w-8 h-8 flex-none'/>
              <div className='flex flex-col'>
              <h3 className='text-h5 text-green-1'>Step 2: Curate Proposal</h3>
                <p className='text-gray-500'>Learn about your prospect needs and create a personalized proposal for them. This is a vital step in ensuring you stand out from the rest of the advisors! <br/><br/>Good to know: each prospects are assigned 5 advisors which allows them to choose the advisor they feel most comfortable to work with. </p>
              </div>
            </div>
            <div className='flex flex-row gap-6 py-2'>
              <Image src="check-circle.svg" className='w-8 h-8 flex-none'/>
              <div className='flex flex-col'>
              <h3 className='text-h5 text-green-1'>Step 3: Connect with Your Prospects</h3>
                <p className='text-gray-500'>Have questions about your prospects? Reach out to them via our messaging platform, prospects will also be able to reach out to you if they have questions. If they like what you have proposed then its time to close. </p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white-1'>
        <div className='flex flex-col max-w-screen-md mx-auto px-4 py-8 gap-4 text-center'>
        <h2 className='text-2xl font-semibold'>Join whatins today!</h2>
        <p className='text-lg text-gray-500'>We'll handle getting you quality prospects so you can focus solely on closing deals. Save time, expand your reach, and boost your success rate with our services.</p>
        <PlainForm/>
        </div>
      </div>
    </div>
  );
}


export default Register;
