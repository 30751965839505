import React, { ReactNode } from 'react';
import { GetStartedSidebar, useAdVariation, GetStartedSample, GetStartedTestimonial } from '../components/CTA'
import { ScrollArea, ScrollBar } from '../components/ui/scroll-area'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '../components/ui/sheet';
import { Button } from '../components/ui/button';

// {
//   "header": "h1/h2/h3/h4",
//   "header_text": "string",
//   "paragraph": "string with <b> and <a> tags",
//   "grid": [
//     {
//       "header": "string",
//       "header_text": "string",
//       "paragraph": "string",
//       "image": {
//         "src": "string",
//         "alt": "string",
//         "img_url": "string"
//       },
//       "cta_url": "string",
//       "cta_text": "string"
//     }
//   ],
//   "list": {
//     "bullet": "bullet/none",
//     "items": [
//       {
//         "header": "string",
//         "header_text": "string",
//         "paragraph": "string",
//         "list": [
//           "string"
//         ]
//       }
//     ]
//   },
//   "image": {
//     "src": "string",
//     "alt": "string",
//     "img_url": "string"
//   },
//   "video": {
//     "embed_url": "string",
//     "title": "string"
//   }
// }

interface HeaderTypes {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
}

const getHeaderClass = (header: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined): string => {
  switch (header) {
    case 'h1': return 'primary-h2 py-4';
    case 'h2': return 'primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4';
    case 'h3': return 'primary-h3 text-green-1';
    case 'h4': return 'text-h5 text-green-1 font-bold text-pretty pb-4 capitalize';
    case 'h5': return 'text-h5';
    case 'h6': return 'text-h6';
    default: return 'text-h4';
  }
};


interface BlogSection {
  header?: 'h1' | 'h2' | 'h3';
  header_text?: string;
  paragraph?: string;
  grid?: GridItem[];
  list_simple?: SimpleList;
  list_nested?: NestedList;
  image?: ImageItem;
  video?: VideoItem;
}

interface GridItem {
  header?: string;
  header_text?: string;
  paragraph?: string;
  image?: ImageItem;
  cta_url?: string;
  cta_text?: string;
  cta_type?: 'button' | 'whole';
}

interface SimpleList {
  bullet: 'bullet' | 'none' | 'num';
  items: string[];
}

interface NestedListItem {
  header?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  header_text?: string;
  paragraph?: string;
  list_simple?: SimpleList;
}

interface NestedList {
  bullet: 'bullet' | 'none' | 'num';
  items: NestedListItem[];
}

interface SectionList {
  header?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  header_text?: string;
  bullet?: 'bullet' | 'none' | 'num';
  list?: SimpleList;
  list_nested?: NestedList;
  items: string[];

}


interface ImageItem {
  src: string;
  alt: string;
  img_url: string;
}

interface VideoItem {
  embed_url: string;
  title: string;
}

interface BlogSectionComponentProps {
  section: BlogSection;
  id: string;
}

const formatParagraph = (text: string): ReactNode => {
  const parts = text.split(/(<b>.*?<\/b>|<a.*?<\/a>|<br\s*\/?>)/);
  return parts.map((part, index) => {
    if (part.startsWith('<b>') && part.endsWith('</b>')) {
      return <strong key={index} className="text-green-1">{part.slice(3, -4)}</strong>;
    } else if (part.startsWith('<a') && part.endsWith('</a>')) {
      const hrefMatch = part.match(/href=(['"])(.*?)\1/);
      const href = hrefMatch ? hrefMatch[2] : undefined;
      const content = part.replace(/<\/?a.*?>/g, '');
      return <a key={index} href={href} className="primary-link">{content}</a>;
    } else if (part.match(/<br\s*\/?>/)) {
      return <br key={index} />;
    }
    return part;
  });
};

const BlogSectionComponent: React.FC<BlogSectionComponentProps> = ({ section, id }) => {
  const formatAnchorLink = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, '-');
  };

  const isValidHeader = (header: string | undefined): header is 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' => {
    return header === 'h1' || header === 'h2' || header === 'h3' || header === 'h4' || header === 'h5' || header === 'h6';
  };
  

  const getListStyle = (bulletType?: 'bullet' | 'none' | 'num'): string => {
    switch (bulletType) {
      case 'none': return 'list-none';
      case 'num': return 'list-decimal pl-6 ';
      default: return 'list-disc pl-6 ';
    }
  };

  const anchorId = section.header_text ? formatAnchorLink(section.header_text) : id;

  const renderHeader = () => {
    if (section.header && section.header_text) {
      return React.createElement(
        section.header,
        {
          className: `${getHeaderClass(section.header)} font-bold tracking-tight leading-none text-pretty pb-4`
        },
        section.header_text
      );
    }
    return null;
  };

  const renderParagraph = ({ paragraph }: { paragraph: string }) => {
    if (paragraph) {
      return <p className="pb-6">{formatParagraph(paragraph)}</p>;
    }
    return null;
  };

  const renderGrid = () => {
    if (section.grid) {
      return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          {section.grid.map((item, index) => {
            const headerType = (item.header as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined);
            
            const gridContent = (
              <>
                {item.header && item.header_text && React.createElement(
                  item.header,
                  {
                    className: `${getHeaderClass(headerType)} text-green-1 font-bold text-pretty pb-4 capitalize`
                  },
                  item.header_text
                )}
                {item.image && <img src={item.image.src} alt={item.image.alt} className="w-full h-auto rounded-md" />}
                {item.paragraph && renderParagraph({ paragraph: item.paragraph })}
                {item.cta_url && item.cta_text && (
                  <a 
                    href={item.cta_url} 
                    className={`primary-button1 mt-4 ${item.cta_type === 'button' ? 'inline-block' : ''}`}
                  >
                    {item.cta_text}
                  </a>
                )}
              </>
            );
  
            const gridItem = item.cta_type === 'whole' ? (
              <a href={item.cta_url} className="block rounded-2xl md:border-2 border-gray-200 p-6 hover:shadow-lg transition-shadow duration-300">
                {gridContent}
              </a>
            ) : (
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                {gridContent}
              </div>
            );
  
            return (
              <div key={index}>
                {gridItem}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  const renderSimpleList = (list: SimpleList) => {
    return (
      <ul className={`${getListStyle(list.bullet)}`}>
        {list.items.map((item: string, index) => (
          <li key={index} className="mb-2">{formatParagraph(item)}</li>
        ))}
      </ul>
    );
  };

  const renderNestedList = (list: NestedList) => {
    return (
      <ul className={`${getListStyle(list.bullet)}`}>
        {list.items.map((item: NestedListItem, index) => (
          <li key={index} className="mb-6">
            {item.header && item.header_text && (
              React.createElement(
                item.header,
                { 
                  className: `${getHeaderClass(item.header)} pb-2` 
                },
                formatParagraph(item.header_text)
              )
            )}
            {item.paragraph && <p className="mb-2">{formatParagraph(item.paragraph)}</p>}
            {item.list_simple && renderSimpleList(item.list_simple)}
          </li>
        ))}
      </ul>
    );
  };


  const renderImage = () => {
    if (section.image) {
      return (
        <div className="pb-6">
          <img src={section.image.src} alt={section.image.alt} className="w-full h-auto rounded-md" />
        </div>
      );
    }
    return null;
  };

  const renderVideo = () => {
    if (section.video) {
      return (
        <div className="pb-6">
          <iframe
            width="100%"
            height="315"
            src={section.video.embed_url}
            title={section.video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    return null;
  };

  return (
    <div id={anchorId} className="blog-section py-6">
      {renderHeader()}
      {section.paragraph && renderParagraph({ paragraph: section.paragraph })}
      {renderGrid()}
      {section.list_simple && renderSimpleList(section.list_simple)}
      {section.list_nested && renderNestedList(section.list_nested)}
      {renderImage()}
      {renderVideo()}
    </div>
  );
};

export default BlogSectionComponent;

const TableOfContents: React.FC<{ content: BlogSection[] }> = ({ content }) => {
  const formatAnchorLink = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <div className="hidden md:block md:top-8 md:sticky bg-white-1">
      <ScrollArea className="border border-gray-300 w-full rounded-2xl p-6 h-72" type='always'>
        
        <ul className="*:py-1">
          {content.map((section, index) => {
            if (section.header_text) {
              const anchorLink = formatAnchorLink(section.header_text);
              return (
                <li key={index}>
                  <a href={`#${anchorLink}`} className="primary-link">
                    👉 {section.header_text}
                  </a>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </ScrollArea>
      <GetStartedSidebar />
    </div>
  );
};

const MobileDropdown: React.FC<{ content: BlogSection[] }> = ({ content }) => {
  const formatAnchorLink = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <div className="md:hidden sticky top-0 backdrop-blur-sm py-2 flex mx-auto w-full bg-white-1 justify-between z-100">
      <Sheet>
        <SheetTrigger>
          <Button className='px-4 py-2 mx-4 primary-button2'>
            Table of contents
          </Button>
        </SheetTrigger>
        <SheetContent side='bottom' className='bg-white-1'>
          <SheetHeader>
            <SheetTitle>Table of contents</SheetTitle>
            <SheetDescription>
              <div className="w-full p-4 text-start">
                <ul className="space-y-1">
                  {content.map((section, index) => {
                    if (section.header_text) {
                      const anchorLink = formatAnchorLink(section.header_text);
                      return (
                        <li key={index}>
                          <a href={`#${anchorLink}`} className="primary-link">
                            👉 {section.header_text}
                          </a>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
      <a href="/lp/get-proposals?source=top-nav" className='primary-button1 mx-4'>
        Get advice
      </a>
    </div>
  );
};

interface BlogContentProps {
  content: any[];
  start?: number;
  end?: number;
}

const BlogContent: React.FC<BlogContentProps> = ({ content, start = 0, end = 0 }) => {
  if (!content || !Array.isArray(content) || content.length === 0) {
    return null;
  }

  const slicedContent = end > 0 ? content.slice(start, end) : content.slice(start);

  return (
    <>
      {slicedContent.map((section, index) => (
        <BlogSectionComponent 
          key={start + index} 
          section={section} 
          id={`section-${start + index}`} 
        />
      ))}
    </>
  );
};

interface BlogPostProps {
  content: BlogSection[];
  children?: React.ReactNode;
}

const BlogPost: React.FC<BlogPostProps> = ({ content, children }) => {
  return (
    <div className="flex flex-col md:max-w-screen-2xl md:mx-auto relative">
      <MobileDropdown content={content} />
      <div className="md:max-w-screen-xl mx-auto">
        <div className="w-full flex flex-col md:flex-row md:mt-8 gap-8 bg-white-1 p-4 md:p-4">
          <div className="flex flex-col md:w-3/4">
            {children}
          </div>
          <div className="md:w-1/4 pb-12">
            <TableOfContents content={content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { BlogPost, BlogContent };