import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { BlogPost, BlogContent } from '../components/BlogJson';
import { GetStartedSidebar, useAdVariation, GetStartedSample, GetStartedTestimonial } from '../components/CTA'
import { MiniExplore, PremiumSample } from '../components/explore';
import TypeTag from '../components/TypeTag';
import { Button } from "../components/ui/button"

interface SimilarPolicy {
  name: string;
  internalDescription: string;
  slug: string;
}

interface SimilarPoliciesProps {
  recordID: string;
}

export const SimilarPolicies: React.FC<SimilarPoliciesProps> = ({ recordID }) => {
  const [similarPolicies, setSimilarPolicies] = useState<SimilarPolicy[]>([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchSimilarPolicies = async () => {
      try {
        const response = await fetch('/api/similar-policies', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ recordID }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch similar policies');
        }

        const data = await response.json();
        setSimilarPolicies(data);
      } catch (error) {
        console.error('Error fetching similar policies:', error);
      }
    };

    fetchSimilarPolicies();
  }, [recordID]);
  const visiblePolicies = showAll ? similarPolicies : similarPolicies.slice(0, 3);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-8">
        {similarPolicies.map((policy, index) => (
          <div
            key={index}
            className={`bg-white rounded-lg shadow-md p-6 ${index >= 3 && !showAll ? 'hidden' : ''}`}
          >
            <h3 className="text-xl font-semibold mb-2">{policy.name}</h3>
            <p className="text-gray-600 mb-4 flex-grow">{policy.internalDescription}</p>
            <a href={`/insurance-products/${policy.slug}`}>
              <Button className="primary-button1 w-full">
                Learn More
              </Button>
            </a>
          </div>
        ))}
      </div>
      {similarPolicies.length > 3 && (
        <div className="text-center mt-4">
          <Button
            onClick={() => setShowAll(!showAll)}
            className="primary-button2"
          >
            {showAll ? 'Show Less' : `View all ${similarPolicies.length} policies`}
          </Button>
        </div>
      )}
    </div>
  );
};

interface BlogData {
  fields: {
    'Blog Title': string;
    'Blog Meta Description': string;
  };
  content: any[]; // Replace 'any' with a more specific type if available
  id: string;
  name: string;
  type: string;
  type_name: string;
  summary: string;
  unique: any[];
}

export const TypeBlog: React.FC = () => {
  const [blogData, setBlogData] = useState<BlogData | null>(null);
  const params = useParams<{ '*': string }>();
  const location = useLocation();

  useEffect(() => {
    const slug = params['*'] || '';

    const fetchBlogContent = async () => {
      console.log("Fetching blog content for slug:", slug);
      try {
        const response = await fetch('/api/blog', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ slug: slug }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch blog content');
        }
        const data: BlogData = await response.json();
        console.log("Received blog content:", data);
        setBlogData(data);
      } catch (error) {
        console.error('Error fetching blog content:', error);
      }
    };

    if (slug) {
      fetchBlogContent();
    }
  }, [params]);

  if (!blogData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white-1">
      <Helmet>
        <title>{blogData.fields['Blog Title']}</title>
        <meta name="description" content={blogData.fields['Blog Meta Description']} />
        <link rel="canonical" href={`https://www.whatins.sg${location.pathname}`} />
      </Helmet>
      <div className="App">
        <BlogPost content={blogData.content}>
          <BlogContent content={blogData.content} start={0} end={2} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={1} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={2} end={4} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={2} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={4} end={7} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={3} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={7} end={blogData.content.length} />
        </BlogPost>
        <div className='flex flex-col mx-auto bg-green-2 px-4 md:px-0'><GetStartedSample /></div>

      </div>
    </div>
  );
};

export const BrandBlog: React.FC = () => {
  const [blogData, setBlogData] = useState<BlogData | null>(null);
  const params = useParams<{ '*': string }>();
  const location = useLocation();

  useEffect(() => {
    const slug = params['*'] || '';

    const fetchBlogContent = async () => {
      console.log("Fetching blog content for slug:", slug);
      try {
        const response = await fetch('/api/brand', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ slug: slug }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch blog content');
        }
        const data: BlogData = await response.json();
        console.log("Received blog content:", data);
        setBlogData(data);
      } catch (error) {
        console.error('Error fetching blog content:', error);
      }
    };

    if (slug) {
      fetchBlogContent();
    }
  }, [params]);

  if (!blogData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white-1">
      <Helmet>
        <title>{blogData.fields['Blog Title']}</title>
        <meta name="description" content={blogData.fields['Blog Meta Description']} />
        <link rel="canonical" href={`https://www.whatins.sg${location.pathname}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="App">
        <BlogPost content={blogData.content}>
          <BlogContent content={blogData.content} start={0} end={2} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={1} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={2} end={4} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={2} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={4} end={7} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={3} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={7} end={blogData.content.length} />
        </BlogPost>
        <div className='flex flex-col mx-auto bg-green-2 px-4 md:px-0'><GetStartedSample /></div>

      </div>
    </div>
  );
};

export const PolicyBlog: React.FC = () => {
  const [blogData, setBlogData] = useState<BlogData | null>(null);
  const params = useParams<{ '*': string }>();
  const location = useLocation();

  useEffect(() => {
    const slug = params['*'] || '';

    const fetchBlogContent = async () => {
      console.log("Fetching blog content for slug:", slug);
      try {
        const response = await fetch('/api/blog-policy', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ slug: slug }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch blog content');
        }
        const data: BlogData = await response.json();
        setBlogData(data);
      } catch (error) {
        console.error('Error fetching blog content:', error);
      }
    };

    if (slug) {
      fetchBlogContent();
    }
  }, [params]);

  if (!blogData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white-1">
      <Helmet>
        <title>{blogData.fields['Blog Title']}</title>
        <meta name="description" content={blogData.fields['Blog Meta Description']} />
        <link rel="canonical" href={`https://www.whatins.sg${location.pathname}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={blogData.fields['Blog Title']} />
        <meta property="og:description" content={blogData.fields['Blog Meta Description']} />
        <meta property="og:image" content="/images/whatins.png" />
        <meta property="og:url" content="https://www.whatins.sg" />
        <meta name="twitter:title" content={blogData.fields['Blog Title']} />
        <meta name="twitter:description" content={blogData.fields['Blog Meta Description']} />
        <meta name="twitter:image" content="/images/whatins.png" />
      </Helmet>
      <div className="App">
        <BlogPost content={blogData.content}>
          <TypeTag type={blogData.type} isLink={true} />
          <BlogContent content={blogData.content} start={0} end={2} />
          {blogData?.id && (
            <div className=' py-4'>
              <PremiumSample
                policy_id={blogData.id}
                header={`Real premiums suggested for ${blogData.name}`}
              />
            </div>
          )}
          <BlogContent content={blogData.content} start={2} end={4} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={2} source={location.pathname} />
          </div>
          <BlogContent content={blogData.unique} />
          <BlogContent content={blogData.content} start={4} end={7} />
          <div className="bg-green-2">
            <GetStartedTestimonial testimonial={3} source={location.pathname} />
          </div>
          <BlogContent content={blogData.content} start={7} end={blogData.content.length} />
          <h2 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4 capitalize pt-16">Other {blogData.type_name} policies you may be interested in</h2>
          {blogData?.id && (
            <div className=' p-4'>
              <SimilarPolicies recordID={blogData?.id} />
            </div>
          )}
          <MiniExplore
            policy_id={blogData.id}
            header={`Why people were suggested ${blogData.name}`}
            rationale={true}
          />
        </BlogPost>
        <div className='flex flex-col mx-auto bg-green-2 px-4 md:px-0'><GetStartedSample /></div>

      </div>
    </div>
  );
};