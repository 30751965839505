import React from 'react';

const getMatchedType = (inputType) => {
  const typeList = [
    { input: ['isp', 'hospital', 'Medical Condition', 'recpdtMoJo1cA8FH8'], output: 'Hospital', color: '#FF4136', typeId: 'recpdtMoJo1cA8FH8', url:'/life-insurance/best-hospital-(isp)-insurance-review-in-singapore' },
    { input: ['pa', 'personal accident', 'recmDVlhrGH7Pa0b9', 'personal'], output: 'Personal Accident', color: '#FF851B', typeId: 'recmDVlhrGH7Pa0b9', url:'/life-insurance/best-personal-accident-insurance-review-in-singapore' },
    { input: ['disability', 'tpd', 'total permanent disability'], output: 'Disability', color: '#FFDC00', typeId: 'recjs035wuJLCIfoX', url:'/life-insurance/best-disability-insurance-review-in-singapore' },
    { input: ['whole', 'whole life', 'recY8Oy3y6KEMQytx'], output: 'Whole Life', color: '#2ECC40', typeId: 'recY8Oy3y6KEMQytx', url:'/life-insurance/best-whole-life-insurance-review-in-singapore' },
    { input: ['term', 'term life', 'recpnPXgTA9KwVmcI'], output: 'Term Life', color: '#0074D9', typeId: 'recpnPXgTA9KwVmcI', url:'/life-insurance/best-term-life-insurance-review-in-singapore' },
    { input: ['ilp', 'investment', 'linked', 'plan', 'recldvICdozX8cFCp', 'investment linked plan'], output: 'Investment Linked Plan', color: '#B10DC9', typeId: 'recldvICdozX8cFCp', url:'/life-insurance/best-investment-linked-plan-(ilp)-review-in-singapore' },
    { input: ['endowment', 'saving', 'rec6MQaW9daZ619Bs'], output: 'Endowment', color: '#F012BE', typeId: 'rec6MQaW9daZ619Bs', url:'/life-insurance/best-endowment-insurance-review-in-singapore' },
    { input: ['ci', 'critical illness', 'recfuZEbbuVfjNTWQ', 'critical'], output: 'Critical Illness', color: '#001f3f', typeId: 'recfuZEbbuVfjNTWQ', url:'/life-insurance/best-critical-illness-insurance-review-in-singapore' },
    { input: ['maternity', 'maternity insurance', 'recvbonxHiXQecX3N'], output: 'Maternity', color: '#001f3f', typeId: 'recvbonxHiXQecX3N', url:'/life-insurance/best-maternity-insurance-review-in-singapore' },
  ];

  if (typeof inputType !== 'string') {
    return null;
  }

  return typeList.find(
    (item) => item.input.some((word) => word.toLowerCase() === inputType.toLowerCase())
  );
};

const TypeTag = ({ type, className = 'text-gray-500 text-sm', isLink = false }) => {
  const matchedType = getMatchedType(type);

  if (!matchedType) {
    return null;
  }

  const { output, color, typeId } = matchedType;

  return (
    <span className={`inline-flex items-center rounded-full px-2 py-0.5 ${className}`} data-type-id={typeId}>
      <span className="inline-block w-2 h-2 rounded-full mr-1" style={{ backgroundColor: color }}></span>
      {isLink ? <a href={matchedType.url} className='hover:underline'>{output}</a> : output}
    </span>
  );
};

export default TypeTag;
export {getMatchedType}