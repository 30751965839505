import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Image from '../components/image';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import Select, { components } from 'react-select';

import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Slider } from '../components/ui/slider';
import { useToast } from '../components/ui/use-toast';
import { PremiumsOnUsFAQ } from './lp-get-proposals';
import { BlockNumberFAQ } from '../components/advisor-spam';

import {
 Form,
 FormControl,
 FormDescription,
 FormField,
 FormItem,
 FormLabel,
 FormMessage,
} from '../components/ui/form';

const formSchema = z.object({
 alias: z.string().min(2, {
  message: 'Username must be at least 2 characters.',
 }),
 email: z.string().email({
  message: 'Please enter a valid email address.',
 }),
});

export function ProfileForm() {
 // 1. Define your form.
 const form = useForm<z.infer<typeof formSchema>>({
  resolver: zodResolver(formSchema),
  defaultValues: {
   alias: '',
   email: '',
  },
 });

 // 2. Define a submit handler.
 function onSubmit(values: z.infer<typeof formSchema>) {
  // Do something with the form values.
  // ✅ This will be type-safe and validated.
 }

 return (
  <Form {...form}>
   <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
    <div className="w-full grid gird-cols-1 md:grid-cols-2 md:space-x-8">
     <FormField
      control={form.control}
      name="alias"
      render={({ field }) => (
       <FormItem>
        <FormLabel>Alias</FormLabel>
        <FormControl>
         <Input placeholder="Xiao Ming" {...field} />
        </FormControl>
        <FormMessage />
       </FormItem>
      )}
     />
     <FormField
      control={form.control}
      name="email"
      render={({ field }) => (
       <FormItem>
        <FormLabel>Email</FormLabel>
        <FormControl>
         <Input placeholder="Your Best Email" {...field} />
        </FormControl>
        <FormMessage />
       </FormItem>
      )}
     />
    </div>
    <Button
     type="submit"
     className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
    >
     GIVE ME ADVICE
    </Button>
   </form>
  </Form>
 );
}

interface FormData {
 // Page 1
 day: string;
 month: string;
 year: string;
 gender: string;
 singaporean: string;
 pass: string;
 reside: string;
 occupation: string;
 income: string;

 // Page 2
 relationship: string;
 currentKids: string;
 moreKids: string;
 dependants: string;
 property: string;
 aspirations: string;

 // Page 3
 smoker: string;
 explain: string;
 budget: string;
 insurance_status: string;
 insuranceTypes: string[];

 // Page 4
 hasInsurance: string;
 inputMethod: string;
 customPolicy: string; // Add this line
 coverage: {
  [key: string]: {
   has: boolean;
   inputMethod?: 'self' | 'guide';
   policy?: { value: string; label: string } | null;
   customPolicy?: string;
   brand?: { value: string; label: string } | null;
   coverageAmount?: string;
   premium?: string;
  };
 };
 insurance_expectation: string;
 expectation_details: string;
 number: string;

 // Hidden fields (if needed)
 userID?: string;
 page?: string;
}

type FormDataState = {
 day: string;
 month: string;
 year: string;
 gender: string;
 singaporean: string;
 pass: string;
 reside: string;
 occupation: string;
 income: string;
 relationship: string;
 currentKids: string;
 moreKids: string;
 dependants: string;
 property: string;
 aspirations: string;
 smoker: string;
 explain: string;
 budget: string;
 insurance_status: string;
 insuranceTypes: string[];
 hasInsurance: string;
 inputMethod: string;
 customPolicy: string;
 coverage: {
  'life insurance': {
   has: boolean;
   policy: any;
   customPolicy: string;
   brand: any;
   coverageAmount: string;
   premium: string;
  };
  'integrated shield plan': {
   has: boolean;
   policy: any;
   customPolicy: string;
   brand: any;
   coverageAmount: string;
   premium: string;
  };
  'personal accident': {
   has: boolean;
   policy: any;
   customPolicy: string;
   brand: any;
   coverageAmount: string;
   premium: string;
  };
  'insurance for saving or investing': {
   has: boolean;
   policy: any;
   customPolicy: string;
   brand: any;
   coverageAmount: string;
   premium: string;
  };
  'any other type of insurance': {
   has: boolean;
   policy: any;
   customPolicy: string;
   brand: any;
   coverageAmount: string;
   premium: string;
  };
 };
 insurance_expectation: string;
 expectation_details: string;
 number: string;
};

interface CoverageDetails {
 has: boolean;
 policy: { value: string; label: string } | null;
 customPolicy: string;
 brand: { value: string; label: string } | null;
 coverageAmount: string;
 premium: string;
}

interface OptionProps {
 htmlFor: string;
 path: string;
 text: string;
}

interface TypeItem {
 id: string;
 name: string;
 image: string;
 slug: string;
}

interface ProductItem {
 id: string;
 fields: {
  Name: string;
  'Insurance Company': string;
  'Insurance Type': string;
 };
}

interface ProductOption {
 value: string;
 label: string;
 product: {
  fields: {
   Name: string;
   // Add other necessary properties from the product object
  };
 };
}

interface Product {
 fields: {
  Name: string;
  // Add other necessary properties from the product object
 };
}

interface InsuranceTypes {
 [key: string]: Product[];
}

const progressSteps = [
 { label: 'About You' },
 { label: 'Life Stage' },
 { label: 'Health & Needs' },
 { label: 'Entry to premiums on us 💰' },
];

const ProgressBar = ({ currentPage }: { currentPage: number }) => {
 return (
  <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
   {progressSteps.map((step, index) => (
    <li
     key={index}
     className={`flex md:w-full items-center ${
      index < currentPage ? 'text-green-2 dark:text-green-2' : ''
     }`}
    >
     <span className="flex items-center">
      {index < currentPage - 1 && (
       <svg
        className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
       >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
       </svg>
      )}
      {step.label}
     </span>
     {index < progressSteps.length - 1 && (
      <span
       className={`inline-block mx-2 sm:mx-6 xl:mx-10 ${
        index < currentPage - 1
         ? 'text-green-2 dark:text-green-2'
         : 'text-gray-500 dark:text-gray-500'
       }`}
      >
       /
      </span>
     )}
    </li>
   ))}
  </ol>
 );
};

const Questionnaire = () => {
 const location = useLocation();
 const locationData = location.state?.data;
 const [page, setPage] = useState(locationData?.page);
 const [userId, setUserId] = useState(locationData?.id || '');
 const [isSingaporean, setIsSingaporean] = useState<boolean | null>(null);
 const [isMedical, setIsMedical] = useState<boolean | null>(null);
 const [haveInsurance, setHaveInsurance] = useState<boolean | null>(null);
 const [property, setProperty] = useState<boolean | null>(null);
 const [types, setTypes] = useState<TypeItem[]>([]);
 const [products, setProducts] = useState({});
 const [selections, setSelections] = useState([
  { productId: '', productName: '', coverage: '', premium: '' },
 ]);
 const [hasInsurance, setHasInsurance] = useState(false);
 const [insuranceTypes, setInsuranceTypes] = useState<InsuranceTypes>({});
 const [coverage, setCoverage] = useState<{ [key: string]: any }>({});
 const [brands, setBrands] = useState<string[]>([]);
 const [selectedExpectation, setSelectedExpectation] = useState('');
 const { toast } = useToast();

 const [formData, setFormData] = useState({
  // Page 1
  day: '',
  month: '',
  year: '',
  gender: '',
  singaporean: '',
  pass: '',
  reside: '',
  occupation: '',
  income: '',

  // Page 2
  relationship: '',
  currentKids: '',
  moreKids: '',
  dependants: '',
  property: '',
  aspirations: '',

  // Page 3
  smoker: '',
  explain: '',
  budget: '',
  insurance_status: '',
  insuranceTypes: [], // This could be an array of selected insurance types

  // Page 4
  hasInsurance: '',
  inputMethod: '',
  customPolicy: '',
  coverage: {
   'life insurance': {
    has: false,
    policy: null,
    customPolicy: '',
    brand: null,
    coverageAmount: '',
    premium: '',
   },
   'integrated shield plan': {
    has: false,
    policy: null,
    customPolicy: '',
    brand: null,
    coverageAmount: '',
    premium: '',
   },
   'personal accident': {
    has: false,
    policy: null,
    customPolicy: '',
    brand: null,
    coverageAmount: '',
    premium: '',
   },
   'insurance for saving or investing': {
    has: false,
    policy: null,
    customPolicy: '',
    brand: null,
    coverageAmount: '',
    premium: '',
   },
   'any other type of insurance': {
    has: false,
    policy: null,
    customPolicy: '',
    brand: null,
    coverageAmount: '',
    premium: '',
   },
  },
  insurance_expectation: '',
  expectation_details: '',
  number: '',
 });

 const navigate = useNavigate();
 const { id } = useParams();

 const handleExpectationChange = (value: string) => {
  setSelectedExpectation(value);
 };

 const getExpectationLabel = () => {
  switch (selectedExpectation) {
   case 'looking_for_quote':
    return '[Optional] What specific insurance products are you interested in?';
   case 'have_question_about_specific_policy':
    return '[Optional] What question do you have and do you want 5 advisors to give their thoughts?';
   default:
    return '';
  }
 };

 useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const mapRelationshipStatus = (status: string | undefined): string => {
   switch (status) {
    case 'Single':
     return 'relationshipSingle';
    case 'In A Relationship':
     return 'relationshipRelationship';
    case 'Married':
     return 'relationshipMarried';
    default:
     return ''; // or you could return a default value like 'relationshipSingle'
   }
  };

  const mapSmoker = (status: string | undefined): string => {
   switch (status) {
    case 'Yes':
     return 'smokerYes';
    case 'No':
     return 'smokerNo';
    default:
     return 'smokerNo';
   }
  };

  if (id) {
   fetch(`/api/prospect-continue?id=${id}`, {
    method: 'POST',
    headers: {
     Accept: 'application/json',
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
   })
    .then(response => response.json())
    .then(data => {
     setUserId(id);
     setPage(data.page);

     // setFormData((prevFormData: FormDataState) => {
     //   const newFormData: FormDataState = {
     //     ...prevFormData,
     //     // Page 1
     //     day: data.data.Birthday ? new Date(data.data.Birthday).getDate().toString() : prevFormData.day,
     //     month: data.data.Birthday ? (new Date(data.data.Birthday).getMonth() + 1).toString() : prevFormData.month,
     //     year: data.data.Birthday ? new Date(data.data.Birthday).getFullYear().toString() : prevFormData.year,
     //     gender: data.data.Gender === 'Male' ? 'genderMale' : data.data.Gender === 'Female' ? 'genderFemale' : prevFormData.gender,
     //     singaporean: data.data['Singaporean?'] ? 'singaporeanYes' : data.data['Singaporean?'] === false ? 'singaporeanNo' : prevFormData.singaporean,
     //     pass: data.data['What pass?'] || prevFormData.pass,
     //     reside: data.data['Living in SG?'] === 'Yes' ? 'resideYes' : data.data['Living in SG?'] === 'No' ? 'resideNo' : prevFormData.reside,
     //     occupation: data.data.Occupation || prevFormData.occupation,
     //     income: data.data['Monthly Income']?.toString() || prevFormData.income,

     //     // Page 2
     //     relationship: mapRelationshipStatus(data.data['Relationship Status']),
     //     currentKids: data.data['Current Kids'] || prevFormData.currentKids,
     //     moreKids: data.data['More Kids In Future'] || prevFormData.moreKids,
     //     dependants: data.data['Other Dependants'] || prevFormData.dependants,
     //     property: data.data['Ideal Property Budget'] || prevFormData.property,
     //     aspirations: data.data['Other Aspirations'] || prevFormData.aspirations,

     //     // Page 3
     //     smoker: mapSmoker(data.data['Smoker?']),
     //     explain: data.data['Medical Condition'] || prevFormData.explain,
     //     budget: data.data.Budget?.toString() || prevFormData.budget,
     //     insurance_status: data.data['Understand Insurance'] || prevFormData.insurance_status,
     //     insuranceTypes: Array.isArray(data.data['Types Requested'])
     //       ? data.data['Types Requested']
     //       : (data.data['Types Requested'] ? [data.data['Types Requested']] : []),

     //     // Page 4
     //     hasInsurance: data.data['Existing Policies'] ? 'yes' : 'no',
     //     inputMethod: prevFormData.inputMethod,
     //     customPolicy: prevFormData.customPolicy,
     //     coverage: {
     //       ...prevFormData.coverage,
     //     },
     //     insurance_expectation: data.data.Expectation || prevFormData.insurance_expectation,
     //     expectation_details: data.data['Expectation Details'] || prevFormData.expectation_details,
     //     number: data.data['Phone Number'] || prevFormData.number,
     //   };

     //   if (data.data['Existing Policies']) {
     //     setCoverage(prev => ({
     //       ...prev,
     //       inputMethod: 'self',
     //       customPolicy: data.data['Existing Policies']
     //     }));
     //   }

     //   return newFormData;
     // });

     // Add the redirection logic here
     if (data.page > 4) {
      window.location.href = `/prospect/${id}`;
     }
    })
    .catch(error => console.error('Error fetching data:', error));
  }
 }, [location]);

 // Add this new useEffect to log the updated formData
 useEffect(() => {
  console.log('Updated formData:', formData);
 }, [formData]);

 useEffect(() => {
  console.log('Current insuranceTypes:', formData.insuranceTypes);
 }, [formData.insuranceTypes]);

 useEffect(() => {
  if (locationData && locationData.page !== undefined) {
   setPage(locationData.page);
   setUserId(locationData.id);
  }
 }, [locationData]);

 useEffect(() => {
  const fetchTypes = async () => {
   try {
    const response = await fetch('/api/get-types');
    if (!response.ok) {
     throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setTypes(data); // Adjust this line based on the structure of `data`
   } catch (error) {
    console.error('Error fetching types data:', error);
   }
  };

  fetchTypes();
 }, []);

 useEffect(() => {
  const fetchProducts = async () => {
   try {
    const response = await fetch('/api/get-products');
    const data = await response.json();
    setProducts(data.products);
    setBrands(data.brands);
   } catch (error) {
    console.error('Error fetching products:', error);
   }
  };

  fetchProducts();
 }, []);

 const handleTypeChange = (group: string, has: boolean) => {
  setCoverage(prev => ({
   ...prev,
   [group]: {
    ...prev[group],
    has,
    inputMethod: has ? 'guide' : undefined, // Set initial input method when user selects "Yes"
    policy: has ? null : undefined,
    customPolicy: has ? '' : undefined,
    brand: has ? null : undefined,
   },
  }));
 };

 const handleInputChange = e => {
  const { name, value } = e.target;
  setFormData(prevData => ({
   ...prevData,
   [name]: value,
  }));
 };

 const handlePolicySelect = (group, selection) => {
  setCoverage(prev => ({
   ...prev,
   [group]: { ...prev[group], policy: selection },
  }));
 };

 const handleCustomPolicy = (group, customPolicy) => {
  setCoverage(prev => ({
   ...prev,
   [group]: { ...prev[group], customPolicy },
  }));
 };

 const handleBrandSelect = (group, brand) => {
  setCoverage(prev => ({
   ...prev,
   [group]: { ...prev[group], brand },
  }));
 };

 const handleCoverageAmountChange = (group: string, value: string) => {
  setCoverage(prevCoverage => ({
   ...prevCoverage,
   [group]: {
    ...prevCoverage[group],
    coverageAmount: value,
   },
  }));
 };

 const handlePremiumChange = (group: string, value: string) => {
  setCoverage(prevCoverage => ({
   ...prevCoverage,
   [group]: {
    ...prevCoverage[group],
    premium: value,
   },
  }));
 };

 const insuranceOrder = [
  'life insurance',
  'integrated shield plan',
  'personal accident',
  'insurance for saving or investing',
  'any other type of insurance',
 ];

 useEffect(() => {
  // Check if page is greater than 6 and if userId is set
  if (page > 7 && userId) {
   navigate(`/prospect/${userId}`); // Redirect if conditions are met
  }
 }, [page, userId, navigate]); // Depend on page, userId, and navigate to ensure the effect runs when any of these change

 const handleBackClick = () => {
  setPage((currentPage: number) => currentPage - 1);
 };

 const scrollToTop = () => {
  const topElement = document.getElementById('top');
  if (topElement) {
   topElement.scrollIntoView({ behavior: 'smooth' });
  }
 };

 const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  // Extract form data
  const formData = new FormData(e.currentTarget);
  const formObject = Object.fromEntries(formData.entries());

  // Parse the insuranceTypes from the hidden input
  const insuranceTypes = formObject.insuranceTypes
   ? (formObject.insuranceTypes as string).split(',')
   : [];

  // Update the formData state
  setFormData(prevData => ({
   ...prevData,
   ...formObject,
   insuranceTypes: insuranceTypes,
   coverage: prevData.coverage, // Preserve the existing coverage data
  }));

  // Parse the coverage data if it exists in the form
  if (formObject.coverage) {
   try {
    formObject.coverage = JSON.parse(formObject.coverage as string);
   } catch (error) {
    console.error('Error parsing coverage data:', error);
   }
  }

  // Prepare the data to be sent to the API
  const dataToSend = {
   ...formObject,
   insuranceTypes: insuranceTypes,
   coverage: formData.get('coverage')
    ? JSON.parse(formData.get('coverage') as string)
    : undefined,
  };

  // Send the data to /api/handle-submissions
  try {
   const response = await fetch('/api/handle-submissions', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
   }

   console.log('Form submitted successfully');
  } catch (error) {
   console.error('Error submitting form:', error);
  }

  // Increment page to show the next set of questions
  setPage((currentPage: number) => {
   const nextPage = currentPage + 1;

   // Show different toast messages based on the next page
   let toastMessage = '';
   switch (nextPage) {
    case 2:
     toastMessage = '✅ Good start. Next up: Your life stage.';
     break;
    case 3:
     toastMessage = "🙌 Well done. Let's cover your health and needs.";
     break;
    case 4:
     toastMessage =
      "👍 Nearly there. Now let's get you more entries for premiums on us.";
     break;
    case 5:
     toastMessage = '🎉 All done! Now just click that button.';
     break;
    default:
     toastMessage = 'Nice progress. On to the next section.';
   }

   toast({
    title: toastMessage,
   });

   return nextPage;
  });
  scrollToTop();
 };

 const handleEmailCheck = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
 ) => {
  e.preventDefault();
  window.open('mailto:', '_blank');
  window.location.href = `/prospect/${userId}`;
 };

 const renderPage1 = () => (
  <form
   onSubmit={handleSubmit}
   className="mx-auto mb-0 mt-8 max-w-xl space-y-4"
  >
   <input type="hidden" id="userID" name="userID" value={userId} />
   <input type="hidden" id="page" name="page" value={page} />
   <div className="w-full">
    <div className="space-y-8 rounded-lg p-2 w-full mx-auto">
     <div id="birthday">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
      >
       Date of birth <label className="text-red-500">*</label>
      </label>
      <div className="flex flex-row gap-4">
       <Input
        type="number"
        name="day"
        value={formData.day}
        onChange={handleInputChange}
        className="bg-white-2"
        placeholder="DD"
        required
       />
       <Input
        type="number"
        name="month"
        value={formData.month}
        onChange={handleInputChange}
        className="bg-white-2"
        placeholder="MM"
        required
       />
       <Input
        type="number"
        name="year"
        value={formData.year}
        onChange={handleInputChange}
        className="bg-white-2"
        placeholder="YYYY"
        required
       />
      </div>
     </div>
     <div id="gender">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
      >
       Gender <label className="text-red-500">*</label>
      </label>
      <ul className="grid w-full gap-6 grid-cols-2">
       <li className="w-full gap-4 flex flex-row items-center">
        <label
         htmlFor="genderMale"
         className="flex items-center gap-4 cursor-pointer"
        >
         <input
          type="radio"
          id="genderMale"
          name="gender"
          value="genderMale"
          checked={formData.gender === 'genderMale'}
          onChange={handleInputChange}
          className="peer"
          required
         />
         <p>Male</p>
        </label>
       </li>
       <li className="w-full gap-4 flex flex-row items-center">
        <label
         htmlFor="genderFemale"
         className="flex items-center gap-4 cursor-pointer"
        >
         <input
          type="radio"
          id="genderFemale"
          name="gender"
          value="genderFemale"
          checked={formData.gender === 'genderFemale'}
          onChange={handleInputChange}
          className="peer"
          required
         />
         <p>Female</p>
        </label>
       </li>
      </ul>
     </div>
     <div id="singaporean">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
      >
       Are you Singaporean 🇸🇬? <label className="text-red-500">*</label>
      </label>
      <ul className="grid w-full gap-6 grid-cols-2">
       <li className="w-full gap-4 flex flex-row items-center">
        <label
         htmlFor="singaporeanYes"
         className="flex items-center gap-4 cursor-pointer"
        >
         <input
          type="radio"
          id="singaporeanYes"
          name="singaporean"
          value="singaporeanYes"
          checked={formData.singaporean === 'singaporeanYes'}
          onChange={handleInputChange}
          className="peer"
          required
         />
         <p>Yes</p>
        </label>
       </li>
       <li className="w-full gap-4 flex flex-row items-center">
        <label
         htmlFor="singaporeanNo"
         className="flex items-center gap-4 cursor-pointer"
        >
         <input
          type="radio"
          id="singaporeanNo"
          name="singaporean"
          value="singaporeanNo"
          checked={formData.singaporean === 'singaporeanNo'}
          onChange={handleInputChange}
          className="peer"
          required
         />
         <p>No</p>
        </label>
       </li>
      </ul>
     </div>

     {formData.singaporean === 'singaporeanNo' && (
      <div>
       <div id="sg-conditional">
        <div className="sm:col-span-2">
         <label
          htmlFor="pass"
          className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
         >
          What pass are you holding?
         </label>
         <select
          name="pass"
          value={formData.pass}
          onChange={handleInputChange}
          id="pass"
          className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-xs px-4 py-2 border mb-2"
         >
          <option value="" disabled>
           Please select
          </option>
          <option value="Dependent Pass">Dependent Pass</option>
          <option value="Employment Pass">Employment Pass</option>
          <option value="Long Term Visit Pass">Long Term Visit Pass</option>
          <option value="PR">PR</option>
          <option value="S Pass">S Pass</option>
         </select>
        </div>
       </div>

       <div id="live-sg" className="sm:col-span-2">
        <label
         htmlFor="intro"
         className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
        >
         Are you currently residing in Singapore?
        </label>
        <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
         <li className="w-full gap-4 flex flex-row items-center">
          <label
           htmlFor="resideYes"
           className="flex items-center gap-4 cursor-pointer"
          >
           <input
            type="radio"
            id="resideYes"
            name="reside"
            value="resideYes"
            checked={formData.reside === 'resideYes'}
            onChange={handleInputChange}
            className="peer"
           />
           <p>Yes</p>
          </label>
         </li>
         <li className="w-full gap-4 flex flex-row items-center">
          <label
           htmlFor="resideNo"
           className="flex items-center gap-4 cursor-pointer"
          >
           <input
            type="radio"
            id="resideNo"
            name="reside"
            value="resideNo"
            checked={formData.reside === 'resideNo'}
            onChange={handleInputChange}
            className="peer"
           />
           <p>No</p>
          </label>
         </li>
        </ul>
       </div>
      </div>
     )}
     <div id="occupation">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       What is your job or profession? <label className="text-red-500">*</label>
      </label>
      <input
       type="text"
       name="occupation"
       value={formData.occupation}
       onChange={handleInputChange}
       className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
       placeholder="Your job or occupation"
       required
      />
      <p className="mt-4 text-gray-500 text-xs text-start">
       Knowing your occupation helps us determine the level of physical risk
       associated with your job. This can impact the insurance you need for your
       everyday safety.
      </p>
     </div>
     <div id="income">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       What is your <span className="text-semibold">monthly income</span>?{' '}
       <label className="text-red-500">*</label>
      </label>
      <input
       type="number"
       name="income"
       value={formData.income}
       onChange={handleInputChange}
       className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
       placeholder="Monthly income before taxes in SGD"
       required
      />
      <p className="mt-4 text-gray-500 text-xs text-start">
       This information helps advisors calculate the coverage that best fits
       your needs, while also considering your budget.
      </p>
     </div>
    </div>
   </div>
   <div className="flex justify-end">
    <Button type="submit" className="primary-button2">
     Next
    </Button>
   </div>
  </form>
 );

 const renderPage2 = () => (
  <form
   onSubmit={handleSubmit}
   className="mx-auto mb-0 mt-8 max-w-xl space-y-4"
  >
   <input type="hidden" id="userID" name="userID" value={userId} />
   <input type="hidden" id="page" name="page" value={page} />
   <div className="w-full">
    <div className="space-y-8 rounded-lg p-2 w-full mx-auto">
     <div id="relationship">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
      >
       Are you currently... ? <label className="text-red-500">*</label>
      </label>
      <ul className="grid w-full gap-6 grid-cols-3 justify-items-center">
       {[
        { id: 'relationshipSingle', label: 'Single 👤' },
        { id: 'relationshipRelationship', label: 'In a relationship 💑' },
        { id: 'relationshipMarried', label: 'Married 💍' },
       ].map(option => (
        <li key={option.id} className="w-full gap-4 flex flex-row items-center">
         <label
          htmlFor={option.id}
          className="flex items-center gap-4 cursor-pointer"
         >
          <input
           type="radio"
           id={option.id}
           name="relationship"
           value={option.id}
           checked={formData.relationship === option.id}
           onChange={handleInputChange}
           className="peer"
           required
          />
          <p>{option.label}</p>
         </label>
        </li>
       ))}
      </ul>
     </div>

     <div id="current-kids">
      <label
       htmlFor="currentKids"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
      >
       Do you have any children? If yes, please list their ages.{' '}
       <label className="text-red-500">*</label>
      </label>
      <input
       name="currentKids"
       type="text"
       value={formData.currentKids}
       onChange={handleInputChange}
       className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
       placeholder="Enter ages if you have children (e.g., 2, 4, 7)"
       required
      />
      <p className="mt-4 text-gray-500 text-xs text-start">
       If you don't have children, enter 0
      </p>
     </div>

     <div id="how-many">
      <label
       htmlFor="moreKids"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       Do you plan on having (more) children in the future? If yes, when would
       you ideally like to have them? <label className="text-red-500">*</label>
      </label>
      <input
       name="moreKids"
       type="text"
       value={formData.moreKids}
       onChange={handleInputChange}
       className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
       placeholder="Indicate your preferred timing for having (more) children"
       required
      />
      <p className="mt-4 text-gray-500 text-xs text-start">
       This would help advisors in determining the most suitable duration for
       your insurance coverage.
      </p>
     </div>

     <div id="dependants">
      <label
       htmlFor="dependants"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       Do you currently financially support any family members or dependents, or
       anticipate doing so in the future?{' '}
       <label className="text-red-500">*</label>
      </label>
      <textarea
       name="dependants"
       value={formData.dependants}
       onChange={handleInputChange}
       className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
       placeholder="For example, if you have children who rely on your financial support or plan to support aging family members, please specify."
       rows={3}
       required
      ></textarea>
      <p className="mt-4 text-gray-500 text-xs text-start">
       By providing this information and an estimated timeframe, advisors can
       ensure it's factored into your coverage decisions.
      </p>
     </div>

     <div id="property">
      <label
       htmlFor="property"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       Do you have plans to buy a property? If yes, when do you think you might
       do so? <label className="text-red-500">*</label>
      </label>
      <textarea
       name="property"
       value={formData.property}
       onChange={handleInputChange}
       className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
       placeholder="Tell us when you plan to buy, such as within a year or a few years, and how much you expect it to be worth. It helps us understand your future goals and how to insure for it."
       rows={3}
       required
      ></textarea>
     </div>

     <div id="aspirations">
      <label
       htmlFor="aspirations"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       Are there future financial goals or aspirations that advisors should
       consider when suggesting insurance options?{' '}
       <label className="text-red-500">*</label>
      </label>
      <textarea
       name="aspirations"
       value={formData.aspirations}
       onChange={handleInputChange}
       className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
       placeholder="If not applicable, leave this field empty."
       rows={3}
       required
      ></textarea>
      <p className="mt-4 text-gray-500 text-xs text-start">
       We want to ensure all your plans, like starting a business or further
       education, are taken into account.
      </p>
     </div>
    </div>
    <div className="flex justify-between">
     <button
      type="button"
      onClick={handleBackClick}
      className="secondary-button1"
     >
      Back
     </button>
     <button type="submit" className="primary-button2">
      Next
     </button>
    </div>
   </div>
  </form>
 );

 const renderPage3 = () => (
  <form
   onSubmit={handleSubmit}
   className="mx-auto mb-0 mt-8 max-w-xl space-y-4"
  >
   <input type="hidden" id="userID" name="userID" value={userId} />
   <input type="hidden" id="page" name="page" value={page} />
   <div className="w-full">
    <div className="space-y-8 rounded-lg p-2 w-full mx-auto">
     <div id="smoker">
      <label
       htmlFor="intro"
       className="block mb-2 text-lg font-medium text-gray-900 dark:text-white text-start"
      >
       Are you a smoker? <label className="text-red-500">*</label>
      </label>
      <ul className="grid w-full gap-6 grid-cols-2">
       {[
        { id: 'smokerYes', label: 'Yes 🚬' },
        { id: 'smokerNo', label: 'No 🚭' },
       ].map(option => (
        <li key={option.id} className="w-full gap-4 flex flex-row items-center">
         <label
          htmlFor={option.id}
          className="flex items-center gap-4 cursor-pointer"
         >
          <input
           type="radio"
           id={option.id}
           name="smoker"
           value={option.id}
           checked={formData.smoker === option.id}
           onChange={handleInputChange}
           className="peer"
           required
          />
          <p>{option.label}</p>
         </label>
        </li>
       ))}
      </ul>
     </div>

     <div id="explain-medical">
      <label
       htmlFor="explain"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       Do you have any medical conditions or past medical issues?
      </label>
      <textarea
       name="explain"
       value={formData.explain}
       onChange={handleInputChange}
       className="w-full rounded-lg border-gray-200 p-3 text-xs border"
       rows={3}
       required
      ></textarea>
      <p className="mt-4 text-gray-500 text-xs text-start">
       If you've sought insurance coverage for this issue in the past, let us
       know.
      </p>
     </div>

     <div id="what-ins">
      <label
       htmlFor="intro"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
      >
       Select all the types of insurance you're interested in:{' '}
       <label className="text-red-500">*</label>
      </label>
      <p className="mt-4 text-gray-500 text-xs text-start pb-6">
       If you have any of the listed plans but want to explore more
       cost-effective options, feel free to select them! We're here to find the
       best coverage for you.
      </p>
      <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
       {types.map((type, index) => {
        const isChecked = formData.insuranceTypes.some(
         t => t === type.id || t === type.name || t === type.slug
        );
        return (
         <li
          key={index}
          className="bg-white-2 border-2 border-gray-200 rounded-lg shadow-md has-[:checked]:border-blue-600 has-[:hover]:bg-gray-50"
         >
          <input
           type="checkbox"
           name={`insuranceType_${type.id}`}
           id={type.id}
           value={type.id}
           checked={isChecked}
           onChange={e => {
            const updatedTypes = e.target.checked
             ? [...formData.insuranceTypes, type.id]
             : formData.insuranceTypes.filter(
                t => t !== type.id && t !== type.name && t !== type.slug
               );
            setFormData(prev => ({ ...prev, insuranceTypes: updatedTypes }));
           }}
           className="hidden peer"
          />
          <label
           htmlFor={type.id}
           className="flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
           <div className="block col-span-1/2 md:col-span-1/3 *:flex *:justify-center">
            <Image src={`${type.image}.svg`} alt={type.name} />
            <div className="text-gray-500 text-center">{type.name}</div>
           </div>
          </label>
         </li>
        );
       })}
      </ul>
      <input
       type="hidden"
       name="insuranceTypes"
       value={formData.insuranceTypes.join(',')}
      />
     </div>

     <div id="budget" className="py-2">
      <label
       htmlFor="budget"
       className="block mb-2 font-medium text-gray-900 dark:text-white text-start "
      >
       Do you have a monthly budget for insurance?{' '}
       <label className="text-red-500">*</label>
      </label>
      <input
       name="budget"
       type="number"
       value={formData.budget}
       onChange={handleInputChange}
       className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
       placeholder="If you don't have a specific budget, you can enter 0"
       required
      />
      <p className="mt-4 text-gray-500 text-xs text-start">
       Consider keeping insurance costs within 10% of your income for optimal
       balance. However, if you view insurance as an investment, your approach
       may differ.
      </p>
     </div>
    </div>
    <div className="flex justify-between">
     <button
      type="button"
      onClick={handleBackClick}
      className="secondary-button1"
     >
      Back
     </button>
     <button type="submit" className="primary-button2">
      Next
     </button>
    </div>
   </div>
  </form>
 );

 return (
  <div className="bg-white-1 min-h-screen h-full">
   <div className="dark:bg-gray-900 mx-auto max-w-screen-xl px-4 py-4 md:py-16 sm:px-6 lg:px-8 max-w-lg ">
    {page === null || page === undefined ? (
     <div>Loading...</div>
    ) : (
     <div>
      {page >= 1 && page <= 4 && (
       <>
        <div className="pt-6 md:pt-12 pb-8 md:pb-16 max-w-xl mx-auto">
         <h1 className="primary-h3 text-green-1 dark:text-white text-center">
          Let's begin questionnaire! 📝
         </h1>
         <p className="mt-4 text-gray-500 text-start">
          Discover your ideal insurance coverage with a quick questionnaire in{' '}
          <b>just 5 minutes</b>. Get expert advice for free and find options
          tailored to your needs. Let's make insurance easy together!
         </p>
        </div>
        <div id="top">
         <ProgressBar currentPage={page} />
        </div>
       </>
      )}

      {page === 1 && renderPage1()}
      {page === 2 && renderPage2()}
      {page === 3 && renderPage3()}
      {/* {page === 4 && renderPage4()}*/}
      {page === 4 && (
       <div className="career mx-auto text-center">
        <form
         onSubmit={handleSubmit}
         className="mx-auto mb-0 mt-8 max-w-xl space-y-4"
        >
         <input type="hidden" id="userID" name="userID" value={userId} />
         <input type="hidden" id="page" name="page" value={page} />
         <input
          type="hidden"
          id="coverage"
          name="coverage"
          value={JSON.stringify(coverage)}
         />
         <div className="w-full *:py-2">
          {/* <div id="rate">
           <label
            htmlFor="rate"
            className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
           >
            What is your level of understanding regarding insurance?{' '}
            <label className="text-red-500">*</label>
           </label>
           <ul className="grid w-full gap-4 *:text-start *:text-xs">
            {['beginner', 'intermediate', 'advanced', 'expert'].map(level => (
             <li
              key={level}
              className="w-full gap-4 flex flex-row items-center"
             >
              <label
               htmlFor={level}
               className="flex items-center gap-4 cursor-pointer"
              >
               <input
                type="radio"
                id={level}
                name="insurance_status"
                value={level}
                checked={formData.insurance_status === level}
                onChange={e =>
                 setFormData(prev => ({
                  ...prev,
                  insurance_status: e.target.value,
                 }))
                }
                className="peer"
                required
               />
               <p>
                {level.replace('_', ' ').charAt(0).toUpperCase() +
                 level.slice(1).replace('_', ' ')}
               </p>
              </label>
             </li>
            ))}
           </ul>
          </div> */}
          <div id="existing-ins" className="career mx-auto text-center">
           <div className="w-full">
            <div className="space-y-4 rounded-lg p-2 w-full mx-auto">
             <div id="has-insurance">
              <label
               htmlFor="hasInsurance"
               className="block mb-2 text-lg font-medium text-gray-900 dark:text-white text-start"
              >
               Do you already have insurance?{' '}
               <label className="text-red-500">*</label>
              </label>
              <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
               <li className="w-full gap-4 flex flex-row items-center text-sm">
                <label
                 htmlFor="hasInsuranceYes"
                 className="flex items-center gap-4 cursor-pointer"
                >
                 <input
                  type="radio"
                  id="hasInsuranceYes"
                  name="hasInsurance"
                  value="yes"
                  checked={formData.hasInsurance === 'yes'}
                  onChange={() =>
                   setFormData(prev => ({ ...prev, hasInsurance: 'yes' }))
                  }
                  className="peer"
                  required
                 />
                 <p>Yes</p>
                </label>
               </li>
               <li className="w-full gap-4 flex flex-row items-center text-sm">
                <label
                 htmlFor="hasInsuranceNo"
                 className="flex items-center gap-4 cursor-pointer"
                >
                 <input
                  type="radio"
                  id="hasInsuranceNo"
                  name="hasInsurance"
                  value="no"
                  checked={formData.hasInsurance === 'no'}
                  onChange={() =>
                   setFormData(prev => ({ ...prev, hasInsurance: 'no' }))
                  }
                  className="peer"
                  required
                 />
                 <p>No</p>
                </label>
               </li>
              </ul>
             </div>

             {formData.hasInsurance === 'yes' && (
              <div className="mt-4">
               <label
                htmlFor="inputMethod"
                className="block mb-2 text-gray-900 dark:text-white text-start"
               >
                How would you like to input the details?{' '}
                <label className="text-red-500">*</label>
               </label>
               <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
                <li className="w-full gap-4 flex flex-row items-center">
                 <label
                  htmlFor="inputMethodSelf"
                  className="flex items-center gap-4 cursor-pointer text-xs text-start"
                 >
                  <input
                   type="radio"
                   id="inputMethodSelf"
                   name="inputMethod"
                   value="self"
                   checked={coverage.inputMethod === 'self'}
                   onChange={() =>
                    setCoverage(prev => ({ ...prev, inputMethod: 'self' }))
                   }
                   className="peer"
                   required
                  />
                  <p>I'll input the details myself</p>
                 </label>
                </li>
                <li className="w-full gap-4 flex flex-row items-center">
                 <label
                  htmlFor="inputMethodGuide"
                  className="flex items-center gap-4 cursor-pointer text-xs"
                 >
                  <input
                   type="radio"
                   id="inputMethodGuide"
                   name="inputMethod"
                   value="guide"
                   checked={coverage.inputMethod === 'guide'}
                   onChange={() =>
                    setCoverage(prev => ({ ...prev, inputMethod: 'guide' }))
                   }
                   className="peer"
                   required
                  />
                  <p>Use a guide</p>
                 </label>
                </li>
               </ul>
              </div>
             )}

             {formData.hasInsurance === 'yes' &&
              coverage.inputMethod === 'self' && (
               <div className="mt-4">
                <textarea
                 className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
                 placeholder="Please describe your insurance policies (include coverage amount and monthly/annual premium if possible)"
                 value={coverage.customPolicy || ''}
                 onChange={e =>
                  setCoverage(prev => ({
                   ...prev,
                   customPolicy: e.target.value,
                  }))
                 }
                />
               </div>
              )}

             {formData.hasInsurance === 'yes' &&
              coverage.inputMethod === 'guide' &&
              insuranceOrder.map(group => (
               <div key={group} id={`insurance-${group}`} className="mt-4">
                <label
                 htmlFor={`has${group}`}
                 className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white text-start"
                >
                 Do you have {group}? <label className="text-red-500">*</label>
                </label>
                <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
                 <li className="w-full gap-4 flex flex-row items-center">
                  <label
                   htmlFor={`has${group}Yes`}
                   className="flex items-center gap-4 cursor-pointer text-xs"
                  >
                   <input
                    type="radio"
                    id={`has${group}Yes`}
                    name={`has${group}`}
                    value="yes"
                    checked={coverage[group]?.has === true}
                    onChange={() => handleTypeChange(group, true)}
                    className="peer text-xs"
                    required
                   />
                   <p>Yes</p>
                  </label>
                 </li>
                 <li className="w-full gap-4 flex flex-row items-center">
                  <label
                   htmlFor={`has${group}No`}
                   className="flex items-center gap-4 cursor-pointer text-xs"
                  >
                   <input
                    type="radio"
                    id={`has${group}No`}
                    name={`has${group}`}
                    value="no"
                    checked={
                     coverage[group]?.has === false ||
                     coverage[group]?.has === undefined
                    }
                    onChange={() => handleTypeChange(group, false)}
                    className="peer text-xs"
                    required
                   />
                   <p>No</p>
                  </label>
                 </li>
                </ul>

                {coverage[group]?.has && products[group] && (
                 <div className="mt-4">
                  <label
                   htmlFor={`policy${group}`}
                   className="block mb-2 text-sm text-gray-900 dark:text-white text-start"
                  >
                   What policy do you have?{' '}
                   <label className="text-red-500">*</label>
                  </label>
                  <Select
                   name={`policy${group}`}
                   value={coverage[group]?.policy}
                   onChange={selectedOption =>
                    handlePolicySelect(group, selectedOption)
                   }
                   options={[
                    { value: 'custom', label: "I'll type it" },
                    { value: 'unknown', label: "I don't know" },
                    ...(products[group] as Product[]).map(
                     (product: Product) => ({
                      value: product.fields.Name,
                      label: product.fields.Name,
                      product: product,
                     })
                    ),
                   ]}
                   placeholder="Select a policy (can search)"
                   className="basic-single w-full text-xs text-start"
                  />
                  {coverage[group]?.policy?.value === 'custom' && (
                   <textarea
                    className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
                    placeholder="Please describe your insurance policies (include coverage amount and monthly/annual premium if possible)"
                    value={coverage[group]?.customPolicy || ''}
                    onChange={e => handleCustomPolicy(group, e.target.value)}
                   />
                  )}

                  {coverage[group]?.policy?.value === 'unknown' && (
                   <div className="mt-4">
                    <label
                     htmlFor={`brand${group}`}
                     className="block mb-2 text-sm text-gray-900 dark:text-white text-start"
                    >
                     That's fine. Do you know what company?
                    </label>
                    <Select
                     name={`brand${group}`}
                     value={coverage[group]?.brand}
                     onChange={selectedOption =>
                      handleBrandSelect(group, selectedOption)
                     }
                     options={[
                      { value: 'unknown', label: "I don't know" },
                      ...brands.map(brand => ({ value: brand, label: brand })),
                     ]}
                     placeholder="Select a company"
                     className="basic-single w-full text-sm text-start"
                    />
                   </div>
                  )}

                  {coverage[group]?.policy?.value &&
                   coverage[group]?.policy?.value !== 'custom' &&
                   coverage[group]?.policy?.value !== 'unknown' &&
                   coverage[group]?.brand?.value !== 'unknown' && (
                    <div className="mt-4 space-y-4">
                     <div>
                      <label
                       htmlFor={`coverage${group}`}
                       className="block mb-2 text-sm text-gray-900 dark:text-white text-start"
                      >
                       What is the coverage amount?
                      </label>
                      <input
                       type="text"
                       id={`coverage${group}`}
                       name={`coverage${group}`}
                       className="w-full rounded-lg border-gray-200 p-3 text-xs border"
                       placeholder="Enter coverage amount"
                       value={coverage[group]?.coverageAmount || ''}
                       onChange={e =>
                        handleCoverageAmountChange(group, e.target.value)
                       }
                      />
                     </div>
                     <div>
                      <label
                       htmlFor={`premium${group}`}
                       className="block mb-2 text-sm text-gray-900 dark:text-white text-start"
                      >
                       How much are you paying?
                      </label>
                      <input
                       type="text"
                       id={`premium${group}`}
                       name={`premium${group}`}
                       className="w-full rounded-lg border-gray-200 p-3 text-xs border"
                       placeholder="Enter premium amount"
                       value={coverage[group]?.premium || ''}
                       onChange={e =>
                        handlePremiumChange(group, e.target.value)
                       }
                      />
                     </div>
                    </div>
                   )}
                 </div>
                )}
               </div>
              ))}
            </div>
           </div>
          </div>
          <div id="insurance_expectation">
           <label
            htmlFor="intro"
            className="block mb-2 font-medium text-gray-900 dark:text-white text-start"
           >
            What are you hoping to get from this experience{' '}
            <label className="text-red-500">*</label>
           </label>
           <ul className="grid w-full gap-4">
            {[
             {
              id: 'want_full_review',
              label: 'I want a full review of my financial situation.',
             },
             {
              id: 'looking_for_quote',
              label: 'I know what I want. Just need quotes.',
             },
             {
              id: 'have_question_about_specific_policy',
              label: 'I have questions about a specific policy.',
             },
             { id: 'looking_for_advice', label: "I'm looking for advice." },
            ].map(option => (
             <li
              key={option.id}
              className="w-full gap-4 flex flex-row items-center"
             >
              <label
               htmlFor={option.id}
               className="flex items-center gap-4 cursor-pointer"
              >
               <input
                type="radio"
                id={option.id}
                name="insurance_expectation"
                value={option.id}
                checked={formData.insurance_expectation === option.id}
                onChange={e => {
                 setFormData(prev => ({
                  ...prev,
                  insurance_expectation: e.target.value,
                 }));
                 handleExpectationChange(e.target.value);
                }}
                className="peer"
                required
               />
               <p>{option.label}</p>
              </label>
             </li>
            ))}
           </ul>
           {formData.insurance_expectation ===
            'have_question_about_specific_policy' && (
            <div className="mt-4">
             <label
              htmlFor="expectation_details"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start"
             >
              {getExpectationLabel()}
             </label>
             <textarea
              id="expectation_details"
              name="expectation_details"
              value={formData.expectation_details}
              onChange={e =>
               setFormData(prev => ({
                ...prev,
                expectation_details: e.target.value,
               }))
              }
              className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
              rows={4}
              placeholder="Please provide more details..."
             ></textarea>
            </div>
           )}
          </div>
          <div id="number" className="flex flex-col">
           <label
            htmlFor="number"
            className="block mb-2 heading-4-primary text-start"
           >
            Looking for 5 unique perspectives?
           </label>
           <p className="mt-4 text-gray-500 text-sm text-start">
            Coming up with a proposal takes most advisors a few hours. In order
            for them to feel comfortable investing that time to help you, they
            would like to know if you're serious about these proposals. <br />
            If you decide you rather not share your number, you can leave it
            blank <b>but we'll only be able assign one advisor</b> to you.
           </p>
           <input
            type="tel"
            name="number"
            value={formData.number}
            onChange={e => {
             const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
             setFormData(prev => ({ ...prev, number: value }));
            }}
            pattern="[89]\d{7}"
            minLength={8}
            maxLength={8}
            className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2"
            placeholder="Enter your 8-digit phone number (optional)"
           />
           <p className="mt-4 text-gray-500 text-sm text-start">
            The advisor will only get this information after they share their
            suggestions with you. We understand the potential of getting spammed
            which is why we{' '}
            <BlockNumberFAQ>
             will penalise anyone who is flagged as spamming
            </BlockNumberFAQ>
            .
           </p>
          </div>
          <div className="flex justify-between">
           <button
            type="button"
            onClick={handleBackClick}
            className="secondary-button1"
           >
            Back
           </button>
           <button type="submit" className="primary-button2">
            Complete!
           </button>
          </div>
         </div>
        </form>
       </div>
      )}

      {page === 5 && (
       <div className="pt-6 md:pt-12 pb-8 md:pb-16 max-w-xl mx-auto text-center">
        <h1 className="primary-h2 text-green-1 dark:text-white text-center">
         Thank you for completing the questionnaire!
        </h1>
        <p className="py-2 text-start">
         We're excited to help you get started. Just{' '}
         <b>two more quick steps:</b>
        </p>
        <p className="py-2 text-start">
         <b>1. Check Your Email:</b> We've sent you a message. Take a peek in
         your inbox (and maybe your spam folder, just in case).
        </p>
        <p className="py-2 text-start">
         <b>2. Click to Verify:</b> Simply click the button in that email to
         confirm your details. This helps us verify you and match you with the
         perfect advisor.
        </p>
        <p className="py-2 mb-4 text-start">
         For any issues or further assistance, feel free to contact our support
         team at support@whatins.sg.
        </p>
        <a
         href={`/prospect/${userId}`}
         onClick={handleEmailCheck}
         className="primary-button1 w-full"
        >
         Check email inbox →
        </a>
       </div>
      )}
     </div>
    )}
   </div>
  </div>
 );
};

export default Questionnaire;
