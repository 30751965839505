import { useState, useEffect } from 'react';
import Image from "./image";
import { Link } from 'react-router-dom';


interface BrandData {
  id: string;
  image: string;
  ctaLink: string;
  header: string;
}

const Numbers = () => {
  return (
<div className='flex flex-col md:flex-row w-full justify-between max-w-2xl md:mx-auto md:pt-4 gap-2 md:gap-6 px-4 md:px-0'>
  <div className="justify-between md:justify-start items-center gap-2.5 flex">
            <p className=" primary-h2">1000+</p>
            <div className="Text flex-col justify-start items-start inline-flex">
              <p className="OurActiv text-gray-500 text-sm font-normal">Suggestions facilitated</p>
            </div>
          </div>
          <div className="Fact2 justify-between md:justify-start  items-center gap-2.5 flex">
          <p className=" primary-h2">50+</p>
            <div className="Text flex-col justify-start items-start inline-flex">
              <p className="OurActiv text-gray-500 text-sm font-normal ">Insurance Experts</p>
            </div>
          </div>
          <div className="Fact3 justify-between md:justify-start items-center gap-2.5 flex">
          <p className=" primary-h2">10+</p>
            <div className="Text flex-col justify-start items-start inline-flex">
              <p className="OurActiv text-gray-500 text-sm font-normal ">Insurance Companies</p>
            </div>
          </div>
  </div>
  )}

  const Logos = ({ text = 'Our Experts are from' }) => {
    return (
      <section className="bg-white-1">
    <div className="py-8 mx-auto max-w-screen-xl px-4">
        <h2 className="mb-8 lg:mb-16 text-h5 font-extrabold tracking-tight leading-tight text-center text-green-1 uppercase">{text}</h2>
        <div className="grid grid-cols-4 gap-8 text-gray-500 sm:gap-12 md:grid-cols-6 dark:text-gray-400">
        <Image src='prudential-logo.png' imgClass='rounded-none w-24'/>
        <Image src='great-eastern-logo.png' imgClass='rounded-none w-24'/>
        <Image src='income-logo.png' imgClass='rounded-none w-24'/>
        <Image src='synergy-finanical-logo.png' imgClass='rounded-none w-24'/>
        <Image src='finexis-logo.png' imgClass='rounded-none w-24'/>
        <Image src='aia-logo.png' imgClass='rounded-none w-24'/>
        </div>
    </div>
</section>
    );
  };

  const BrandsYouTrust = ({ text = 'Get quotes to brands you trust', url = true }: { text?: string; url?: boolean }) => {
    const [brands, setBrands] = useState<BrandData[]>([]);
  
    useEffect(() => {
      fetch('/api/get-brand')
        .then(response => response.json())
        .then(data => setBrands(data))
        .catch(error => console.error('Error fetching brands:', error));
    }, []);
  
    return (
      <section className="bg-white-1">
                <div className="py-8 mx-auto max-w-screen-xl px-4">
          <h2 className="primary-h3 text-green-1 text-center uppercase pb-4">{text}</h2>
          <div className="grid grid-cols-4 gap-8 text-gray-500 sm:gap-12 md:grid-cols-8 dark:text-gray-400">
            {brands.map(brand => (
              url ? (
                <Link key={brand.id} to={brand.ctaLink}>
                  <Image src={brand.image} alt="Brand logo" imgClass='rounded-none w-24 cursor-pointer' />
                </Link>
              ) : (
                <Image key={brand.id} src={brand.image} alt={`Whatins ${brand.header} logo`} imgClass='rounded-none w-24' />
              )
            ))}
          </div>
        </div>
      </section>
    );
  };
  
export { BrandsYouTrust };
export { Numbers };
export { Logos };