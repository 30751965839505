'use client';

import { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import {
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableHeader,
 TableRow,
} from '../components/ui/table';
import {
 Card,
 CardContent,
 CardHeader,
 CardTitle,
} from '../components/ui/card';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { ArrowUpDown, Plus } from 'lucide-react';
import { useToast } from '../components/ui/use-toast';

// Define the Advisor type
type Advisor = {
 id: number;
 name: string;
 email: string;
 totalProposals: number;
 submittedProposals: number;
};

export default function Agency() {
 const [advisors, setAdvisors] = useState<Advisor[]>([]);
 const [selectedAdvisor, setSelectedAdvisor] = useState<Advisor | null>(null);
 const [sortColumn, setSortColumn] = useState<keyof Advisor>('name');
 const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
 const [filterName, setFilterName] = useState('');
 const [newAdvisorName, setNewAdvisorName] = useState('');
 const [newAdvisorEmail, setNewAdvisorEmail] = useState('');
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState<string | null>(null);
 const [agency, setAgency] = useState<any>(null);
 const { toast } = useToast();

 useEffect(() => {
  fetchAgencyData();
 }, []);

 const fetchAgencyData = async () => {
  setLoading(true);
  setError(null);
  try {
   const response = await fetch('/api/agency-dashboard');
   if (!response.ok) {
    throw new Error('Failed to fetch agency data');
   }
   const data = await response.json();
   setAgency(data.agency);
   setAdvisors(
    data.advisors.map((advisor: any) => ({
     id: advisor.data.id,
     name: advisor.data.name,
     email: advisor.data.email,
     totalProposals: advisor.data.totalProposals,
     submittedProposals: advisor.data.submittedProposals,
    }))
   );
  } catch (err) {
   setError('Failed to fetch agency data');
  } finally {
   setLoading(false);
  }
 };

 // Sort advisors
 const sortedAdvisors = [...advisors].sort((a, b) => {
  if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
  if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
  return 0;
 });

 // Filter advisors
 const filteredAdvisors = sortedAdvisors.filter(advisor =>
  advisor.name.toLowerCase().includes(filterName.toLowerCase())
 );

 // Calculate team stats
 const teamStats = advisors.reduce(
  (acc, advisor) => {
   acc.totalProposals += advisor.totalProposals;
   acc.submittedProposals += advisor.submittedProposals;
   return acc;
  },
  { totalProposals: 0, submittedProposals: 0 }
 );

 // Handle sort
 const handleSort = (column: keyof Advisor) => {
  if (column === sortColumn) {
   setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  } else {
   setSortColumn(column);
   setSortDirection('asc');
  }
 };

 // Add new advisor
 const handleAddAdvisor = async () => {
  if (newAdvisorName && newAdvisorEmail && agency) {
   try {
    const response = await fetch('/api/add-advisor', {
     method: 'POST',
     headers: {
      'Content-Type': 'application/json',
     },
     body: JSON.stringify({
      name: newAdvisorName,
      email: newAdvisorEmail,
      agencyID: agency.recordid,
     }),
    });
    if (!response.ok) {
     throw new Error('Failed to add advisor');
    }
    const data = await response.json();
    setAdvisors([
     ...advisors,
     {
      id: data.advisor.id,
      name: data.advisor.fields.Name,
      email: data.advisor.fields.Email,
      totalProposals: 0,
      submittedProposals: 0,
     },
    ]);
    toast({
     title: 'Advisor added successfully',
     description:
      'An email has been sent to them to finish their account creation',
    });
    setNewAdvisorName('');
    setNewAdvisorEmail('');
   } catch (err) {
    setError('Failed to add advisor');
    toast({
     title: 'Failed to add advisor',
     description:
      'Please try again and if it fails again, please contact admin@whatins.sg',
     variant: 'destructive',
    });
   }
  }
 };

 // Pie chart data
 const getPieChartData = (submitted: number, total: number) => [
  { name: 'Submitted', value: submitted },
  { name: 'Not Submitted', value: total - submitted },
 ];

 if (loading) return <div>Loading...</div>;
 if (error) return <div>Error: {error}</div>;

 return (
  <div className="container mx-auto p-4">
   <h1 className="text-2xl font-bold mb-4">
    {agency ? `${agency.name} Dashboard` : 'Insurance Team Dashboard'}
   </h1>

   {/* Team Stats */}
   <Card className="mb-6">
    <CardHeader>
     <CardTitle>Team Overview</CardTitle>
    </CardHeader>
    <CardContent>
     <div className="flex justify-between items-center">
      <div>
       <p>Total Proposals: {teamStats.totalProposals}</p>
       <p>Submitted Proposals: {teamStats.submittedProposals}</p>
       <p>
        Submission Rate:{' '}
        {(
         (teamStats.submittedProposals / teamStats.totalProposals) *
         100
        ).toFixed(2)}
        %
       </p>
      </div>
      <div className="w-64 h-64">
       <ResponsiveContainer width="100%" height="100%">
        <PieChart>
         <Pie
          data={getPieChartData(
           teamStats.submittedProposals,
           teamStats.totalProposals
          )}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={({ name, percent }) =>
           `${name} ${(percent * 100).toFixed(0)}%`
          }
         >
          <Cell fill="#4CAF50" />
          <Cell fill="#FFA000" />
         </Pie>
         <Legend />
        </PieChart>
       </ResponsiveContainer>
      </div>
     </div>
    </CardContent>
   </Card>

   {/* Advisor Table */}
   <Card className="mb-6">
    <CardHeader>
     <CardTitle>Advisors</CardTitle>
    </CardHeader>
    <CardContent>
     <div className="mb-4">
      <Input
       placeholder="Filter by name"
       value={filterName}
       onChange={e => setFilterName(e.target.value)}
      />
     </div>
     <Table>
      <TableHeader>
       <TableRow>
        <TableHead
         onClick={() => handleSort('name')}
         className="cursor-pointer"
        >
         Name <ArrowUpDown className="inline ml-2" />
        </TableHead>
        <TableHead
         onClick={() => handleSort('email')}
         className="cursor-pointer"
        >
         Email <ArrowUpDown className="inline ml-2" />
        </TableHead>
        <TableHead
         onClick={() => handleSort('totalProposals')}
         className="cursor-pointer"
        >
         Total Proposals <ArrowUpDown className="inline ml-2" />
        </TableHead>
        <TableHead
         onClick={() => handleSort('submittedProposals')}
         className="cursor-pointer"
        >
         Submitted Proposals <ArrowUpDown className="inline ml-2" />
        </TableHead>
        <TableHead
         onClick={() => handleSort('submittedProposals')}
         className="cursor-pointer"
        >
         % Submitted <ArrowUpDown className="inline ml-2" />
        </TableHead>
       </TableRow>
      </TableHeader>
      <TableBody>
       {filteredAdvisors.map(advisor => (
        <TableRow
         key={advisor.id}
         onClick={() => setSelectedAdvisor(advisor)}
         className="cursor-pointer"
        >
         <TableCell>{advisor.name}</TableCell>
         <TableCell>{advisor.email}</TableCell>
         <TableCell>{advisor.totalProposals}</TableCell>
         <TableCell>{advisor.submittedProposals}</TableCell>
         <TableCell>
          {(
           (advisor.submittedProposals / advisor.totalProposals) *
           100
          ).toFixed(2)}
          %
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </CardContent>
   </Card>

   {/* Selected Advisor Chart */}
   {selectedAdvisor && (
    <Card className="mb-6">
     <CardHeader>
      <CardTitle>{selectedAdvisor.name}'s Submission Rate</CardTitle>
     </CardHeader>
     <CardContent>
      <div className="w-64 h-64 mx-auto">
       <ResponsiveContainer width="100%" height="100%">
        <PieChart>
         <Pie
          data={getPieChartData(
           selectedAdvisor.submittedProposals,
           selectedAdvisor.totalProposals
          )}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={({ name, percent }) =>
           `${name} ${(percent * 100).toFixed(0)}%`
          }
         >
          <Cell fill="#4CAF50" />
          <Cell fill="#FFA000" />
         </Pie>
         <Legend />
        </PieChart>
       </ResponsiveContainer>
      </div>
     </CardContent>
    </Card>
   )}

   {/* Add Advisor Form */}
   <Card>
    <CardHeader>
     <CardTitle>Add New Advisor</CardTitle>
    </CardHeader>
    <CardContent>
     <div className="flex space-x-2">
      <Input
       placeholder="Advisor Name"
       value={newAdvisorName}
       onChange={e => setNewAdvisorName(e.target.value)}
      />
      <Input
       placeholder="Advisor Email"
       value={newAdvisorEmail}
       onChange={e => setNewAdvisorEmail(e.target.value)}
      />
      <Button onClick={handleAddAdvisor}>
       <Plus className="mr-2 h-4 w-4" /> Add Advisor
      </Button>
     </div>
    </CardContent>
   </Card>
  </div>
 );
}
