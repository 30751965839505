import React, { useEffect, useState, useCallback } from 'react';
import { ScrollArea } from '../components/ui/scroll-area';
import { SvgIcons } from '../components/svg';
import { useToast } from '../components/ui/use-toast';
import Loading from '../components/GIF';
import { reportError } from '../components/reportError';
import {
 Tabs,
 TabsContent,
 TabsList,
 TabsTrigger,
} from '../components/ui/tabs';

import { Drawer, DrawerContent, DrawerTrigger } from '../components/ui/drawer';
import { Button } from '../components/ui/button';
import {
 Tooltip,
 TooltipContent,
 TooltipProvider,
 TooltipTrigger,
} from '../components/ui/tooltip';

type DynamicFields = {
 [key: string]: string | number | boolean | null; // Assuming simple scalar values, adjust as necessary
};
interface Prospect {
 fields?: DynamicFields;
}

const AdvisorProspect = () => {
 const [data, setData] = useState({ prospectList: [], user: {} });
 const [prospectList, setProspectList] = useState([]);
 const [advisor, setAdvisor] = useState({});
 const [isLoading, setIsLoading] = useState(true);
 const [selectedProspectIndex, setSelectedProspectIndex] = useState(null);
 const [comments, setComments] = useState('');
 const { toast } = useToast();
 const [countdown, setCountdown] = useState(0);
 const [activeTab, setActiveTab] = useState('active');
 const [archivedProspectList, setArchivedProspectList] = useState([]);
 const [activeProspects, setActiveProspects] = useState([]);
 const [archivedProspects, setArchivedProspects] = useState([]);
 const [totalCount, setTotalCount] = useState(0);

 const [showArchiveButton, setShowArchiveButton] = useState(() => {
  const saved = localStorage.getItem('showArchiveButton');
  return saved !== null ? JSON.parse(saved) : true;
 });

 useEffect(() => {
  localStorage.setItem('showArchiveButton', JSON.stringify(showArchiveButton));
 }, [showArchiveButton]);

 useEffect(() => {
  try {
   setActiveProspects(
    activeTab === 'active' ? prospectList : archivedProspectList
   );
   setSelectedProspectIndex(0);
  } catch (error) {
   reportError({
    message: error.message,
    url: window.location.href,
    function: 'useEffect (activeTab)',
    componentName: 'AdvisorProspect',
    additionalInfo: {
     activeTab,
     prospectListLength: prospectList.length,
     archivedProspectListLength: archivedProspectList.length,
    },
    stackTrace: error.stack,
   });
   console.error('Error setting active prospects:', error);
  }
 }, [activeTab, prospectList, archivedProspectList]);

 const fetchInitialProspects = useCallback(async () => {
  try {
   const response = await fetch(`/api/advisor-prospect?limit=5&offset=0`);
   if (!response.ok) {
    if (response.status === 400) {
     window.location.href = '/advisor-login';
     return;
    }
    throw new Error('Network response was not ok');
   }
   const data = await response.json();
   console.log(data);
   setData(data);
   setProspectList(data.prospectList || []);
   setActiveProspects(data.prospectList || []);
   setAdvisor(data.user || {});
   setTotalCount(data.totalCount);
   setSelectedProspectIndex(0);
  } catch (error) {
   await reportError({
    message: error.message,
    url: window.location.href,
    function: 'fetchInitialProspects',
    componentName: 'AdvisorProspect',
    additionalInfo: { limit: 5, offset: 0 },
    stackTrace: error.stack,
   });
   console.error('Error fetching initial prospects:', error);
  } finally {
   setIsLoading(false);
  }
 }, []);

 const fetchArchivedProspects = useCallback(async () => {
  try {
   const response = await fetch(`/api/advisor-archived`);
   const archivedData = await response.json();
   setArchivedProspectList(archivedData.prospectList || []);
   setArchivedProspects(archivedData.prospectList || []);
  } catch (error) {
   await reportError({
    message: error.message,
    url: window.location.href,
    function: 'fetchArchivedProspects',
    componentName: 'AdvisorProspect',
    stackTrace: error.stack,
   });
   console.error('Error fetching archived data:', error);
  }
 }, []);

 const fetchRemainingProspects = useCallback(async () => {
  try {
   const response = await fetch(
    `/api/advisor-prospect?limit=${totalCount}&offset=5`
   );
   if (!response.ok) throw new Error('Network response was not ok');
   const data = await response.json();
   setProspectList(prevList => [...prevList, ...(data.prospectList || [])]);
   setActiveProspects(prevList => [...prevList, ...(data.prospectList || [])]);
  } catch (error) {
   await reportError({
    message: error.message,
    url: window.location.href,
    function: 'fetchRemainingProspects',
    componentName: 'AdvisorProspect',
    additionalInfo: { totalCount, offset: 5 },
    stackTrace: error.stack,
   });
   console.error('Error fetching remaining prospects:', error);
  }
 }, [totalCount]);

 useEffect(() => {
  const fetchData = async () => {
   try {
    setIsLoading(true);
    await fetchInitialProspects();
    await fetchArchivedProspects();
    await fetchRemainingProspects();
   } catch (error) {
    reportError({
     message: error.message,
     url: window.location.href,
     function: 'useEffect (fetchData)',
     componentName: 'AdvisorProspect',
     stackTrace: error.stack,
    });
    console.error('Error fetching data:', error);
   } finally {
    setIsLoading(false);
   }
  };

  fetchData();
 }, [fetchInitialProspects, fetchArchivedProspects, fetchRemainingProspects]);

 // This function will be called when the form is submitted
 const handleSubmit = async event => {
  event.preventDefault();
  setIsLoading(true);

  const formData = new FormData(event.target);
  formData.append('action', 'submit');

  try {
   const response = await fetch('/api/advisor-prospect-action', {
    method: 'POST',
    body: formData,
   });

   if (response.ok) {
    toast({
     title: 'Submission sent',
     description: "Please check your email to get the prospect's contact.",
    });
   } else {
    toast({
     title: 'Something went wrong',
     description: 'Please email us about it. Sorry about that 🙏',
     variant: 'destructive',
    });
   }
  } catch (error) {
   await reportError({
    message: error.message,
    url: window.location.href,
    function: 'handleSubmit',
    componentName: 'AdvisorProspect',
    additionalInfo: { action: 'submit' },
    stackTrace: error.stack,
   });
   toast({
    title: 'Something went wrong',
    description: 'Please try again or contact support if the issue persists.',
    variant: 'destructive',
   });
  } finally {
   setIsLoading(false);
  }
 };

 const messageProspect = async event => {
  event.preventDefault();

  // Start the countdown immediately
  setCountdown(5);
  const timer = setInterval(() => {
   setCountdown(prevCount => {
    if (prevCount <= 1) {
     clearInterval(timer);
     return 0;
    }
    return prevCount - 1;
   });
  }, 1000);

  // Check if the message is empty or only contains whitespace
  if (!comments.trim()) {
   toast({
    title: 'Empty message',
    description: 'We assume that was a mistake. No message was sent.',
   });
   return;
  }

  if (countdown > 0) {
   toast({
    title: 'Please wait',
    description: `You can send another message in ${countdown} seconds.`,
   });
   return;
  }

  const form = event.target;
  const submission_id = form.elements.submission_id.value;
  const formData = {
   comments: comments,
   action: 'message',
   prospectId: submission_id,
  };

  try {
   const response = await fetch('/api/advisor-prospect-action', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
   });

   if (response.ok) {
    setComments('');
    toast({
     title: 'Message sent',
     description: 'Your message has been sent.',
    });
   } else {
    toast({
     title: 'Something went wrong',
     description: 'Please email us about it. Sorry about that 🙏',
     variant: 'destructive',
    });
   }
  } catch (error) {
   await reportError({
    message: error.message,
    url: window.location.href,
    function: 'messageProspect',
    componentName: 'AdvisorProspect',
    additionalInfo: { action: 'message', prospectId: formData.prospectId },
    stackTrace: error.stack,
   });
   console.error('Network error:', error);
   toast({
    title: 'Network error',
    description: 'Please check your internet connection and try again.',
    variant: 'destructive',
   });
  }
 };

 const handleArchive = async (recordID: string, currentlyArchived: boolean) => {
  const action = currentlyArchived ? 'unarchive' : 'archive';
  try {
   const response = await fetch('/api/advisor-prospect-action', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     action: action,
     prospectId: recordID,
    }),
   });

   if (!response.ok) {
    throw new Error('Network response was not ok');
   }

   const result = await response.json();

   // Update the state based on the action
   if (action === 'archive') {
    setActiveProspects(prevList =>
     prevList.filter(
      prospect => prospect.submission.fields.recordID !== recordID
     )
    );
    setArchivedProspects(prevList => [...prevList, result.updatedProspect]);
   } else {
    setArchivedProspects(prevList =>
     prevList.filter(
      prospect => prospect.submission.fields.recordID !== recordID
     )
    );
    setActiveProspects(prevList => [result.updatedProspect, ...prevList]);
   }

   // Reset selected prospect if it was archived/unarchived
   setSelectedProspectIndex(null);

   // Force a re-render of the active tab
   setActiveTab(prev => (prev === 'active' ? 'active' : 'archived'));

   // Show success toast notification
   toast({
    title: action === 'archive' ? 'Prospect Archived' : 'Prospect Unarchived',
    description: `${
     result.updatedProspect.client_proposal.fields.Name
    } has been ${
     action === 'archive' ? 'archived' : 'unarchived'
    } successfully. If you don't see it, please refresh.`,
    variant: 'default',
   });
  } catch (error) {
   await reportError({
    message: error.message,
    url: window.location.href,
    function: 'handleArchive',
    componentName: 'AdvisorProspect',
    additionalInfo: { action, recordID, currentlyArchived },
    stackTrace: error.stack,
   });
   console.error('Error:', error);
   toast({
    title: 'Error',
    description: `Failed to ${action} the prospect. Please try again.`,
    variant: 'destructive',
   });
  }
 };

 if (isLoading) {
  return (
   <div>
    <Loading src="loading.mp4" />
   </div>
  ); // Display loading message while fetching data
 }

 const renderProspectDetails = () => {
  if (selectedProspectIndex === null) return null;

  if (selectedProspectIndex === null) return null;
  const currentProspects =
   activeTab === 'active' ? activeProspects : archivedProspects;
  const prospect = currentProspects[selectedProspectIndex];

  if (!prospect) return null;

  if (activeTab === 'archived') {
   return (
    <div className="flex flex-col items-center justify-center h-full w-full order-2">
     <p className="text-xl font-semibold text-gray-500 text-center">
      This prospect is archived.
     </p>
     <p className="text-md text-gray-400 text-center">
      Unarchive to view details.
     </p>
    </div>
   );
  }

  return (
   <>
    <ScrollArea
     id="card"
     className="h-full flex flex-col basis-5/12 grow-0 p-4 order-2"
     type="always"
    >
     {selectedProspectIndex !== null &&
      (activeTab === 'active'
       ? prospectList[selectedProspectIndex]
       : archivedProspectList[selectedProspectIndex]) && (
       <div className="card-content flex flex-col md:flex-row gap-8">
        <div
         id="col-1"
         className="gap-y-8 divide-y-2 order-last md:order-first"
        >
         <ul className="space-y-2 odd:bg-white-1 *:even:bg-slate-500">
          <li>
           <div className="flex justify-between mb-4 text-xl font-bold  ">
            Alias:{' '}
            <span className="text-right mb-2 text-lg">
             {prospect['client_proposal']['fields']['Name']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Email:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospectList[selectedProspectIndex]['submission']['fields'][
              'send?'
             ] ? (
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['Email']}
              </span>
             ) : (
              <span className="text-right mb-2 text-sm font-light">
               Will be given after submission
              </span>
             )}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Phone:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospectList[selectedProspectIndex]['submission']['fields'][
              'send?'
             ] && prospect['client_proposal']['fields']['Phone Number'] ? (
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['Phone Number']}
              </span>
             ) : (
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['Phone Number'] ? (
                <span className="text-right mb-2 text-sm font-light">
                 Will be given after submission
                </span>
               ) : (
                <span className="text-right mb-2 text-sm font-light">
                 No number was provided
                </span>
               )}
              </span>
             )}
            </span>
           </div>
          </li>
          {/* <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Telegram ID:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospectList[selectedProspectIndex]['submission']['fields'][
              'send?'
             ] && prospect['client_proposal']['fields']['Telegram ID'] ? (
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['Telegram ID']}
              </span>
             ) : (
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['Telegram ID'] ? (
                <span className="text-right mb-2 text-sm font-light">
                 Will be given after submission
                </span>
               ) : (
                <span className="text-right mb-2 text-sm font-light">
                 No Telegram ID was provided
                </span>
               )}
              </span>
             )}
            </span>
           </div>
          </li> */}
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Gender:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Gender']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Birthday:
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Birthday']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Singaporean?:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Singaporean?'] ? (
              <p>Yes</p>
             ) : (
              <p>No</p>
             )}
            </span>
           </div>
          </li>
          {!prospectList[selectedProspectIndex]['client_proposal']['fields'][
           'Singaporean?'
          ] && (
           <div>
            <li>
             <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
              What Pass:{' '}
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['What pass?']}
              </span>
             </div>
            </li>
            <li>
             <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
              Live in 🇸🇬?:{' '}
              <span className="text-right mb-2 text-sm font-light">
               {prospect['client_proposal']['fields']['Living in SG?']}
              </span>
             </div>
            </li>
           </div>
          )}
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Relationship Status:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Relationship Status']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Have Kids?:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Current Kids']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Future Kids?:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['More Kids In Future']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Current / Future Dependants:
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Other Dependants']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Occupation:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Occupation']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Monthly Income:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Monthly Income']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Ideal Insurance Budget (Monthly):{' '}
            {prospect['client_proposal']['fields']['Budget'] === 0 ? (
             <span className="text-right mb-2 text-sm font-light">
              Don't know - please advise
             </span>
            ) : (
             <span className="text-right mb-2 text-sm font-light">
              {prospect['client_proposal']['fields']['Budget']}
             </span>
            )}
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Smoker?:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Smoker?'] ? (
              <p>Yes</p>
             ) : (
              <p>No</p>
             )}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Medical History?:
            <span className="text-right mb-2 text-sm font-light">
             {' '}
             {prospect['client_proposal']['fields']['Medical?'] ? (
              <p>Yes</p>
             ) : (
              <p>None</p>
             )}
            </span>
           </div>
           {prospect['client_proposal']['fields']['Medical?'] && (
            <div className="text-start pl-2 mb-2 text-sm">
             {prospect['client_proposal']['fields']['Medical Condition']}{' '}
            </div>
           )}
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            1-10 How Well Do You Understand Insurance:{' '}
            <span className="text-right mb-2 text-sm font-light">
             {prospect['client_proposal']['fields']['Understand Insurance']}
            </span>
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            What Insurance Do You Have?
           </div>
           <div className="text-start pl-2 mb-2 text-sm">
            {prospect['client_proposal']['fields']['Existing Policies'] ? (
             <div>
              {prospect['client_proposal']['fields']['Existing Policies']}
             </div>
            ) : (
             <p>No Insurance</p>
            )}
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            What Insurance Are You Looking For?
           </div>
           <ul className="grid grid-cols-3 block mb-2 text-sm">
            {Array.isArray(
             prospectList[selectedProspectIndex]['client_proposal']['fields'][
              'Types Name'
             ]
            ) &&
             prospectList[selectedProspectIndex]['client_proposal']['fields'][
              'Types Name'
             ].map((plan, index) => <li key={index}>{plan}</li>)}
           </ul>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Do you plan on buying property? If so, what's the budget range?
           </div>
           <div className="text-start pl-2 mb-2 text-sm">
            {prospect['client_proposal']['fields']['High Risk Hobbies?'] ? (
             <p>
              {prospect['client_proposal']['fields']['Ideal Property Budget']}
             </p>
            ) : (
             <p>No plans</p>
            )}
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Do you have any future financial goals and aspirations that the
            advisor should be aware of to suggest the perfect insurance fit?
           </div>
           <div className="text-start pl-2 mb-2 text-sm">
            {prospect['client_proposal']['fields']['Other Aspirations']}
           </div>
          </li>
          <li>
           <div className="flex justify-between gap-y-2 mb-2 text-md font-medium ">
            Is there anything else you'd like to share?
           </div>
           {prospect['client_proposal']['fields']['Expectation Details'] ? (
            <div className="text-start pl-2 mb-2 text-sm">
             {prospect['client_proposal']['fields']['Expectation Details']}
            </div>
           ) : (
            <div className="text-start pl-2 mb-2 text-sm">N/A</div>
           )}
          </li>
         </ul>
        </div>
       </div>
      )}
    </ScrollArea>
    <ScrollArea
     id="card"
     className="h-full flex flex-col basis-5/12 grow-0 p-4 order-3"
     type="always"
    >
     {selectedProspectIndex !== null &&
      (activeTab === 'active'
       ? prospectList[selectedProspectIndex]
       : archivedProspectList[selectedProspectIndex]) && (
       <div className="card-content flex flex-col md:flex-row gap-8">
        <div id="col2" className="flex flex-col ">
         <div className="flex justify-between text-xl font-bold block md:hidden">
          Alias:{' '}
          <span className="text-right mb-2 text-lg">
           {prospect['client_proposal']['fields']['Name']}
          </span>
         </div>
         {(() => {
          const prospect = prospectList[selectedProspectIndex]['submission'];
          const clientProposal =
           prospectList[selectedProspectIndex]['client_proposal'];

          // Scenario 1: Engagement Stats
          const submissionActionsString =
           prospect['fields']['Prospect Actions'];
          let submissionActions = [];
          if (
           submissionActionsString &&
           typeof submissionActionsString === 'string' &&
           submissionActionsString.trim() !== ''
          ) {
           try {
            submissionActions = JSON.parse(submissionActionsString);
           } catch (error) {
            console.error('Error parsing Submission Prospect Actions:', error);
           }
          }

          const clientProspectAction =
           clientProposal['fields']['Prospect Action'];
          const newestClientAction =
           Array.isArray(clientProspectAction) &&
           clientProspectAction.length > 0
            ? clientProspectAction[clientProspectAction.length - 1]
            : null;

          // Scenario 2: Client Proposal Actions
          const clientProposalActionsString =
           clientProposal['fields']['Prospect Actions'];
          let clientProposalActions = [];
          let firstClientProposalAction = null;
          if (
           clientProposalActionsString &&
           typeof clientProposalActionsString === 'string' &&
           clientProposalActionsString.trim() !== ''
          ) {
           try {
            clientProposalActions = JSON.parse(clientProposalActionsString);
            if (clientProposalActions.length > 0) {
             firstClientProposalAction = clientProposalActions[0]; // Change this line
            }
           } catch (error) {
            console.error(
             'Error parsing Client Proposal Prospect Actions:',
             error
            );
           }
          }

          return (
           <div className="p-4 *:py-1">
            <h2 className="text-h5 text-green-1 font-semibold capitalize">
             Engagement Stats
            </h2>
            <p>
             Engagements at this point are when the prospect opens the proposal
             you sent.
            </p>

            {/* Scenario 1: Engagement Stats */}
            {submissionActions.length > 0 || newestClientAction ? (
             <>
              {submissionActions.map((action, index) => (
               <div key={index} className="flex justify-between">
                <p className="font-semibold">
                 {new Date(action.date).toLocaleDateString('en-GB')}:
                </p>
                <p>{action.action}</p>
               </div>
              ))}
              {newestClientAction && (
               <div className="flex justify-between">
                <p className="font-semibold">Latest Client Action:</p>
                <p>{newestClientAction}</p>
               </div>
              )}
             </>
            ) : (
             <p className="text-bold">
              There has been no known engagements from this prospect.
             </p>
            )}

            {/* Scenario 2: Client Proposal Actions */}
            {firstClientProposalAction && (
             <div className="mt-4">
              <div className="flex justify-between">
               <h3 className="text-h6 text-green-1 font-semibold">
                Latest Prospect Action
               </h3>
               <p className="font-semibold">
                {new Date(firstClientProposalAction.date).toLocaleDateString(
                 'en-GB'
                )}
               </p>
              </div>
              <p>
               This action could be an action that's related to you or related
               to a different advisor.
              </p>
             </div>
            )}
           </div>
          );
         })()}
         {prospectList[selectedProspectIndex]['submission']['fields'][
          'send?'
         ] && (
          <div id="submission" className="space-y-2">
           <h2 className="text-h5 text-green-1 font-semibold capitalize">
            What you sent over
           </h2>
           <textarea
            rows={8}
            disabled
            className="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
            value={
             prospect['submission']['fields']['Additional comments'] || ''
            } // Added value attribute for controlled textarea
           ></textarea>
           {prospectList[selectedProspectIndex]['attachments'] &&
            prospectList[selectedProspectIndex]['attachments'].length > 0 && (
             <>
              <h2 className="font-bold text-lg capitalize">
               Files You Sent (Click to See)
              </h2>
              <ul>
               {prospect['attachments'].map((attachment, index) => (
                <li key={index}>
                 {' '}
                 {/* Added a key prop for list items */}
                 <a
                  className="mb-2 text-md text-blue-500" // Corrected className
                  href={attachment['url']}
                  target="_blank" // Added for opening in a new tab
                  rel="noopener noreferrer" // Security for opening links in a new tab
                 >
                  {attachment['filename']}
                 </a>
                </li>
               ))}
              </ul>
             </>
            )}
          </div>
         )}
         <div className="flex flex-col gap-y-8">
          {/* {prospect['client_proposal']['fields']['Types Requested'] && !prospectList[selectedProspectIndex]['submission']['fields']['send?'] && (
        <div id="GetDesign" className="p-4 space-y-2">
          <h2 className="text-h5 text-green-1 font-semibold capitalize">Get A Designed PDF</h2>
          <p>We have created a way to help you deliver a more professional, personalised suggestion to the client. Simply click on the button below and fill out the details.<br />
          Once you finished providing us the information, we'll help design it for you (it may take up to 3 business days).</p>
          <div className="flex gap-x-8 gap-y-4">
            <a href={`/submit-suggestion/${prospect['submission']['id']}`} className="inline-block rounded-lg bg-indigo-400 px-5 py-3 text-md font-medium text-white capitalize">Get Designed PDF</a>
          </div>
        </div>
      )} */}

          <div id="MessageCentre" className="md:p-4 space-y-2">
           <h2 className="text-h5 text-green-1 font-semibold capitalize">
            Chat history
           </h2>
           {Array.isArray(
            prospectList[selectedProspectIndex]['submission'].fields[
             'Message History'
            ]
           ) ? (
            prospectList[selectedProspectIndex]['submission'].fields[
             'Message History'
            ].map((message, index) => (
             <div key={index} className="w-full gap-2">
              <p
               className={`${
                message.sender ===
                prospectList[selectedProspectIndex]['submission'].fields[
                 'Advisor Name'
                ][0]
                 ? 'text-start bg-blue-100 px-2 py-1 rounded-xl text-sm md:text-base'
                 : 'text-end bg-green-100 px-2 py-1 rounded-xl text-sm md:text-base'
               }`}
              >
               {message.message}
              </p>
             </div>
            ))
           ) : (
            <p className="text-sm md:text-base">
             You two haven't messaged each other.
            </p>
           )}
           <h2 className="text-h5 text-green-1 font-semibold capitalize">
            Message Centre
           </h2>
           {!prospectList[selectedProspectIndex]?.['client_proposal']?.[
            'fields'
           ]?.['Telegram ID'] ? (
            <p>
             {prospect['client_proposal']['fields']['Name']} did not connect to
             the telegram bot so the only way to talk to them right now is
             through the message centre. Write what you'd like to send to{' '}
             {prospect['client_proposal']['fields']['Name']} and it will
             automatically be sent via email to them. When they respond, the
             message box will be what they shared back.
            </p>
           ) : (
            <p>
             {prospect['client_proposal']['fields']['Name']} is signed up to
             telegram so you can message them directly.
            </p>
           )}
           <form className="space-y-2" onSubmit={messageProspect}>
            <input
             type="hidden"
             name="submission_id"
             value={prospect['submission']['id']}
            />
            <textarea
             name="comments"
             rows={8}
             className="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
             value={comments}
             onChange={e => setComments(e.target.value)}
            ></textarea>
            <div className="flex justify-between gap-6">
             <button
              type="submit"
              className={`primary-button1 hover:primary-button2 ${
               countdown > 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={countdown > 0}
             >
              {countdown > 0 ? `Wait ${countdown}s` : 'Send Message'}
             </button>
             {advisor?.['fields']?.['Telegram ID'] &&
              prospectList[selectedProspectIndex]?.['client_proposal']?.[
               'fields'
              ]?.['Telegram ID'] && (
               <button
                className="primary-button1 hover:primary-button2"
                onClick={handleTelegramMessage}
               >
                Message via telegram
               </button>
              )}
            </div>
           </form>
          </div>
          {!advisor?.['fields']?.['Telegram ID'] && (
           <div id="Telegram" className="p-4 space-y-2">
            <h2 className="text-h5 text-green-1 font-semibold capitalize">
             You're not connected to our telegram bot
            </h2>
            <p>
             We try to get our prospects to connect to our telegram bot so it's
             easier for you to message them. However you're not connected.
             Please connect.
            </p>
            <div className="flex gap-x-8 gap-y-4">
             <a
              href={`https://t.me/whatins_bot?start=${advisor['id']}`}
              className="inline-block rounded-lg bg-indigo-400 px-5 py-3 text-md font-medium text-white capitalize"
             >
              Connect Telegram Bot
             </a>
            </div>
           </div>
          )}
          {!prospectList[selectedProspectIndex]['submission']['fields'][
           'send?'
          ] && (
           <div id="SubmitProposal" className="space-y-2">
            <h2 className="text-h5 text-green-1 font-semibold capitalize">
             Submit your suggestions to{' '}
             {prospect['client_proposal']['fields']['Name']}
            </h2>
            <form
             className="space-y-3"
             onSubmit={handleSubmit}
             encType="multipart/form-data"
            >
             <input
              type="hidden"
              name="submission_id"
              value={prospect['submission']['id']}
             />
             <div className="space-y-2">
              <label
               className="block mb-2 text-md font-medium"
               htmlFor="proposals"
              >
               Attach files here (optional if URL is provided):
              </label>
              <input
               className="block w-full text-sm border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
               type="file"
               id="proposals"
               name="proposals"
               accept=".pdf"
               multiple
              />
              <p id="fileList"></p>
              <p id="uploadedFileList"></p>
             </div>

             <div className="space-y-2">
              <label
               className="block mb-2 text-md font-medium"
               htmlFor="proposalUrl"
              >
               Or enter a URL (optional if files are attached):
              </label>
              <input
               className="block w-full text-sm border border-gray-300 rounded-lg bg-gray-50 p-2.5 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
               type="url"
               id="proposalUrl"
               name="proposalUrl"
               placeholder="https://suggestions.whatins.sg/... or https://proposals.whatins.sg/..."
               pattern="https://(suggestions|proposals)\.whatins\.sg/.*"
              />
             </div>

             <textarea
              id="message"
              name="message"
              rows={8}
              className="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="(Required) Please type a personal message here. We suggest you explain why you're proposing certain products and connect it back to what they shared and what assumptions you're making."
              required
             ></textarea>
             <button
              type="submit"
              className="primary-button1 hover:primary-button2"
             >
              Submit
             </button>
            </form>
           </div>
          )}
         </div>
        </div>
       </div>
      )}
    </ScrollArea>
   </>
  );
 };

 const handleTelegramMessage = async () => {
  console.log(prospectList[selectedProspectIndex]);
  try {
   const response = await fetch('/api/telegram-for-advisor', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify(prospectList[selectedProspectIndex]),
   });

   if (!response.ok) {
    throw new Error('Failed to send Telegram message');
   }

   const result = await response.json();
   toast({
    title: 'We just messaged you',
    description:
     "We just messaged you so you can message your prospect on telegram. The message you shared here (if any) was not sent by us so you'll have to send it again.",
   });
  } catch (error) {
   console.error('Error sending Telegram message:', error);
   toast({
    title: 'Error',
    description: 'Failed to send Telegram message. Please try again.',
    variant: 'destructive',
   });
  }
 };

 return (
  <div className="bg-white-1 flex flex-col h-full">
   {isLoading && <Loading src="loading.mp4" />}
   <div className="flex flex-col h-full">
    <div className="block md:hidden sticky top-0 bg-white-1 w-full py-2 z-50">
     <Drawer>
      <DrawerTrigger>
       <Button className="px-4 py-2 mx-4 primary-button2">
        See Prospects List
       </Button>
      </DrawerTrigger>
      <DrawerContent>
       <ScrollArea className="max-h-96">
        {activeProspects.map((prospect, index) => (
         <div key={index}>
          <button
           className={`w-full primarily-button2 p-2 text-start hover:bg-green-1 ${
            selectedProspectIndex === index ? 'bg-green-2' : 'bg-white-1'
           }`}
           onClick={() => setSelectedProspectIndex(index)}
          >
           <div className="">
            {prospect['client_proposal']['fields']['Name']}
           </div>
          </button>
         </div>
        ))}
       </ScrollArea>
      </DrawerContent>
     </Drawer>
    </div>
    <div className="flex flex-grow h-full">
     <ScrollArea
      className="h-full flex flex-col w-2/12 flex-none justify-items-center hidden md:block order-1"
      type="always"
     >
      <Tabs
       defaultValue="active"
       onValueChange={value => {
        setActiveTab(value);
        setSelectedProspectIndex(0);
       }}
       className="w-full"
      >
       <TabsList className="flex w-full bg-white-1 rounded-t-lg border-b border-gray-200">
        <TabsTrigger
         value="active"
         className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 hover:text-green-1 focus:outline-none focus:text-green-1 data-[state=active]:text-green-1 data-[state=active]:border-b-2 data-[state=active]:border-green-1 data-[state=active]:bg-green-2 bg-white-2"
        >
         Active
        </TabsTrigger>
        <TabsTrigger
         value="archived"
         className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 hover:text-green-1 focus:outline-none focus:text-green-1 data-[state=active]:text-green-1 data-[state=active]:border-b-2 data-[state=active]:border-green-1 data-[state=active]:bg-green-2 bg-white-2"
        >
         Archived
        </TabsTrigger>
       </TabsList>
      </Tabs>
      {(activeTab === 'active' ? activeProspects : archivedProspects).map(
       (prospect, index) => (
        <div key={index}>
         <button
          className={`w-full primarily-button2 p-2 text-start hover:bg-green-1 ${
           selectedProspectIndex === index ? 'bg-green-2' : 'bg-white-1'
          }`}
          onClick={() => setSelectedProspectIndex(index)}
         >
          <div className="flex flex-row justify-between items-center w-full">
           <div className="flex flex-row">
            <div className="mr-4">
             {prospect['submission']['fields']['send?'] ? '✅' : null}
            </div>
            <div>{prospect['client_proposal']['fields']['Name']}</div>
           </div>
           <div className="mr-4 text-end items-center flex flex-row">
            {(activeTab === 'active' && showArchiveButton) ||
            activeTab === 'archived' ? (
             <TooltipProvider>
              <Tooltip>
               <TooltipTrigger>
                <button
                 onClick={() =>
                  handleArchive(
                   prospect.submission.fields.recordID,
                   activeTab === 'archived'
                  )
                 }
                >
                 {activeTab === 'archived' ? (
                  <SvgIcons.Archive color="fill-green-2 hover:fill-gray-400" />
                 ) : (
                  <SvgIcons.Archive color="fill-gray-400 hover:fill-green-2" />
                 )}
                </button>
               </TooltipTrigger>
               <TooltipContent>
                <p>
                 {activeTab === 'archived'
                  ? 'Unarchive prospect'
                  : 'Archive prospect'}
                </p>
               </TooltipContent>
              </Tooltip>
             </TooltipProvider>
            ) : null}
           </div>
          </div>
         </button>
        </div>
       )
      )}
      {prospectList.length < totalCount && (
       <div className="p-4 space-y-2">
        <p className="text-sm text-gray-600 italic">
         {prospectList.length === 5
          ? 'Newest prospects loaded. The rest are being populated.'
          : `Showing ${prospectList.length} of ${totalCount} prospects.`}
        </p>
       </div>
      )}
     </ScrollArea>
     {renderProspectDetails()}
    </div>
   </div>
  </div>
 );
};

export default AdvisorProspect;
